<ng-container [ngSwitch]="activeLocation">
  <ng-container *ngSwitchCase="'mo'">
    <prf-page-footer-mosobl></prf-page-footer-mosobl>
  </ng-container>
  <ng-container *ngSwitchCase="'kz'">
    <prf-page-footer-kz></prf-page-footer-kz>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="baseFooter"></ng-container>
  </ng-container>
</ng-container>

<ng-template #baseFooter>
  <!-- <div class="footer-section">
    <div class="footer-row">
      <div class="footer-text">© {{ yearNumber }} Профилум</div>
      <div class="footer-logo">
        <a target="_blank" class="logo-link-footer w-inline-block">
          <img src="/assets/images/dashboard/profilum-logo.svg" alt="Profilum logo" />
        </a>
      </div>
      <div class="footer-emails">
        <div class="footer-text">
          {{ 'FOOTER.SUPPORT' | translate }}:
          <a href="mailto:ask@profilum.ru" class="footer-link">ask@profilum.ru</a>
        </div>
      </div>
    </div>
  </div> -->
</ng-template>
