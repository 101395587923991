import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { of, Subject } from 'rxjs';
import { UserRoles } from 'app/shared/enums/userroles.enum';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { UserProfileService } from 'app/pages/catalog/user-profile-page/user-profile.service';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { MenuService } from './menu.service';
import { SharedService } from 'app/shared/shared.service';
import { SchoolAdminPanelService } from 'app/pages/control-panel/schooladmin/schooladmin-panel.service';
import { IResultsAccess } from 'app/shared/interfaces/iresults.access';
import { UserAccessService } from 'app/shared/services/useraccess.service';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

export interface IUserTestProfile {
  screeningTestId: string;
  testName: string;
  testAccess: boolean;
  resultsAccess: boolean;
}

@Component({
  selector: 'prf-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  @Input() root: string = '/';
  @Input() test: boolean;
  @Input() set menuAllowed(val: boolean) {
    this._menuAllowed = val;
  }
  @Input() set setUserProfile(val: UserInfoClass) {
    if (val) {
      this.userProfile = val;
    }
  }

  private _menuAllowed: boolean = true;
  get menuAllowed() {
    return this._menuAllowed;
  }

  public userInfo;
  public userData: IUserInfo;
  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public userProfile: UserInfoClass;
  public firstName: string = '';
  public currentUrl: string = '';
  public userId: string = '';
  public tag: string = '';

  public sessionList: IHistory[];
  public sessionListView: IHistory[] = [];
  public regionId: string = '';

  public showInternships: boolean = true;
  public showMosruAbout: boolean = false;
  public url: string;
  headerButtonsArray: { name: string; url: string }[] = [];
  routerRef: any;

  public sessionStatus: string = '';
  public testSessionId: string = '';
  public uncompletedTest: boolean = false;

  testInfo: ITestInfo = null;

  isMobile: boolean = false;
  isMOStend: boolean = false;
  isVGuser: boolean = false;
  defaultB2cParents: boolean = false;

  // Переменные временно скрывают тест на ценности и стажировки
  isTestReady: boolean = false;
  isInternshipsReady: boolean = false;

  userTestsArray: IUserTestProfile[] = [];

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private headerService: PageHeaderService,
    private menuService: MenuService,
    private profileService: UserProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private sharedService: SharedService,
    private appSettingsService: AppSettingsService,
    private userDataHandlerService: UserDataHandlerService,
    private userAccessService: UserAccessService,
  ) {
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.userData = this.userDataHandlerService.getUserData().value;
    this.defaultB2cParents =
      this.userInfo.role === 'parent' &&
      (this.userInfo.tag === EUserTags[EUserTags.Default] ||
        this.userInfo.tag === EUserTags[EUserTags.b2c] ||
        this.userInfo.tag === EUserTags[EUserTags.Prosv]);
    this.imagePath = localStorage.getItem('imagePath');
    this.firstName = localStorage.getItem('firstName');
    this.isVGuser = localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString();
    this.userId = localStorage.getItem('userId');
    this.regionId = localStorage.getItem('regionId');
    this.tag = localStorage.getItem('tag');
    this.sessionStatus = localStorage.getItem('sessionStatus');
    this.testSessionId = localStorage.getItem('testSessionId');
    this.uncompletedTest = this.sessionStatus === 'Uncompleted test';
    this.isMobile = window.innerWidth <= 768;

    if (this.imagePath === null || this.imagePath === 'null' || this.imagePath === '') {
      this.imagePath = '/assets/images/dashboard/no-photo.svg';
    }

    this.routerRef = router;

    this.isMOStend = location.origin.includes('mosreg');
  }

  ngOnInit() {
    if (this.userInfo.role === 'pupil' || this.userInfo.role === 'parent') {
      this.userAccessService
        .getUserAccesses()
        .pipe(
          switchMap((resultsAccessArray: IResultsAccess[]) => {
            if (resultsAccessArray) {
              return this.sharedService.getRegionTestInfo(this.userData.regionId).pipe(
                tap((testInfo: ITestInfo) => {
                  this.testInfo = testInfo;

                  return this.setTestsArray(resultsAccessArray);
                }),
              );
            } else {
              return of(null);
            }
          }),
        )
        .subscribe();
    }

    this.setHeaderButtonsMenu();
    this.menuService.setMobileScreen(this.isMobile);

    this.detectUrlChange();
    if (this.root === '/') {
      this.root = this.userInfo.role;
    }

    this.menuService.getMobileMenu().subscribe(show => {
      this.isShowMobileMenu = show;
    });
  }

  setTestsArray(userAccesses: IResultsAccess[]) {
    const getTestAccess = screeningTestId => {
      return userAccesses.find(testAccess => testAccess.screeningTestId === screeningTestId)?.isTestPassing;
    };
    const getResultsAccess = screeningTestId => {
      return userAccesses.find(testAccess => testAccess.screeningTestId === screeningTestId)?.isTestResult;
    };

    switch (this.userInfo.role) {
      case 'pupil': {
        this.userTestsArray = [
          {
            screeningTestId: this.testInfo.screeningTestId,
            testName: 'Таланты',
            testAccess: getTestAccess(this.testInfo.screeningTestId),
            resultsAccess: getResultsAccess(this.testInfo.screeningTestId),
          },
        ];
        break;
      }
      case 'parent': {
        this.userTestsArray = [
          {
            screeningTestId: this.testInfo.screeningTestId,
            testName: 'Таланты',
            testAccess: getTestAccess(this.testInfo.screeningTestId),
            resultsAccess: getResultsAccess(this.testInfo.screeningTestId),
          },
        ];
      }
    }
  }

  public selectResults() {
    if (this.isMobile) {
      this.hideMobileMenu();
    }
    this.routingMainTest();
  }

  detectUrlChange() {
    this.showMosruAbout = this.userInfo.role === 'parent' && this.userInfo.tag === 'MosRu';
    this.currentUrl = this.router.url.split('/')[1];
    this.currentUrl = '/' + this.currentUrl;
    if (this.currentUrl.includes('#')) {
      this.currentUrl = this.router.url.split('#')[0];
    }
  }

  private navigateTest() {
    const access = this.userTestsArray.find(el => el.screeningTestId === this.testInfo.screeningTestId)?.testAccess;
    if (access) {
      if (this.uncompletedTest && this.testSessionId) {
        this.router.navigate(['/test-na-professiyu', this.testSessionId]);
      } else {
        this.router.navigate(['/test-na-professiyu']);
      }
    }
  }

  routingMainTest() {
    if (this.testSessionId && this.sessionStatus == 'Success') {
      if (this.root == 'pupil') {
        this.router.navigate(['/results']);
      } else if (this.root == 'parent') {
        this.router.navigate(['/parent']);
      }
    } else {
      this.navigateTest();
    }
  }

  // надо добавить выбор текущего ребенка
  routingMotivationTest() {
    let userId;

    if (this.userInfo.role === 'pupil') {
      userId = this.userId;
    } else if (this.userInfo.role === 'parent') {
      ///TODO доделать выбор ребенка у родителя!
      const children = this.userProfile.children;
      userId = children[children.length - 1].userId;
    }
    this.sharedService
      .getSessionList(userId)
      .pipe(
        switchMap(r => {
          this.sessionList = r;
          if (this.sessionList) {
            return this.sharedService.getRegionTestInfo(this.regionId).pipe(
              tap(testInfo => {
                this.testInfo = testInfo;
                let motivationTestSessions: any[] = [];

                switch (this.userInfo.role) {
                  case UserRoles.pupil:
                  case UserRoles.parent:
                    motivationTestSessions = this.sessionList.filter(el => el.screeningTestId == TESTS_VARIANTS_IDS.motivationtest);
                    break;
                  default:
                    break;
                }

                const motivationTestSessionsComplete = motivationTestSessions
                  .filter(session => session.completed)
                  .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));
                const lastSessionCompleted = motivationTestSessionsComplete[0];

                const testAccess: boolean = this.userTestsArray.find(
                  el => el.screeningTestId === TESTS_VARIANTS_IDS.motivationtest,
                )?.testAccess;

                const resultsAccess: boolean = this.userTestsArray.find(
                  el => el.screeningTestId === TESTS_VARIANTS_IDS.motivationtest,
                )?.resultsAccess;

                if (lastSessionCompleted?.sessionId) {
                  if (resultsAccess) {
                    this.router.navigate(['/motivation-test-results/', lastSessionCompleted?.sessionId]);
                  }
                } else {
                  if (this.userInfo.role === 'parent' && resultsAccess) {
                    this.router.navigate(['/motivation-test-results']);
                  } else if (motivationTestSessions.length && this.userInfo.role === 'pupil' && testAccess) {
                    this.router.navigate(['/motivation-test/', motivationTestSessions[motivationTestSessions.length - 1].sessionId]);
                  } else if (testAccess) {
                    this.router.navigate(['/motivation-test']);
                  }
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  routingValuesTest() {
    let userId;
    if (this.userInfo.role === 'pupil') {
      userId = this.userId;
    } else if (this.userInfo.role === 'parent') {
      ///TODO доделать выбор ребенка у родителя!
      const children = this.userProfile?.children;
      userId = children[children.length - 1].userId;
    }

    this.sharedService
      .getSessionList(userId)
      .pipe(
        tap(r => {
          this.sessionList = r;
          if (this.sessionList) {
            let valuesTestSessions: any[] = [];

            switch (this.userInfo.role) {
              case UserRoles.pupil:
              case UserRoles.parent:
                valuesTestSessions = this.sessionList.filter(el => el.screeningTestId == TESTS_VARIANTS_IDS.valuesTest);
                break;
              default:
                break;
            }
            const valuesTestSessionsCompleted = valuesTestSessions
              .filter(session => session.completed)
              .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));
            const lastSessionCompleted = valuesTestSessionsCompleted[0];

            const testAccess: boolean = this.userTestsArray.find(el => el.screeningTestId === TESTS_VARIANTS_IDS.valuesTest)?.testAccess;

            const resultsAccess: boolean = this.userTestsArray.find(
              el => el.screeningTestId === TESTS_VARIANTS_IDS.valuesTest,
            )?.resultsAccess;

            if (lastSessionCompleted?.sessionId) {
              if (resultsAccess) {
                this.router.navigate(['/values-test-results/', lastSessionCompleted?.sessionId]);
              }
            } else {
              if (this.userInfo.role === 'parent' && resultsAccess) {
                this.router.navigate(['/values-test-results/']);
              } else if (valuesTestSessions.length && this.userInfo.role === 'pupil' && testAccess) {
                this.router.navigate(['/values-test/', valuesTestSessions[valuesTestSessions.length - 1].sessionId]);
              } else if (testAccess) {
                this.router.navigate(['/values-test']);
              }
            }
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    event.target.innerWidth;
    this.isMobile = event.target.innerWidth <= 768;
    this.menuService.setMobileScreen(this.isMobile);
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }
  showMobileMenu() {
    this.menuService.showMobileMenu(true);
  }

  public hideProfile() {
    this.headerService.closeProfile();
    this.schoolAdminPanelService.closePupilPage();
  }

  public selectMain() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/' + this.root]);
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/profile']);
  }

  public selectRecommendations() {
    this.hideMobileMenu();
    this.router.navigate(['/recommendations']);
  }

  public selectProfessions() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/professions']);
  }

  public selectCourses() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/courses']);
  }

  public selectEvents() {
    this.hideMobileMenu();
    this.router.navigate(['/events']);
  }

  public selectInternships() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/internships']);
  }

  public selectNews() {
    this.hideMobileMenu();
    this.router.navigate(['/news']);
  }

  public selectPortfolio() {
    this.hideMobileMenu();
    this.router.navigate(['/portfolio']);
  }

  public navigateContinueTest() {
    this.router.navigate(['/test-na-professiyu/' + this.testSessionId]);
  }

  public selectHistory() {
    this.hideMobileMenu();
    this.router.navigate(['/pupil-tests-history']);
  }

  public selectPromoAction() {
    this.hideMobileMenu();
    this.router.navigate(['/mosruabout']);
  }

  setHeaderButtonsMenu() {
    this.url = this.router.url;
    if (this.isVGuser) {
      if (this.url.includes('partners-courses-all')) {
        this.headerButtonsArray = [
          { name: 'Результаты теста', url: '/vorobievi-gori/promotest-results' },
          { name: 'Пройти тест', url: '/vorobievi-gori/mosrupromo' },
        ];
      } else if (this.url.includes('promotest-results')) {
        this.headerButtonsArray = [
          { name: 'Курсы', url: '/vorobievi-gori/partners-courses-all' },
          { name: 'Пройти тест', url: '/vorobievi-gori/mosrupromo' },
        ];
      }
    }
  }

  get isB2cUser() {
    const b2cRegionId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.regionId : null;
    const b2cMunicipalityId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.municipalityId : null;
    return (
      (localStorage.getItem('userRole') === 'pupil' || localStorage.getItem('userRole') === 'parent') &&
      localStorage.getItem('regionId') === b2cRegionId &&
      localStorage.getItem('municipalityId') === b2cMunicipalityId
    );
  }

  setMenuProperties() {
    const toggledMenuOverlay = document.getElementsByClassName('cdk-overlay-connected-position-bounding-box')[0];
    if (window.innerWidth > 991) {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(100px, -83px)');
    } else if (window.innerWidth > 767) {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(100px, -55px)');
    } else {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(55px, -10px)');
    }
  }

  backToParentsPage() {
    return this.router.navigate(['/parent']);
  }

  get showHeaderButtons() {
    return this.isVGuser;
  }
  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
