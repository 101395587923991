<div class="login-section">
  <div class="login-container">
    <div class="tabs login w-tabs">
      <!-- <div class="tabs-menu w-tab-menu">
        <a
          [routerLink]="['/b2c-login']"
          [routerLinkActive]="['w--current']"
          class="tab-link login w-inline-block w-tab-link"
        >
          <div>Вход</div>
          <img src="/assets/images/icons/angle.png" width="20" class="angle" />
        </a>
        <a
          [routerLink]="['/b2c-registration']"
          [routerLinkActive]="['w--current']"
          class="tab-link login w-inline-block w-tab-link"
        >
          <div>Регистрация</div>
          <img src="/assets/images/icons/angle2.png" width="20" class="angle _2" />
        </a>
      </div> -->

      <div class="w-tab-pane w--tab-active">
        <div class="tab-pane login">
          <div class="registration-row">
            <div class="registration-column">
              <a [routerLink]="['/registration-parent']" class="registration-link w-inline-block">
                <img src="/assets/images/dashboard/reg-parent.svg" class="registration-link-image" />
                <div class="registration-link-text">Родитель</div>
              </a>
            </div>
            <div class="registration-column">
              <a [routerLink]="['/code-registration']" class="registration-link w-inline-block">
                <img src="/assets/images/dashboard/reg-student.svg" class="registration-link-image" />
                <div class="registration-link-text">Ученик</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="policy-text">
      Нажимая «Зарегистрироваться», вы соглашаетесь с&nbsp;
      <a href="/assets/docs/PersonalData.pdf" target="_blank">политикой&nbsp;конфиденциальности</a>,&nbsp;
      <a href="/assets/docs/TermsOfUse.pdf" target="_blank">пользовательским&nbsp;соглашением</a> и&nbsp;
      <a href="/assets/docs/ReceivingAdvertising.pdf" target="_blank">согласием получения рекламы</a>
    </div>
    -->
  </div>
</div>
