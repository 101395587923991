/**
 * Компонент для маршрутизации на различные страницы результатов, в зависимости от типа теста
 */
import { Component, inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PrEventsEnums } from 'app/shared/enums/pr-events.enums';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { filter, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { ETestTypes } from '../../../shared/enums/testtypes.enum';
import { Observable, of } from 'rxjs';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { ApiCalculatorService } from '@profilum-api-services/api-calculator/api-calculator.service';
import { IUserInfo, StorageKeys, UserDataHandlerService, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-results-switcher',
  templateUrl: './results-switcher.component.html',
  styleUrls: ['./results-switcher.component.scss'],
})
export class ResultsSwitcherComponent implements OnInit {
  testType: string = '';
  userInfo: any;
  userData: IUserInfo;
  sessionId: string = '';
  promoTag: string = ''; // метка для определения участия пользователя в промо акции
  private overlayBusyService: OverlayBusyService = inject(OverlayBusyService);
  private apiCalculatorService: ApiCalculatorService = inject(ApiCalculatorService);

  constructor(
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userDataHandlerService: UserDataHandlerService,
    private webStorageService: WebStorageService,
  ) {
    this.testType = webStorageService.get(StorageKeys.TestType)?.toString();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.userData = this.userDataHandlerService.getUserData().getValue();
    this.promoTag = webStorageService.get(StorageKeys.PromoTag)?.toString();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          if (params['ssid']) {
            this.sessionId = params['ssid'];
          } else {
            this.router.navigate(['/404']);
          }

          return this.calculateSessionResults();
        }),
        switchMap(() => {
          if (this.userInfo.role === 'parent') {
            if (this.testType === ETestTypes.PROMO_TEST.toString()) {
              if (this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.VorobieviGori].toString()) {
                this.router.navigate(['/vorobievi-gori/promotest-results/' + this.sessionId]);
              } else {
                this.router.navigate(['/promotest-results/' + this.sessionId]);
              }
            } else {
              this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
              this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
              this.router.navigate(['/parent']);
              this.userDataHandlerService.updateUserInfo();
            }
            return of(null);
          } else {
            switch (this.testType) {
              case ETestTypes.DEFAULT_STANDART.toString():
                if (this.promoTag == PrEventsEnums[PrEventsEnums.Marathon]) {
                  this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                  return this.router.navigate(['/results-marathon', this.sessionId]);
                }
                this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
                this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                this.router.navigate(['/results']);
                break;
              case ETestTypes.DEFAULT_360.toString():
                console.log('wtf?');
                this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
                this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                // сюда все еще попадаем??
                //todo: check&delete it
                this.router.navigate(['/' + this.userInfo.role]);
                break;
              case ETestTypes.PROFESSIONPRESTIGE.toString():
                this.router.navigate(['/results-profession-prestige']);
                break;
              case ETestTypes.MOTIVATION_TEST.toString():
                this.router.navigate(['/motivation-test-results/' + this.sessionId]);
                break;
              case ETestTypes.VALUES_TEST.toString():
                this.router.navigate(['/values-test-results/' + this.sessionId]);
                break;
              case ETestTypes.CAREER_TYPES.toString():
                this.router.navigate(['/career-test-results/' + this.sessionId]);
                break;
              default:
                if (this.promoTag == PrEventsEnums[PrEventsEnums.Marathon]) {
                  return this.router.navigate(['/results-marathon', this.sessionId]);
                }
                this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
                this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                setTimeout(() => {
                  // редирект на начало теста через браузерную кнопку "назад"
                  switch (localStorage.getItem('redirectTestType')) {
                    case ETestTypes.VALUES_TEST.toString():
                      localStorage.removeItem('redirectTestType');
                      return this.router.navigate(['/values-test']);
                    default:
                      return this.router.navigate(['/' + this.userInfo.role]);
                  }
                }, 1000);
                break;
            }
          }
        }),
      )
      .subscribe();
  }

  private calculateSessionResults = (): Observable<boolean> => {
    this.overlayBusyService.show();
    return this.apiCalculatorService.calculateSessionResults(this.sessionId).pipe(
      map(response => response.status === 'Success'),
      retry({ count: 3, delay: 2000 }),
      take(1),
      filter(isCalculated => isCalculated),
      tap(() => this.overlayBusyService.hide()),
    );
  };
}
