import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProfessionsService } from 'app/shared/dashboard/professions/professions.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

@Component({
  selector: 'prf-steps-trajectory',
  templateUrl: './steps-trajectory.component.html',
  styleUrls: ['./steps-trajectory.component.scss'],
})
export class StepsTrajectoryComponent {
  @Input() hideButton: boolean = false;
  @Input() showMoreAbout: boolean = false;

  @Input()
  set profession(profession: IProfession) {
    this._profession = profession;
    this.currentStep = 'averageSchool';

    if (this._profession.companies) {
      this._profession.companies.filter(x => (x.url = x.url?.trim()));
    }
    this.specialsCollage = profession.specials?.filter(r => r.education === 2);
    this.specialsUniversity = profession.specials?.filter(r => r.education === 3);

    this.setSchemeType();
  }

  get profession(): IProfession {
    return this._profession;
  }

  public isOpen: boolean = true;
  public currentStep: string = 'averageSchool';
  public specialsCollage: Array<any> = [];
  public specialsUniversity: Array<any> = [];
  public schemeType: string = 'twoBranches'; // [twoBranches, withoutCollege, withoutUniversity]

  userRole: string;
  private _profession: IProfession;

  constructor(
    private professionService: ProfessionsService,
    private router: Router,
  ) {
    this.userRole = localStorage.getItem('userRole');
  }

  public showProfession() {
    this.professionService.setCurrentProfession(this.profession);
    this.router.navigate(['/professions', this.profession.hrid]);
  }

  public goToCourses(course: any) {
    localStorage.setItem('course', course);
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
      case 'tutor':
        this.router.navigate(['/courses']);
        break;
      default:
        this.router.navigate([`${this.userRole}/courses`]);
        break;
    }
  }

  private setSchemeType() {
    if (this._profession && this._profession.education && this._profession.education.length) {
      if (
        this._profession.education.some(e => e.level === 2) &&
        this._profession.education.some(e => e.level === 3 && e.perentage !== 100)
      ) {
        this.schemeType = 'twoBranches';
        return;
      }

      if (this._profession.education.some(e => e.level === 3 && e.perentage === 100)) {
        this.schemeType = 'withoutCollege';
        return;
      }

      if (!this._profession.education.some(e => e.level === 3)) {
        this.schemeType = 'withoutUniversity';
        return;
      }
    }
  }
}
