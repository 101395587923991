import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { RegistrationProsvetService } from '../registration-prosvet.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { RegionsService } from '../../../../pages/control-panel/admin/regions/regions.service';
import { ChildrenAddService } from '../../../../pages/control-panel/parent/children-add/children-add.service';
import { FilterSchoolsPipe } from '../../../../shared/pipes/filter-schools.pipe';
import { FilterByNamePipe } from '../../../../shared/pipes/filter-by-name.pipe';
import { TranslateService } from '@ngx-translate/core';
import { AdminPanelService } from '../../../../pages/control-panel/admin/admin-panel.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { ICity } from 'app/shared/interfaces/icity';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import * as moment from 'moment';

@Component({
  selector: 'prf-code-registration-prosvet',
  templateUrl: './code-registration-prosvet.component.html',
  styleUrls: ['./code-registration-prosvet.component.scss'],
})
export class CodeRegistrationProsvetComponent implements OnInit {
  public registrationPupil: boolean;
  public registrationOther: boolean;
  public tglRegistration: boolean = false;
  public advertisingTerms: boolean = false;
  public registrationOpen: boolean = false;
  public role = 'parent';

  tag: string;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public emailRegExp: RegExp = REG_EXP.emailRegExp;

  public isKz: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public personalTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public codeIsUsed: boolean = false;
  public checkEmail: boolean = true;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;
  private ngUnsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private registrationService: RegistrationProsvetService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private regionsService: RegionsService,
    private childrenAddService: ChildrenAddService,
    private filterSchoolsPipe: FilterSchoolsPipe,
    private filterByNamePipe: FilterByNamePipe,
    private translateService: TranslateService,
    private adminPanelService: AdminPanelService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
  ) {
    this.moment = moment;
    this.moment.locale('ru');
    this.meta.updateTag({ name: 'og:title', content: 'Регистрация по коду' });
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/prosvetregistration-pupil': {
            this.personalTerms = true;
            return (this.registrationPupil = true);
          }
          case '/prosvetregistration-other': {
            return (this.registrationOther = true);
          }
          default: {
            this.personalTerms = true;
            return (this.registrationPupil = true);
          }
        }
      }
    });
  }

  ngOnInit() {
    this.SCHOOL_LETTERS = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');

    this.form = this.fb.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [Validators.required]),
    });
    // Check Tag in URL
    this.activatedRoute.queryParams.subscribe(params => {
      this.tag = params.tag;
    });

    this.setLanguage();
  }

  public setLanguage() {
    const language = localStorage.getItem('selectedLanguage');
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz' ? true : false;
      this.translateService.use(language);
    }
    return;
  }

  submit() {
    this.submitted = true;
    this.duplicateUserName = false;
    this.codeIsUsed = false;
    if (this.form.valid) {
      this.passFailed = false;
      this.duplicateUserName = false;

      this.registrationService
        .registration(this.form.value.email, this.form.value.password, this.advertisingTerms)
        .pipe(
          switchMap((response: any) => {
            if (response.status === 'User with current email already exists') {
              this.duplicateUserName = true; // почта занята
              this.failWaiting();
              return of(null);
            } else {
              // Сразу попадаем в ЛК
              return this.registrationService.login(response.userName, this.form.value.password, false).pipe(
                switchMap((loginResult: any) => {
                  if (loginResult) {
                    if (loginResult.succeeded === false) {
                      this.loginFailed = true;
                      this.passFailed = true;
                      this.removeWaiting();
                      return of(null);
                    } else if (response.status === 'Сode has already been used') {
                      this.codeIsUsed = true; // код использован
                      this.failWaiting();
                      return of(null);
                    } else {
                      localStorage.setItem('userRole', loginResult.role);
                      localStorage.setItem('userId', loginResult.userId);
                      localStorage.setItem('tag', loginResult.tag);
                      localStorage.setItem('isAuthorized', 'true');

                      return this.sharedService.getUserInfoData().pipe(
                        tap((ui: UserInfoClass) => {
                          if (ui) {
                            this.setUserInfoInLS(ui);
                          }

                          switch (loginResult.role) {
                            case 'admin': {
                              // Пока нет пользователя с ролью админ, переходим просто /admin
                              this.router.navigate(['/admin/schools']);
                              return;
                            }
                            case 'schooladmin': {
                              this.router.navigate(['/schooladmin/classes']);
                              return;
                            }
                            case 'pupil': {
                              this.router.navigate(['/pupil']);
                              return;
                            }
                          }
                        }),
                      );
                    }
                  } else {
                    return of(null);
                  }
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(_ => this.removeWaiting());
    } else {
      this.failWaiting();
    }
  }

  setUserInfoInLS(ui) {
    if (!ui) {
      throw 'User info is not defined';
    }
    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;
  }

  public toggleRegistration() {
    this.tglRegistration = !this.tglRegistration;
    this.registrationOpen = !this.registrationOpen;
  }

  get isAccessAllowed() {
    return this.form.value && this.personalTerms;
  }
  get accessFormRole(): boolean {
    if (this.registrationOther) {
      return true;
    } else {
      return this.registrationPupil && this.tglRegistration;
    }
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  get f() {
    return this.form.controls;
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }
  public removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }
  public failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  public checkFormatEmail(event): void {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
