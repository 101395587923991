import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'prf-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() params: any;

  @ViewChild('container') container: ElementRef;

  public top: number = 0;
  public left: number = 0;
  public visibility: string = 'hidden';

  ngOnInit() {
    setTimeout(() => this.setPosition(), 100);
  }

  private setPosition() {
    const rect = this.params.hostRef.getBoundingClientRect();
    const leftCenter = Math.round(this.container.nativeElement.clientWidth / 2 - this.params.hostRef.clientWidth / 2);

    this.top = rect.top + window.pageYOffset - this.container.nativeElement.clientHeight - 10;
    this.left = rect.left + window.pageXOffset - leftCenter;
    this.visibility = 'visible';
  }
}
