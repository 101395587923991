import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { SharedService } from 'app/shared/shared.service';
import { ISchoolClass } from '../../../shared/interfaces/ischoolclass.interface';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { ResultsService } from '../results.service';
import { UserAccessService } from 'app/shared/services/useraccess.service';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

@Component({
  selector: 'prf-page-result',
  templateUrl: './page-result.component.html',
  styleUrls: ['./page-result.component.scss'],
})
export class PageResultComponent implements OnInit, OnDestroy {
  public schoolClass: ISchoolClass;
  public userRole: string = '';
  public userId: string = '';
  private userData: IUserInfo;
  public sessionId: string = '';
  public urlSessionId: string = '';
  public sessionStatus: string = '';
  public testSessionId: string = '';
  public profile = {
    userId: '',
    userRole: '',
    firstName: '',
  };

  public profilumLogoUrl: string = AppSettingsService.settings.profilumLogoUrl;

  showFullResults: boolean = false;
  loaded: boolean = false;
  public isMobile: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private resultsService: ResultsService,
    private appSettingsService: AppSettingsService,
    private userAccessService: UserAccessService,
    private sharedService: SharedService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.getTranslation('SHARED.RESULT.S')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation['SHARED.RESULT.S'],
        }),
      );
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.userId = this.userDataHandlerService.getUserInfo().userId;
    this.userData = this.userDataHandlerService.getUserData().value;
    this.sessionStatus = localStorage.getItem('sessionStatus');
    this.testSessionId = localStorage.getItem('testSessionId');
    this.profile = {
      userId: this.userId,
      userRole: this.userRole,
      firstName: localStorage.getItem('firstName'),
    };
    this.isMobile = window.innerWidth <= 768;

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
      }
    });
  }

  ngOnInit() {
    if (this.userRole === 'pupil') {
      // this.userAccessService.requestUserAccesses(this.userRole);
      this.sharedResults()
        .pipe(
          switchMap(() =>
            this.userAccessService.getUserAccesses().pipe(
              switchMap(userAccesses => {
                if (userAccesses) {
                  return this.sharedService.getRegionTestInfo(this.userData.regionId).pipe(
                    tap((testInfo: ITestInfo) => {
                      const resultsAccess = userAccesses.find(el => el.screeningTestId === testInfo.screeningTestId);
                      this.showFullResults = resultsAccess?.isTestResult === true;
                      this.loaded = true;
                    }),
                  );
                } else {
                  return of(null);
                }
              }),
            ),
          ),
        )

        .subscribe();
    }
  }

  get headerTitle() {
    return !this.isMobile ? 'Результаты теста' : 'Ура, диагностика пройдена!';
  }

  public printPage() {
    window.print();
  }

  public sharedResults(): Observable<any> {
    return this.resultsService.getSharedResults(this.userId).pipe(
      switchMap(sharedResults => {
        if (sharedResults.length > 0) {
          return this.sharedService.getRegionTestInfo(localStorage.getItem('regionId')).pipe(
            tap((testInfo: ITestInfo) => {
              const sessionID = sharedResults.filter(
                result => result.screeningTestId === testInfo.screeningTestId && result.refferalUserId === this.userId,
              );
              if (sessionID.length > 0) {
                this.sessionId = sessionID[0].refferalSessionId;
              }
            }),
          );
        } else {
          return of(null);
        }
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 768;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
