import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { yandexMetrics } from './app/shared/analytics/yandex-metriks/yandex';
import { environment } from './environments/environment';
import postscribe from 'postscribe';

if (environment.production) {
  enableProdMode();
}

if (environment.analytics) {
  postscribe('#head', yandexMetrics);
}

platformBrowserDynamic().bootstrapModule(AppModule);
