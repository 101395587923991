<prf-menu></prf-menu>

<div class="content-section">
  <prf-page-header [title]="'Стажировки'"></prf-page-header>
  <div class="container">
    <div class="tabs">
      <div class="tabs-professions">
        <div *ngIf="userRole === 'pupil'" class="tabs-menu-div-2">
          <div class="tabs-menu-prof">
            <a (click)="tabName = 'catalog'" [class.current]="tabName === 'catalog'" class="tab-link-prof w-inline-block">
              <div>{{ 'SHARED.CATALOG' | translate }}</div>
              <img src="/assets/images/icons/angle.png" width="20" alt="" class="angle" />
            </a>
            <a (click)="tabName = 'recommendations'" [class.current]="tabName === 'recommendations'" class="tab-link-prof w-inline-block">
              <div>Рекомендованные</div>
              <img src="/assets/images/icons/angle.png" width="20" alt="" class="angle" [class.hide]="tabName !== 'recommendations'" />
              <img src="/assets/images/icons/angle2.png" width="20" alt="" class="angle _2" />
            </a>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'catalog'" class="tabs-content">
          <div class="tab-pane left">
            <prf-internship-catalog [tabName]="tabName"></prf-internship-catalog>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'recommendations'" class="tabs-content">
          <div class="tab-pane left">
            <prf-internship-recommendations [testResults]="testResults"></prf-internship-recommendations>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
