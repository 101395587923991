import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ProjectVariables } from 'app/core/project.variables';
import { AuthGuard } from './guards/auth.guard';
import { MainGuard } from './guards/main.guard';
import { CachingInterceptor } from './interceptors/caching.interceptor';
import { GuardService } from './services/guard.service';
import { RequestCache } from './services/request-cache.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    ProjectVariables,
    GuardService,
    AuthGuard,
    MainGuard,
    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  ],
  exports: [],
})
export class CoreModule {}
