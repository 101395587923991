<div class="results-section">
  <div class="diagnostic-passed">
    <div class="diagnostic-passed-text-block">
      <h1 class="diagnostic-passed-title">Диагностика пройдена! Результат покажет тьютор</h1>
      <p class="diagnostic-passed-desc">
        Ты молодец, тест позади! Записали твои ответы, результаты расшифрует тьютор на следующей встрече. Мы свяжемся с твоим родителем,
        чтобы запланировать её.
      </p>
    </div>
  </div>
</div>
