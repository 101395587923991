import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjectVariables } from '../../../core/project.variables';
import { PrEventsEnums } from '../../../shared/enums/pr-events.enums';

import { OverlayBusyService } from '../../../shared/overlay-busy/overlay-busy.service';
import { ChangePasswordService } from '../../../pages/catalog/settings-page/change-password/change-password.service';
import { CurrentStepService } from '../../../pages/control-panel/pupil/welcome-steps/current-step.service';
import { LoginService } from '../login-b2c/login.service';
import { RegistrationService } from '../registration-b2c/registration.service';
import { SharedService } from 'app/shared/shared.service';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'prf-tokenauth',
  templateUrl: './tokenauth.component.html',
  styleUrls: ['./tokenauth.component.scss'],
})
export class TokenauthComponent implements OnInit {
  queryParams: any;
  accessToken: any;
  tokenFailed: boolean = false;
  showMessage: boolean = false;
  alertMessage: string = '';

  buttonWaiting: boolean = false;
  buttonActivated: boolean = false;
  buttonActivate: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private loginService: LoginService,
    private currentStepService: CurrentStepService,
    private sharedService: SharedService,
    private changePasswordService: ChangePasswordService,
    private projectVariables: ProjectVariables,
    private overlayService: OverlayBusyService,
  ) {}

  ngOnInit() {
    this.overlayService.show();
    this.queryParams = this.route.snapshot.queryParams;
    this.accessToken = this.queryParams['accessToken'];

    if (this.accessToken && this.accessToken != '') {
      this.loginService
        .tokenLogin(this.accessToken)
        .pipe(
          switchMap((loginResult: any) => {
            // логика идентичная компоненту login
            if (loginResult.succeeded === false) {
              this.tokenFailed = true;
              this.showMessage = true;
              this.alertMessage = 'Токен неверный';
              return of(null);
            } else {
              localStorage.clear();

              localStorage.setItem('userRole', loginResult.role);
              localStorage.setItem('userId', loginResult.userId);
              localStorage.setItem('tag', loginResult.tag);
              localStorage.setItem('isAuthorized', 'true');

              return this.sharedService.getUserInfoData().pipe(
                switchMap(ui => {
                  localStorage.setItem('imagePath', ui.imagePath);
                  localStorage.setItem('firstName', ui.firstName);
                  localStorage.setItem('lastName', ui.lastName);
                  localStorage.setItem('schoolId', ui.schoolId);
                  localStorage.setItem('userGender', ui.gender);
                  localStorage.setItem('companyId', ui.companyId);
                  localStorage.setItem('position', ui.position);

                  switch (loginResult.role) {
                    case 'admin': {
                      this.router.navigate(['/admin/schools']);
                      return;
                    }
                    case 'schooladmin': {
                      this.router.navigate(['/schooladmin/classes']);
                      return;
                    }
                    case 'parent': {
                      this.router.navigate(['/parent']);
                      return;
                    }
                    case 'pupil': {
                      this.router.navigate(['/pupil']);
                    }
                  }

                  return this.currentStepService.getCurrentStep().pipe(
                    switchMap(welcomeStep => {
                      localStorage.setItem('welcomeStep', welcomeStep);

                      return this.changePasswordService.getUserSession(loginResult.userId).pipe(
                        tap(data => {
                          const sessionId = data.status == 'Success' || 'Uncompleted test' ? data.sessionId : undefined;
                          if (sessionId) {
                            localStorage.setItem('testSessionId', sessionId);
                            localStorage.setItem('sessionStatus', data.status);
                            if (loginResult.tag == PrEventsEnums[PrEventsEnums.Marathon]) {
                              localStorage.setItem('promoTag', loginResult.tag); // метка для определения участия пользователя в промо акции
                              return this.router.navigate(['/results-marathon', sessionId]);
                            }
                            this.router.navigate(['/pupil']);
                          } else if (data.status == 'Untested user') {
                            this.router.navigate(['/test-na-professiyu']);
                          }
                        }),
                      );
                    }),
                  );
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(() => this.removeWaiting());
    } else {
      this.showMessage = true;
      this.alertMessage = 'Нет токена в URL';
    }
    this.overlayService.hide();
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
    this.overlayService.hide();
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
    this.overlayService.hide();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
