<section class="schooladmin">
  <prf-schooladmin-menu></prf-schooladmin-menu>

  <div class="content-section">
    <prf-page-header [schoolTitle]="schoolTitle" [title]="setTitleHeader()"> </prf-page-header>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
