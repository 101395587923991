<prf-menu [menuAllowed]="menuAllowed" [setUserProfile]="userProfile" [root]="'parent'"></prf-menu>

<div class="content-section">
  <prf-page-header [title]="' '"></prf-page-header>
  <div class="container">
    <div class="registrations-steps-wrapper">
      <div class="registrations-steps">
        <div class="step-item" *ngFor="let step of registrationSteps; let i = index">
          <div class="step-number-container">
            <div class="step-number" [class.active]="step.status === 'active' || step.status === 'done'">
              {{ i + 1 }}
            </div>
          </div>
          <div class="step-name" [class.active]="step.status === 'active'" [class.done]="step.status === 'done'">
            {{ step.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-content">
      <div class="page-tab">
        <ng-container *ngIf="child; else registrChild">
          <div class="invite-child-container">
            <h1 class="invite-child-h1">Поделитесь ссылкой</h1>
            <div class="invite-child-text">Отправьте ссылку ребенку для входа <br />любым удобным способом...</div>
            <div class="invite-child-buttons">
              <div class="button-copy-container">
                <button
                  class="button-copy button-new"
                  [class.default-cursor]="child.registrationCode"
                  (click)="copyRegLink()"
                  (mouseover)="showToolTip = true"
                  (mouseout)="showToolTip = false"
                >
                  Скопировать ссылку
                </button>
                <div class="info-popup" #infoPopup *ngIf="showToolTip || linkIsCopied">
                  <p *ngIf="!linkIsCopied">Сгенерировать ссылку для регистрации ребенка</p>
                  <p *ngIf="linkIsCopied">Ссылка скопирована</p>

                  <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                </div>
              </div>
              <div class="button-share-container">
                <button class="button-share" (click)="openSharing()">Поделиться через...</button>
                <a class="share-link share-link_wp" href="https://wa.me/send?text={{ registrationPupilLink }}" target="_blank"
                  ><span class="share-link__text_hidden">WhatsApp</span></a
                >
                <a class="share-link share-link_tg" href="https://t.me/share/url?url={{ registrationPupilLink }}" target="_blank"
                  ><span class="share-link__text_hidden">Telegram</span></a
                >
                <a class="share-link share-link_email" href="mailto:?body={{ registrationPupilLink }}" target="_blank"
                  ><span class="share-link__text_hidden">Email</span></a
                >
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #registrChild> <prf-child-add-form (addedChild)="updateChild($event)"></prf-child-add-form></ng-template>
      </div>
    </div>
  </div>
</div>
