<prf-tutor-menu></prf-tutor-menu>
<div *ngIf="isLoaded" class="content-section">
  <prf-page-header [title]="title"></prf-page-header>
  <div class="container">
    <div class="return-button-container">
      <a (click)="routingBack()" class="no-link">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
    </div>

    <div *ngIf="!pupil" class="loader-container">
      <prf-content-loader></prf-content-loader>
    </div>

    <ng-container *ngTemplateOutlet="studentPage"></ng-container>
  </div>
</div>

<ng-template #studentPage>
  <div class="student-page-container" *ngIf="pupil">
    <ng-container *ngIf="showResults === false; else resultsPage">
      <div class="student-page-row">
        <div class="student-page-name">{{ pupil?.firstName }} {{ pupil?.lastName }}</div>
      </div>

      <section class="student-info-section">
        <div class="student-info-row">
          <div class="student-info-item">
            <div for="pupilEmail" class="info-item-key">E-mail</div>
            <div *ngIf="pupil.email" class="info-item-value">
              {{ pupil.email }}
            </div>
            <div *ngIf="!pupil.email" class="info-item-value">Почта не добавлена</div>
          </div>
          <div class="student-info-item">
            <div for="pupilCode" class="info-item-key">Код</div>
            <div class="info-item-value">{{ pupil.registrationCode }}</div>
          </div>

          <div class="student-info-item">
            <div for="parentEmail" class="info-item-key">Телефон ребенка</div>
            <div *ngIf="pupil?.phoneNumber" class="info-item-value">{{ pupil?.phoneNumber }}</div>
            <div *ngIf="!pupil?.phoneNumber" class="info-item-value">Номер не добавлен</div>
          </div>
        </div>
        <div class="student-info-row">
          <div class="student-info-item">
            <div for="parentName" class="info-item-key">Родитель</div>
            <div class="info-item-value">{{ parent?.lastName }} {{ parent?.firstName }} {{ parent?.middleName }}</div>
          </div>
        </div>

        <div class="student-info-row">
          <div class="student-info-item">
            <div for="parentEmail" class="info-item-key">E-mail родителя</div>
            <div class="info-item-value">{{ parent?.email }}</div>
          </div>
          <div class="student-info-item">
            <div for="parentEmail" class="info-item-key">Телефон родителя</div>
            <div *ngIf="parent?.phoneNumber" class="info-item-value">{{ parent?.phoneNumber }}</div>
            <div *ngIf="!parent?.phoneNumber" class="info-item-value">Номер не добавлен</div>
          </div>
        </div>
      </section>

      <section *ngIf="!hideDiagnostics" class="diagnostics-tabs-section">
        <h2>Диагностика</h2>

        <div class="diagnostics-tabs-wrapper">
          <div class="diagnostics-tabs-container">
            <div class="diagnostic-tab-wrapper" *ngFor="let diagnostic of diagnosticsList; trackBy: trackByFn">
              <div class="diagnostic-tab">
                <div class="diagnostic-name">{{ diagnostic.name }}</div>
                <div class="diagnostic-description">{{ diagnostic.description }}</div>
                <div class="diagnostic-status-bar">
                  <div class="status-bar-checkpoint" [ngClass]="{ done: diagnostic.diagnosticOpened }"></div>
                  <div
                    *ngIf="diagnostic.parent.testForParent"
                    class="status-bar-checkpoint"
                    [ngClass]="{
                      primary: diagnostic.parent.isParentStarted,
                      done: diagnostic.parent.isParentTested
                    }"
                  ></div>
                  <div
                    *ngIf="diagnostic.pupil.testForPupil"
                    class="status-bar-checkpoint"
                    [className]="{
                      primary: diagnostic.pupil.isPupilStarted && !diagnostic.pupil.isPupilTested,
                      done: diagnostic.pupil.isPupilTested
                    }"
                  ></div>
                  <div
                    class="status-bar-checkpoint"
                    [ngClass]="{
                      done: diagnostic.diagnosticState === 4
                    }"
                  ></div>
                </div>
                <div class="diagnostic-status-text">
                  {{ diagnostic.statusText }}
                </div>
                <div class="diagnostic-buttons">
                  <ng-container [ngSwitch]="true">
                    <div *ngSwitchCase="diagnostic.diagnosticState === 1">
                      <button
                        type="button"
                        class="diagnostic-button button-blue"
                        (click)="popUpTestAccess = true; selectedDiagnostic = diagnostic"
                      >
                        Открыть доступ
                      </button>
                    </div>
                    <div *ngSwitchCase="diagnostic.diagnosticState === 2">
                      <button type="button" class="diagnostic-button button-blue inactive">Открыта</button>
                    </div>
                    <div *ngSwitchCase="[3, 4].includes(diagnostic.diagnosticState)">
                      <button type="button" class="diagnostic-button button-blue" (click)="openResultsPage(diagnostic)">Результаты</button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>

    <ng-template #resultsPage>
      <div>
        <div class="results-access-block">
          <button
            type="button"
            class="button-results-access button-blue"
            [class.active]="selectedDiagnostic?.diagnosticState === 3 && selectedDiagnostic?.pupil.isPupilTested"
            (click)="popUpResultsAccess = true"
          >
            {{ selectedDiagnostic?.diagnosticState === 4 ? 'Результаты выданы' : 'Открыть результаты семье' }}
          </button>
        </div>

        <ng-container [ngSwitch]="selectedDiagnostic.screeningTestId">
          <ng-container *ngSwitchCase="diagnosticsIds.motivationtest">
            <div class="test-history-block">
              <div *ngIf="motivationLastSessionId; else notPassed" class="test-results">
                <prf-motivation-results [userRole]="userRole" [sessionId]="motivationLastSessionId"></prf-motivation-results>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="diagnosticsIds.valuesTest">
            <div class="test-history-block">
              <div *ngIf="valuesLastSessionId; else notPassed" class="test-results">
                <prf-values-results [userRole]="userRole" [sessionId]="valuesLastSessionId"></prf-values-results>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="diagnosticsIds.GetPassportOfTalentsByLocation()"
            ><prf-student-test-results [pupil]="pupil"></prf-student-test-results>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-container *ngIf="popUpTestAccess">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="popup-block">
      <a class="close-lb w-inline-block" (click)="popUpTestAccess = false">
        <img src="/assets/images/icons/close-lb.svg" alt="close icon" />
      </a>
      <div class="popup-column">
        <h4 class="task-lb-heading-result">Открыть доступ?</h4>
        <div class="task-result-text">
          После подтверждения ребёнок и родитель<br />
          смогут пройти диагностику
        </div>
        <div class="buttons-lb-row">
          <button type="button" class="button-blue button-accept" (click)="openDiagnosticAccess(selectedDiagnostic)">Да</button>
          <button type="button" class="button-blue" (click)="popUpTestAccess = false">Нет</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="popUpResultsAccess">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="popup-block">
      <a class="close-lb w-inline-block" (click)="popUpResultsAccess = false">
        <img src="/assets/images/icons/close-lb.svg" alt="close icon" />
      </a>
      <div class="popup-column">
        <h4 class="task-lb-heading-result">Открыть результаты семье?</h4>
        <div class="task-result-text">
          После этого родитель и подросток смогут увидеть<br />
          полные результаты диагностики в своих кабинетах
        </div>
        <div class="buttons-lb-row">
          <button type="button" class="button-blue button-accept" (click)="openResultsAccess(selectedDiagnostic)">Да</button>
          <button type="button" class="button-blue" (click)="popUpResultsAccess = false">Нет</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #notPassed>
  <div class="no-test-block">
    <div>
      <img src="/assets/images/icons/no-test-icon.png" width="80" class="no-profession-icon" alt="no-test-icon" />
    </div>
    <div class="empty-block-text">Тест не пройден</div>
  </div>
</ng-template>
