import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms/';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectVariables } from 'app/core/project.variables';
import { EAnketaStep, ETestType } from 'app/pages/player/anketa/anketa.enums';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';
import { PlayerService } from 'app/pages/player/player.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Gender } from 'app/shared/enums/gender.enums';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { SlideService } from 'app/shared/services/slide.service';
import { Observable, of, Subject, timer } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ETestTypes, ETestTypesSteps } from '../../../shared/enums/testtypes.enum';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

@Component({
  selector: 'prf-anketa',
  templateUrl: './anketa.component.html',
  styleUrls: ['./anketa.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnketaComponent implements OnInit, OnDestroy {
  public currentAnketaStep = 1;
  public steps = EAnketaStep;
  public forms: FormGroup[];
  public navigation: Subject<boolean>;
  public userInfo: any;
  public userData: IUserInfo;
  public testType: string = '';
  public testTypes = ETestTypes;
  public testTypesSteps = ETestTypesSteps;

  private anketa = { testType: ETestType.Default };

  public languageSelected: string = '';

  public isMobile: boolean = false;
  baseNavigationAllow: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    public anketaService: AnketaService,
    private router: Router,
    private playerService: PlayerService,
    private slideService: SlideService,
    private overlayService: OverlayBusyService,
    private projectVariables: ProjectVariables,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.userData = this.userDataHandlerService.getUserData().value;

    this.isMobile = window.innerWidth <= 450;
  }

  ngOnInit() {
    // тип теста устанавливается в анкете и в плеере, чтобы на тест можно было корректно попадать
    //как через анкету, так и через редирект на продолженние теста
    this.setTestType();
    this.forms = this.anketaService.getForms();
    this.navigation = new Subject();
    this.baseNavigationAllow =
      this.testType !== this.testTypes.DEFAULT_STANDART.toString() && this.testType !== this.testTypes.DEFAULT_360.toString();

    let isChild = false;
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
      params = params || {};
      if (params.type == 'child') {
        isChild = true;
        localStorage.setItem('sharedSession', params.id);
      } else {
        localStorage.removeItem('sharedSession');
      }
    });
    this.navigation
      .pipe(
        mergeMap(way => {
          if (localStorage.getItem('testType') === ETestTypes.MOTIVATION_TEST.toString()) {
            this.anketa.testType = ETestType.MotivationTest;
          } else if (localStorage.getItem('testType') === ETestTypes.VALUES_TEST.toString()) {
            this.anketa.testType = ETestType.ValuesTest;
          } else if (localStorage.getItem('testType') === ETestTypes.CAREER_TYPES.toString()) {
            this.anketa.testType = ETestType.CareerTypes;
          } else {
            this.anketa.testType = this.userInfo.role === 'parent' ? ETestType.Test360 : ETestType.Default;
          }
          return this.createSession();
        }),
      )
      .subscribe(
        () => {
          this.overlayService.hide();
        },
        err => {
          this.overlayService.hide();
          this.handleSessionError();
        },
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  private createSession(): Observable<any> {
    this.overlayService.show();
    let currentObservable$: Observable<any> = of(null);

    if (this.anketa.testType === ETestType.Default) {
      localStorage.getItem('parentUserId')
        ? Object.assign(this.anketa, {
            referralUserId: localStorage.getItem('parentUserId'),
          })
        : Object.assign(this.anketa, {
            referralUserId: '00000000-0000-0000-0000-000000000000',
          });
      currentObservable$ = this.playerService.createSession(this.anketa).pipe(
        switchMap(sessionResult => {
          if (!sessionResult || !sessionResult.status || sessionResult.status === 'Failed to create testing session') {
            this.handleSessionError();
            return of(null);
          } else {
            return timer(1500).pipe(
              switchMap(r => {
                if (sessionResult.status == 'Success') {
                  if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                    localStorage.setItem(
                      'lastSession',
                      JSON.stringify({
                        sessionID: sessionResult.sessionId,
                        user: this.anketa,
                      }),
                    );
                  }
                  return this.playerService.getNextSlide(sessionResult.sessionId).pipe(
                    tap(value => {
                      this.slideService.setCurrentSlide(sessionResult.sessionId, value);
                      this.router.navigate(['/test-na-professiyu', sessionResult.sessionId]).then(() => {
                        this.overlayService.hide();
                      });
                    }),
                  );
                } else {
                  return of(null);
                }
              }),
            );
          }
        }),
      );
    }
    if (this.anketa.testType === ETestType.Test360) {
      currentObservable$ = this.playerService.createSessionParent(this.anketa).pipe(
        switchMap(sessionResult => {
          return timer(1500).pipe(
            switchMap(r => {
              //todo обновить проверку поле изменений на бэке
              //if (sessionResult.status == 'Success') {
              if (sessionResult.sessionId && sessionResult.sessionId !== '00000000-0000-0000-0000-000000000000') {
                if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                  localStorage.setItem(
                    'lastSession',
                    JSON.stringify({
                      sessionID: sessionResult.sessionId,
                      user: this.anketa,
                    }),
                  );
                }
                return this.playerService.getNextSlide(sessionResult.sessionId).pipe(
                  tap(value => {
                    this.slideService.setCurrentSlide(sessionResult.sessionId, value);
                    this.router.navigate(['/test-na-professiyu', sessionResult.sessionId]).then(() => {
                      this.overlayService.hide();
                    });
                  }),
                );
              } else if (sessionResult.comment == "Can't find child testing session") {
                this.utilsService.openSnackBar('Ребенок еще не начал тестирование', 'error');
                return of(null);
              } else {
                this.utilsService.openSnackBar('Неполадки на сервере', 'error');
                return of(null);
              }
            }),
          );
        }),
      );
    }
    if (this.anketa.testType === ETestType.MotivationTest) {
      const testId = TESTS_VARIANTS_IDS.motivationtest;
      const data = {
        testId,
        userId: this.userData.userId,
        personalInfo: {
          User: {
            Gender: Gender.Male,
          },
        },
      };

      currentObservable$ = this.playerService.getPlayerSession(data).pipe(
        switchMap(sessionResult => {
          let sessionId: string;
          if (sessionResult && sessionResult.status === 'Success') {
            sessionId = sessionResult.sessionId;
            return timer(1500).pipe(
              switchMap(() => {
                if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                  localStorage.setItem(
                    'lastSession',
                    JSON.stringify({
                      sessionID: sessionId,
                      user: this.anketa,
                    }),
                  );
                }
                return this.playerService.getNextSlide(sessionId).pipe(
                  tap(value => {
                    this.slideService.setCurrentSlide(sessionId, value);
                    this.router.navigate(['/motivation-test', sessionId]).then(() => {
                      this.overlayService.hide();
                    });
                  }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
      );
    }
    if (this.anketa.testType === ETestType.ValuesTest) {
      const testId = TESTS_VARIANTS_IDS.valuesTest;
      const data = {
        testId,
        userId: this.userData.userId,
        personalInfo: {
          User: {
            Gender: Gender.Male,
          },
        },
      };

      currentObservable$ = this.playerService.getPlayerSession(data).pipe(
        switchMap(sessionResult => {
          let sessionId: string;
          if (sessionResult && sessionResult.status === 'Success') {
            sessionId = sessionResult.sessionId;
            return timer(1500).pipe(
              switchMap(() => {
                if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                  localStorage.setItem(
                    'lastSession',
                    JSON.stringify({
                      sessionID: sessionId,
                      user: this.anketa,
                    }),
                  );
                }
                return this.playerService.getNextSlide(sessionId).pipe(
                  tap(value => {
                    this.slideService.setCurrentSlide(sessionId, value);
                    this.router.navigate(['/values-test', sessionId]).then(() => {
                      this.overlayService.hide();
                    });
                  }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
      );
    }

    if (this.anketa.testType === ETestType.CareerTypes) {
      const testId = TESTS_VARIANTS_IDS.careerTypes;
      const data = {
        testId,
        userId: this.userData.userId,
        personalInfo: {
          User: {
            Gender: Gender.Male,
          },
        },
      };

      currentObservable$ = this.playerService.getPlayerSession(data).pipe(
        switchMap(sessionResult => {
          let sessionId: string;
          if (sessionResult && sessionResult.status === 'Success') {
            sessionId = sessionResult.sessionId;
            return timer(1500).pipe(
              switchMap(() => {
                if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                  localStorage.setItem(
                    'lastSession',
                    JSON.stringify({
                      sessionID: sessionId,
                      user: this.anketa,
                    }),
                  );
                }
                return this.playerService.getNextSlide(sessionId).pipe(
                  tap(value => {
                    this.slideService.setCurrentSlide(sessionId, value);
                    this.router.navigate(['/career-test', sessionId]).then(() => {
                      this.overlayService.hide();
                    });
                  }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
      );
    }
    return currentObservable$;
  }

  setTestType() {
    this.route.url.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(url => {
      url.map(segment => segment.path).includes('motivation-test')
        ? localStorage.setItem('testType', ETestTypes.MOTIVATION_TEST.toString())
        : null;
      url.map(segment => segment.path).includes('mosrupromo') ? localStorage.setItem('testType', ETestTypes.PROMO_TEST.toString()) : null;
      url.map(segment => segment.path).includes('values-test') ? localStorage.setItem('testType', ETestTypes.VALUES_TEST.toString()) : null;
      url.map(segment => segment.path).includes('career-test')
        ? localStorage.setItem('testType', ETestTypes.CAREER_TYPES.toString())
        : null;

      url.map(segment => segment.path).includes('test-na-professiyu')
        ? this.userInfo.role == 'pupil'
          ? localStorage.setItem('testType', ETestTypes.DEFAULT_STANDART.toString())
          : this.userInfo.role == 'parent'
            ? localStorage.setItem('testType', ETestTypes.DEFAULT_360.toString())
            : null
        : null;
      this.testType = localStorage.getItem('testType');
      if (this.testType == null) {
        if (this.userInfo.role == 'pupil') {
          this.testType = ETestTypes.DEFAULT_STANDART.toString();
        }
        if (this.userInfo.role == 'parent') {
          this.testType = ETestTypes.DEFAULT_360.toString();
        } else {
          this.testType = ETestTypes.DEFAULT_STANDART.toString();
        }
      }
    });
  }

  handleSessionError() {
    this.utilsService.openSnackBar('Неполадки на сервере, уже чиним', 'error');
    setTimeout(() => {
      this.overlayService.hide();
      this.utilsService.closeSnackBar();
      // return this.router.navigate(['/']);
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }
}
