import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PlayerService } from 'app/pages/player/player.service';
import { IResult, IResultsData } from 'app/pages/results/results.interface';
import { ResultsService } from 'app/pages/results/results.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppSettingsService } from '../../../../../shared/services/appsettings.service';
import { TESTS_VARIANTS_IDS } from '../../../../../shared/global-constants/tests-variants.data';
import { EChildInviteSteps } from '../parent-child-invite/parent-child-invite.component';
import { SharedService } from '../../../../../shared/shared.service';
import { ParentPanelService } from '../../parent-panel.service';
import { UtilsService } from '../../../../../shared/dashboard/backend-services/utils.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-parent-results-shortened',
  templateUrl: './parent-results-shortened.component.html',
  styleUrls: ['./parent-results-shortened.component.scss'],
})
export class ParentResultsShortenedComponent extends UnsubscribeComponent implements OnInit {
  @Input()
  set sessionId(val: string) {
    //загружаем результаты по sessionId
    this._sessionId = val;
    if (val) {
      this.playerService
        .getResults(this._sessionId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(params => {
          this.parseResults(params);

          setTimeout(() => this.overlayService.hide(), 500);
          // данные загружены
          this.resultsFetched = true;
          this.loaded.emit(true);
        });
    }
  }

  get sessionId() {
    return this._sessionId;
  }

  @Output() loaded = new EventEmitter<boolean>();

  public interestsTopCategories: any[] = [];
  public skillsTopCategories: any[] = [];

  public skillsAttainmentData = [];
  public interestsData = [];
  public talentsData = [];

  public fieldsData = [];
  public userRole: string = '';
  public isCompleteTestParent: boolean = false;

  public skillsAttainment: IResult[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public professionList: IProfession[] = [];

  public additionalText: Array<string> = [];
  public userId: string = '';
  public parent: any = {};
  public results: any;
  public chartSize: number = 560; // [px]

  _sessionId: string = null;
  nullResults: boolean = false;
  resultsFetched: boolean = false;

  public child: any;
  public childRegistered: boolean = false;
  public childTested: boolean = false;
  public whatsAppLink: string;
  public childInviteStep: EChildInviteSteps = EChildInviteSteps.CONFIRM_PHONE;
  public testVariantIds = TESTS_VARIANTS_IDS;

  constructor(
    private playerService: PlayerService,
    private overlayService: OverlayBusyService,
    private resultService: ResultsService,
    private sharedService: SharedService,
    private parentService: ParentPanelService,
    private utilsService: UtilsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setChartSize(window.innerWidth);
    this.getUserInfo()
      .pipe(
        take(1),
        switchMap(() => {
          this.whatsAppLink = AppSettingsService.settings.workWhatsApp;

          if (this.child) {
            return this.sharedService.getSessionList(this.child.userId).pipe(
              tap(sessions => {
                if (sessions && sessions.length) {
                  let testSessions: any[] = [];

                  testSessions = sessions.filter(el => el.screeningTestId == this.testVariantIds.passportOfTalents);

                  const testSessionsCompleted = testSessions
                    .filter(session => session.completed)
                    .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

                  if (testSessionsCompleted && testSessionsCompleted.length > 0) {
                    this.childTested = testSessionsCompleted[0].completed;
                  }
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private getUserInfo(): Observable<any> {
    return this.sharedService.getUserInfoData().pipe(
      take(1),
      tap(userInfo => {
        this.child = userInfo.children[0];

        if (this.child) {
          this.childRegistered = this.child?.id !== '00000000-0000-0000-0000-000000000000';
        }
      }),
    );
  }

  public changePhoneNumber(phone: string): void {
    this.parentService
      .changePhoneNumber(phone.replace(/\D/g, ''))
      .pipe(
        switchMap(response => {
          if (response.status === 'Success') {
            return this.parentService.inviteChild().pipe(
              take(1),
              switchMap(response => {
                if (response.status === 'Success') {
                  this.childInviteStep = EChildInviteSteps.LINK_SENT;
                  return this.sharedService.requestUserInfo().pipe(switchMap(() => this.getUserInfo()));
                } else {
                  this.openSnackBar(response);
                  return of(null);
                }
              }),
            );
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private parseResults(results: IResultsData): any | void {
    const resultsData = JSON.parse(JSON.stringify(results));

    resultsData.results = resultsData.results.map(item => {
      item.results = item.results.filter(s => s.sessionId === this._sessionId);
      return item;
    });

    if (!this.checkResultsCategories(resultsData.results, ['SkillAttainment', 'TalentAttainment'])) {
      this.nullResults = true;
      setTimeout(() => this.overlayService.hide(), 500);
      // данные загружены
      this.resultsFetched = true;
      this.loaded.emit(true);
      return;
    }

    this.skillsAttainment = resultsData.results.filter(d => d.objectType === 'SkillAttainment');
    this.interests = resultsData.results.filter(d => d.objectType === 'TalentAttainment');

    this.interestsData = this.resultService.transformInterests(this.interests, this.sessionId, null);
    this.interestsTopCategories = this.interestsData.map(item => {
      return {
        name: item.title,
        childValue: item.childEstimate,
        parentValue: item.parentEstimate,
      };
    });
    if (this.interestsTopCategories['childValue']) {
      this.interestsTopCategories = this.interestsTopCategories.sort((a, b) => b.childValue - a.childValue).slice(0, 3);
    } else {
      this.interestsTopCategories = this.interestsTopCategories.sort((a, b) => b.parentValue - a.parentValue).slice(0, 3);
    }
    let pupilSessionId = null;
    this.skillsAttainmentData = this.resultService.transformSkills(this.skillsAttainment, pupilSessionId);

    this.skillsTopCategories = [];

    for (let i = 0; i < this.skillsAttainmentData[0].length; i++) {
      this.skillsTopCategories.push({
        childValue: this.skillsAttainmentData[0][i] ? Math.round(this.skillsAttainmentData[0][i]['value'] * 100) : null,
        parentValue: this.skillsAttainmentData[1][i] ? Math.round(this.skillsAttainmentData[1][i]['value'] * 100) : null,
        name: this.skillsAttainmentData[0][i]['axis'],
      });
    }
    //parent sessionId
    this.skillsTopCategories = this.skillsTopCategories.sort((a, b) => b.parentValue - a.parentValue).slice(0, 3);
  }

  private checkResultsCategories(resultsArray, categoriesNames: string[]): boolean {
    let resultsAvailable: boolean;
    categoriesNames.forEach(categoryName => {
      let categoryResultsArray = resultsArray.filter(r => r.objectType === categoryName);
      resultsAvailable = categoryResultsArray.every(resultItem => resultItem.results?.length > 0);
    });
    return resultsAvailable;
  }

  private setChartSize(size: number): void {
    if (size > 890 && size <= 960) {
      this.chartSize = 500;
    } else if (size > 460 && size <= 890) {
      this.chartSize = 450;
    } else if (size <= 460 && size > 370) {
      this.chartSize = 400;
    } else if (size <= 370) {
      this.chartSize = 350;
    } else {
      this.chartSize = 560;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.setChartSize(event.target.innerWidth);
  }

  public openSnackBar(response): void {
    if (response.comment) {
      this.utilsService.openSnackBar(`Серверная ошибка: ${response.comment}`, 'error');
    } else {
      this.utilsService.openSnackBar(
        'Обратитесь в поддержку по клику на зелёную кнопку внизу. Ответим в течение нескольких минут',
        'error',
      );
    }
  }

  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
