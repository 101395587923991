import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RecommendationsComponent } from './recommendations.component';
import { SupportBlockComponent } from '../../../shared/common-components/support-block/support-block.component';

@NgModule({
  declarations: [RecommendationsComponent],
  imports: [CommonModule, RouterModule, SharedModule, BrowserModule, SupportBlockComponent],
})
export class RecommendationsModule {}
