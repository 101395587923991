import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[prfTooltip]',
})
export class TooltipDirective {
  @Input() prfTooltip: string = '';

  private visible: boolean = false;
  private componentRef: ComponentRef<TooltipComponent>;

  constructor(
    private element: ElementRef,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  @HostListener('focusin', ['$event', '$target'])
  @HostListener('mouseenter', ['$event', '$target'])
  show() {
    if (this.visible) {
      return;
    }

    if (this.prfTooltip) {
      this.visible = true;
      this.createComponent();
    }
  }

  @HostListener('focusout', ['$event', '$target'])
  @HostListener('mouseleave', ['$event', '$target'])
  hide() {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    this.removeComponent();
  }

  private createComponent() {
    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent).create(this.injector);
    this.applicationRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.componentRef.instance.params = {
      hostRef: this.element.nativeElement,
      text: this.prfTooltip,
    };
  }

  private removeComponent() {
    this.applicationRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }
}
