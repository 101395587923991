import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IResultsAccess } from '../interfaces/iresults.access';
import { UserDataHandlerService } from '@profilum-library';

@Injectable()
export class UserAccessService {
  userAccesses$ = new BehaviorSubject<IResultsAccess[]>(null);

  constructor(
    private http: HttpService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
  }

  requestUserAccesses(userRole): Observable<any> {
    return this.getResultsAccess(userRole)
      .pipe(
        take(1),
        tap(r => {
          if (r.status === 'Success') {
            this.setAccesses(r.screeningTestsAccesses);
          } else {
            this.setAccesses([]);
          }
        }),
        catchError(err => {
          this.setAccesses([]);
          throw err;
        }),
      )
      .pipe(take(1));
  }

  getResultsAccess(userRole): Observable<any> {
    let domen: string;
    if (userRole === 'pupil') {
      domen = 'pupils';
    } else if (userRole === 'parent') {
      domen = 'parents';
    }
    return this.http.get('/b2c/v1.0/' + domen + '/getscreeningtestsaccesses').pipe(map(r => r));
  }

  setAccesses(accesses: IResultsAccess[]) {
    this.userAccesses$.next(accesses);
  }

  getUserAccesses(): Observable<any> {
    const userRole = this.userDataHandlerService.getUserInfo().role;
    return this.userAccesses$.asObservable().pipe(
      switchMap(userAccesses => {
        if (userAccesses) {
          return of(userAccesses);
        } else {
          return this.requestUserAccesses(userRole).pipe(switchMap(() => this.userAccesses$.asObservable()));
        }
      }),
    );
  }
}
