<div class="container">
  <div class="profile-section">
    <div class="profile-row" *ngIf="userProfile">
      <div class="profile-column _1">
        <div class="profile-row-2">
          <div class="user-name-profile">
            <span
              >{{ userProfile.firstName }}
              {{ userProfile.middleName && userProfile.middleName.length > 0 ? ' ' + userProfile.middleName : '' }}
              {{ ' ' + userProfile.lastName }}</span
            >
          </div>
          <div *ngIf="userRole === 'schooladmin'" class="user-school-profile">
            <span>{{ school && school.number ? school.number : ('SHARED.SCHOOL_NUMBER_NOT_FOUND' | translate) }} </span>

            <br />
            <span>{{ municipality && municipality.name ? municipality.name : ('SHARED.MUNICIPALITY_NOT_FOUND' | translate) }} </span>
          </div>
        </div>

        <div class="profile-row-2">
          <div *ngIf="userRole === 'pupil'" class="profile-code">
            <div>{{ 'SHARED.MY_CODE' | translate }}</div>
            <div class="code-text">{{ userProfile.registrationCode }}</div>
            <div class="info-div">
              <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
              <div class="info-popup" *ngIf="showInfoPopup">
                <p>Здесь будет текст про мой код регистрации, зачем он нужен и как им пользоваться.</p>
                <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
              </div>
            </div>
          </div>

          <div *ngIf="userRole === 'parent' && !mosruUser" class="children-list-row">
            <div class="children-list-text">Дети:</div>
            <div class="children-list-column">
              <a
                *ngFor="let child of userProfile.children"
                routerLink="/parent/{{ child.userId }}/profile"
                class="children-list-link w-inline-block"
              >
                <div [ngStyle]="{ 'background-image': 'url(' + getImageUrl(child) + ')' }" class="children-list-photo"></div>
                <div>{{ child.lastName }} {{ child.firstName }}</div>
              </a>
            </div>
          </div>

          <div *ngIf="userRole === 'pupil'" class="profile-row-2-column">
            <ng-container *ngIf="parents.length === 0">
              <div class="parent-row not-invited">
                <div class="parent-photo"></div>
                <div><a (click)="inviteParent()">Пригласи родителя</a> для полноценной оценки твоих талантов</div>
              </div>
            </ng-container>

            <ng-container *ngIf="parents.length > 0">
              <div *ngFor="let parent of parents" class="parent-row not-invited">
                <div class="parent-photo uploaded" [ngStyle]="{ 'background-image': 'url(' + getImageUrl(parent) + ')' }"></div>
                <div>
                  <span class="parent-name-profile">
                    {{ parent.firstName }}
                    {{ parent.middleName && parent.middleName.length > 0 ? ' ' + parent.middleName : '' }}
                    {{ ' ' + parent.lastName }}
                  </span>
                  <br />
                  <span class="parent-email">{{ parent.email }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div [ngClass]="{ opened: animIndex == 0, toOpen: animIndex == 1, toClose: animIndex == 2 }" class="user-data-form-block w-form">
          <div class="profile-data-row">
            <div>
              <label class="user-data-label">{{ 'SHARED.FORM.FAMILY' | translate }}:</label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <div class="profile-data-text">{{ userProfile.lastName }}</div>
            </div>
          </div>
          <div class="div-space-2"></div>
          <div class="profile-data-row">
            <div>
              <label class="user-data-label">{{ 'SHARED.FORM.NAME' | translate }}:</label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <div class="profile-data-text">{{ userProfile.firstName }}</div>
            </div>
          </div>
          <div class="div-space-2"></div>
          <div class="profile-data-row">
            <div>
              <label class="user-data-label">{{ 'SHARED.FORM.MIDDLENAME' | translate }}:</label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <div class="profile-data-text">{{ userProfile.middleName }}</div>
            </div>
          </div>

          <div class="div-space-2"></div>

          <div class="profile-data-row">
            <div>
              <label class="user-data-label">{{ 'SHARED.FORM.PHONE' | translate }}:</label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <div class="profile-data-text">{{ userProfile.phoneNumber }}</div>
            </div>
          </div>

          <div class="div-space-2"></div>

          <div class="profile-data-row">
            <div>
              <label class="user-data-label">Email:</label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <div class="profile-data-text">{{ userProfile.email }}</div>
            </div>
          </div>

          <div class="div-space-2"></div>

          <div class="profile-data-row">
            <div>
              <label class="user-data-label">Дата рождения:</label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <div class="profile-data-text">{{ userBirthday }}</div>
            </div>
          </div>

          <div class="profile-data-row gender-choice">
            <label class="login-label">Пол:</label>
            <div class="div-space"></div>
            <div class="gender-choice-row">
              <a class="gender-button" [ngClass]="{ active: getMale() == true }"> М </a>
              <a class="gender-button right" [ngClass]="{ active: getMale() !== true }"> Ж </a>
            </div>
          </div>

          <div class="div-space-2"></div>

          <!-- <a
            (click)="editProfile = !editProfile; changeIndex(animIndex)"
            class="white-button edit-profile-data-button w-button"
            >{{ 'SHARED.EDIT_BUTTON' | translate }}</a
          > -->
        </div>
        <!-- *ngIf="editProfile" -->
        <!-- <prf-edit-user-profile-outlet
          class="left-margin"
          [ngClass]="{closed_: animIndex == 0, toOpen_: animIndex == 2, toClose_: animIndex == 1}"
          [animIndexToChild]="animIndex"
          (indexFromChild)="indexFromChild($event)"
          (editProfile)="editProfile = $event"
          [user]="userProfile"
          [userRole]="userRole"
        ></prf-edit-user-profile-outlet> -->
      </div>

      <div class="profile-column">
        <div class="user-photo-profile" [ngStyle]="{ 'background-image': 'url(' + userInfo.imagePath + ')' }">
          <div class="user-photo-change-bg">
            <a (click)="upload.click()" class="user-photo-change-button w-button">Изменить</a>
            <input type="file" (change)="selectFile($event)" #upload hidden />
          </div>
        </div>

        <mat-error *ngIf="uploadImageError" class="upload-image-error">{{ uploadImageError }}</mat-error>

        <!-- блок для смены пароля -->
        <div class="profile-changePassword">
          <ng-container *ngTemplateOutlet="passwordChange"></ng-container>
        </div>
      </div>
    </div>
    <a (click)="logOut()" class="exit w-inline-block">
      <img src="/assets/images/icons/exit-icon.svg" class="exit-profile-icon" />
      <div>Выход</div>
    </a>
  </div>

  <div class="invite-parent-lb lb" [ngStyle]="{ display: showInviteParent ? 'block' : 'none' }">
    <div class="lb-bg"></div>
    <div class="lb-block">
      <div class="invite-parent-row">
        <div class="invite-parent-column">
          <h3 class="h3-invite-parent">Пригласить родителя</h3>
          <p class="invite-parent-text">
            Для полноценной оценки твоих талантов пригласи родителя. Напиши email и родителю придет приглашение.
          </p>
          <div class="w-form">
            <form id="wf-form-Invite-Parent" name="wf-form-Invite-Parent" data-name="Invite Parent">
              <label for="Parent-Email" class="invite-parent-label">Email родителя</label>
              <input
                type="email"
                class="user-data-field invite-field w-input"
                maxlength="70"
                name="Parent-Email"
                data-name="Parent Email"
                placeholder="thealexganin@gmail.com"
                id="Parent-Email"
                required=""
              />
              <input
                type="submit"
                value="{{ 'SHARED.SEND_INVITE' | translate }}"
                data-wait="Please wait..."
                class="button invite-parent-button w-button"
              />
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <img
          src="/assets/images/parent/invite-parent.png"
          width="279"
          srcset="/assets/images/parent/invite-parent-p-500.png 500w, /assets/images/parent/invite-parent.png 558w"
          sizes="(max-width: 767px) 100vw, 279px"
          class="invite-parent-image"
        />
      </div>
      <a (click)="inviteClose()" class="close-lb w-inline-block">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
    </div>
  </div>
</div>

<ng-template #passwordChange>
  <div class="profile-changePassword-text" (click)="changePasswordFlag = !changePasswordFlag">
    {{ 'SHARED.CHANGE_PASSWORD' | translate }}
  </div>
  <div class="profile-changePassword-block" *ngIf="changePasswordFlag">
    <div class="overlay">
      <form id="form-change-pass" name="wf-form-User-Data" data-name="User Data" [formGroup]="form" autocomplete="off">
        <div class="changePass_box">
          <!-- поп-ап с полями паролей -->
          <div class="profile-data-row">
            <div>
              <label for="PasswordOld" class="user-data-label">
                <span class="password-label">{{ 'SHARED.OLD_PASSWORD' | translate }}:</span>
              </label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <input
                *ngIf="isMaskedPasswordOld"
                type="password"
                class="user-data-field w-input"
                name="PasswordOld"
                data-name="PasswordOld"
                id="PasswordOld"
                formControlName="passwordOld"
                autocomplete="off"
              />
              <input
                *ngIf="!isMaskedPasswordOld"
                type="text"
                class="user-data-field w-input"
                name="PasswordOld"
                data-name="PasswordOld"
                id="PasswordOld"
                formControlName="passwordOld"
                autocomplete="off"
              />
              <div [ngClass]="{ eye: !isMaskedPasswordOld, eye__closed: isMaskedPasswordOld }" (click)="toggleMaskOld()"></div>
              <div class="alertred" *ngIf="submitted">
                <mat-error *ngIf="errorOldPass">
                  {{ errorOldPass }}
                </mat-error>
              </div>
            </div>
          </div>

          <div class="div-space-2"></div>
          <div class="profile-data-row">
            <div>
              <label for="Password" class="user-data-label">
                <span class="password-label">{{ 'PASS_CHANGE.NEW_PASSWORD' | translate }}:</span>
              </label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div" (focusout)="focusOutErrorChecking()" (focusin)="focusOutPasswordErrors = false">
              <input
                *ngIf="isMaskedPassword"
                type="password"
                class="user-data-field w-input short-right"
                [class.error-field]="submitted && f.password.errors"
                maxlength="256"
                name="Password"
                data-name="Password"
                id="Password"
                formControlName="password"
                (ngModelChange)="testPassword($event)"
                (focus)="popupPassword.style.display = 'block'"
                (focusout)="popupPassword.style.display = 'none'"
                autocomplete="off"
              />

              <input
                *ngIf="!isMaskedPassword"
                autocomplete="off"
                type="text"
                class="user-data-field w-input short-right"
                [class.error-field]="submitted && f.password.errors"
                maxlength="256"
                name="Password"
                data-name="Password"
                id="PasswordUnMask"
                formControlName="password"
                (ngModelChange)="testPassword($event)"
                (focus)="popupPassword.style.display = 'block'"
                (focusout)="popupPassword.style.display = 'none'"
              />

              <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
              <div
                class="info-popup recommendation w-hidden-small w-hidden-tiny hide-popup"
                #popupPassword
                [style.display]="errorChecking() ? 'block' : 'none'"
              >
                <p>Требования к паролю (не менее)</p>
                <ol class="info-exacting">
                  <li class="info-pswrd-icon info-popup-pswrd" [class.not-correct]="charactersError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.6_CHARACTERS' | translate }}
                  </li>
                  <li class="info-pswrd-icon info-popup-letter" [class.not-correct]="letterError">1 строчная буква</li>
                  <li class="info-pswrd-icon info-popup-digit" [class.not-correct]="numberError">1 цифра</li>
                </ol>
                <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
              </div>
              <div class="error-field-container" *ngIf="focusOutPasswordErrors">
                <div>
                  {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.INCORRECT_FORMAT' | translate }}
                </div>
                <ul>
                  <li *ngIf="charactersError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.LESS_6_CHARACTERS' | translate }}
                  </li>
                  <li *ngIf="letterError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.NO_LOWERCASE_LETTER' | translate }}
                  </li>
                  <li *ngIf="numberError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.NO_DIGIT' | translate }}
                  </li>
                  <li *ngIf="whiteSpaceError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.INCORRECT_CHARACTERS' | translate }}
                  </li>
                  <li *ngIf="rusLettersError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.RUS_LETTERS' | translate }}
                  </li>
                </ul>
              </div>

              <ng-container *ngIf="submitted">
                <mat-error *ngIf="errorChangePass">
                  {{ errorChangePass }}
                </mat-error>
              </ng-container>
            </div>
          </div>

          <!-- кнопки -->
          <div class="profile-data-row">
            <div class="div-space-3"></div>
            <div class="edit-profile-data-buttons">
              <a (click)="submitChanges()" [ngClass]="{ disabled_: !isAccessAllowed() }" class="button save-profile-changes-button w-button"
                >Сохранить</a
              >
              <a class="white-button w-button" (click)="changePasswordFlag = !changePasswordFlag; cancelEdit(); clearPassError()"
                >Отменить</a
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
