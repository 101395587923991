// Компанент для ввода нового пароля.
// Попадаем на него по ссылке на восстановление пароля.
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationService } from 'app/landing/b2c/registration-b2c/registration.service';
import { ChangePasswordService } from 'app/pages/catalog/settings-page/change-password/change-password.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'prf-change-password-kazahstan',
  templateUrl: './change-password-kazahstan.component.html',
  styleUrls: ['./change-password-kazahstan.component.scss'],
})
export class ChangePasswordKazahstanComponent implements OnInit {
  public form: UntypedFormGroup;

  public readonly passwordRegExp: RegExp = REG_EXP.passwordRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;

  public submitted: boolean = false;
  public isMaskedPassword: boolean = false;

  public passwordError: boolean = false;
  public charactersError: boolean = false;
  public letterError: boolean = false;
  public numberError: boolean = false;
  public whiteSpaceError: boolean = false;

  private email: string = null;
  private token: string = null;

  public password: string = '';

  public passFailed: boolean = false;
  public loginFailed: boolean = false;
  public isSuccess: boolean = false;
  public rusLettersError: boolean = false;

  public focusOutPasswordErrors: boolean = false;

  public isKz: boolean = true;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changePasswordService: ChangePasswordService,
    private registrationService: RegistrationService,
    private translate: TranslateService,
    private utilsService: UtilsService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Страница изменения пароля' });
    const language = localStorage.getItem('selectedLanguage');
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz' ? true : false;
      this.translate.use(language);
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
      this.email = params.userEmail;
      this.token = params.token;
    });

    this.form = this.fb.group({
      password: new UntypedFormControl(this.password, [Validators.required, Validators.pattern(this.passwordRegExp)]),
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  get f() {
    return this.form.controls;
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public focusOutErrorChecking() {
    if (this.form.value.password != '') {
      this.focusOutPasswordErrors =
        this.charactersError || this.letterError || this.numberError || this.rusLettersError || this.whiteSpaceError;
    }
  }

  private testPassword(event): boolean {
    this.passwordError = false;

    this.whiteSpaceError = false;
    this.rusLettersError = false;
    this.charactersError = true;
    this.letterError = true;
    this.numberError = true;
    this.rusLettersError = false;
    if (event && event.length > 0) {
      this.passwordError = !this.passwordRegExp.test(event);
      this.charactersError = !this.testSixCharter.test(event);
      this.letterError = !this.testOneLetter.test(event);
      this.numberError = !this.testOneDigit.test(event);
      this.rusLettersError = this.testRusLetters.test(event);
      this.whiteSpaceError = !this.testWhiteSpace.test(event);
      if ((this.whiteSpaceError = !this.testWhiteSpace.test(event))) {
        return false;
      }
      if ((this.charactersError = !this.testSixCharter.test(event))) {
        return false;
      }
      if ((this.letterError = !this.testOneLetter.test(event))) {
        return false;
      }
      if ((this.numberError = !this.testOneDigit.test(event))) {
        return false;
      }
      if ((this.rusLettersError = this.testRusLetters.test(event))) {
        return false;
      }
      return true;
    }
  }

  public errorChecking(): boolean {
    if (this.passwordError || this.whiteSpaceError) {
      return true;
    }
  }

  public isAccessAllowed() {
    return this.testPassword(this.form.value.password);
  }

  /* public onInput(event) {
     this.password = event.target.value;
     this.invalidPassword = !((this.password.length >= 6 && this.password.length <= 12) &&
       /[0-9]+/.test(this.password) && /[a-zA-Z]+/.test(this.password));
   }*/

  public sendCredentials() {
    if (this.email && this.token) {
      this.changePasswordService
        .updatePassword(this.email, this.form.value.password, this.token)
        .pipe(
          switchMap(response => {
            if (response.status == 'Success') {
              // Сразу попадаем в ЛК
              return this.registrationService.login(this.email, this.form.value.password, false).pipe(
                tap(loginResult => {
                  if (loginResult) {
                    if (!loginResult.succeeded) {
                      this.loginFailed = true;
                      this.passFailed = true;
                      return;
                    } else {
                      localStorage.setItem('userRole', loginResult.role);
                      localStorage.setItem('userId', loginResult.userId);
                      localStorage.setItem('tag', loginResult.tag);
                      localStorage.setItem('isAuthorized', 'true');
                      switch (loginResult.role) {
                        case 'admin': {
                          this.router.navigate(['/admin/schools']);
                          return;
                        }
                        case 'schooladmin': {
                          this.router.navigate(['/schooladmin/classes']);
                          return;
                        }
                        case 'parent': {
                          this.router.navigate(['/parent']);
                          return;
                        }
                        case 'pupil': {
                          this.router.navigate(['/pupil']);
                          return;
                        }
                      }
                    }
                  }
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
      return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
    }
  }
}
