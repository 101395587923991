<!-- <div class="tabs-content w-tab-content"> -->
<div *ngIf="loaded" class="professions-section">
  <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
    <ng-container *ngIf="!showFullResults">
      <h1 *ngIf="!isMobile" class="parent-child-h1 align-left">Предварительные результаты</h1>
      <div class="parent-child-text align-left">Мы получили ваши ответы. Ниже – результаты диагностики.</div>
      <section class="test-results-section">
        <prf-parent-results-shortened [sessionId]="sessionIDParent" (loaded)="resultsLoaded = $event"></prf-parent-results-shortened>
      </section>
    </ng-container>

    <ng-container *ngIf="showFullResults">
      <ng-container *ngIf="pupilSessionComplete">
        <h1 class="parent-child-h1 w-center">Результаты диагностики</h1>
        <div class="parent-child-text w-center">
          {{ 'TEST_AND_RESULTS.PARENT_TEXT' | translate }}
        </div>
      </ng-container>

      <!-- <section *ngIf="!pupilSessionComplete" class="child-registration-section">
        <div
          *ngIf="child"
          class="child-inv-container"
          [class]="{
            'child-notRegistered': childStatus === 'notRegistered',
            'child-registered': childStatus === 'registered'
          }"
        >
          <div class="child-inv-data" [class.full-width]="childStatus === 'registered'">
            <div class="child-inv-title">{{ childInviteTexts[childStatus].title }}</div>
            <div class="child-inv-text">{{ childInviteTexts[childStatus].text }}</div>
          </div>
          <div *ngIf="childStatus === 'notRegistered'" class="child-inv-button">
            <div class="button-share-container" [class.open]="openSocials">
              <button class="button-share" (click)="openSharing(); copyRegLink()">
                <span *ngIf="!openSocials">Пригласить ребенка</span>
                <span *ngIf="openSocials">Поделиться</span>
              </button>
              <div class="info-popup">
                <p>Ссылка скопирована</p>

                <img
                  src="/assets/images/icons/info-triangle.png"
                  width="12"
                  class="info-triangle"
                />
              </div>
              <a
                class="share-link share-link_wp"
                href="https://wa.me/send?text={{ generateRegLink() }}"
                target="_blank"
                ><span class="share-link__text_hidden">WhatsApp</span></a
              >
              <a
                class="share-link share-link_tg"
                href="https://t.me/share/url?url={{ generateRegLink() }}"
                target="_blank"
                ><span class="share-link__text_hidden">Telegram</span></a
              >
              <a
                class="share-link share-link_email"
                href="mailto:?body={{ generateRegLink() }}"
                target="_blank"
                ><span class="share-link__text_hidden">Email</span></a
              >
            </div>
          </div>
        </div>
      </section> -->

      <!-- <prf-parent-child-registration
        [child]="child"
        *ngIf="!childRegistered"
      ></prf-parent-child-registration> -->

      <section class="test-results-section">
        <!-- пока работает как заглушка начатого теста родителя -->
        <!-- <prf-parent-test-panel
          *ngIf="resultsLoaded"
          [parentSessionComplete]="parentSessionComplete"
          [sessionIDParent]="sessionIDParent"
          [class.no-display]="!resultsLoaded || parentSessionComplete"
          [pupilUserId]="child?.userId"
        >
        </prf-parent-test-panel> -->

        <prf-results
          [child]="child"
          [showSwitch]="true"
          [showResultMenu]="false"
          (loaded)="resultsLoaded = $event"
          (pupilSessionComplete)="pupilSessionComplete = $event"
          (parentSessionComplete)="parentSessionComplete = $event"
        >
        </prf-results>
      </section>
    </ng-container>

    <!-- banner -->
    <!-- пока скрыто! -->
    <div *ngIf="isProsvetIdUser && (pupilSessionComplete || parentSessionComplete) && false" class="banner-new">
      <div class="banner-wrapper">
        <div class="banner-content">
          <h3 class="banner-title">Сформируем вектор развития для вашего ребенка!</h3>
          <p class="banner-description">Проанализируем результаты тестирования вместе</p>
          <ul class="banner-list">
            <li class="banner-list__item">
              <span class="banner-list__item-icon"></span>
              <span>Определим интересы вашего ребенка</span>
            </li>
            <li class="banner-list__item">
              <span class="banner-list__item-icon"></span>
              <span>Заинтересуем и мотивируем на обучение</span>
            </li>
            <li class="banner-list__item">
              <span class="banner-list__item-icon"></span>
              <span>Расскажем о построении карьеры простым языком</span>
            </li>
          </ul>
          <a
            class="banner-button"
            href="https://career.profilum.ru/prosv_application/?utm_source=prosv&utm_medium=partnership"
            target="_blank"
          >
            Оставить заявку
          </a>
        </div>
        <div class="banner-footer">
          <p class="banner-footer__text">Научим делать осознанный выбор и поможет спроектировать будущее ребенка!</p>
        </div>
      </div>
    </div>
  </div>
</div>
