<div class="land-body">
  <div class="land-main-section">
    <div class="land-content">
      <div class="land-header-row-2">
        <a routerLink="/" class="land-header-logo-2 w-inline-block">
          <!--<img [ngClass]="routeAnimationsElements" src="/assets/images/landing/ProfLiner_Logo_1.svg" alt="" class="land-logo-2">-->
          <div class="profilum_logo">
            <img src="/assets/images/landing/logo.svg" alt="Profilum logo" />
          </div>
        </a>
        <div class="land-header-column">
          <a [ngClass]="routeAnimationsElements" routerLink="/login" class="land-header-button w-button">{{
            'SHARED.ENTRANCE' | translate
          }}</a>
        </div>
      </div>
      <div class="land-main-block">
        <div class="main-block">
          <h1 class="land-h1-2" [ngClass]="routeAnimationsElements">
            <span class="underline">Профнавигационная</span>
            <span class="underline">система</span>
          </h1>
          <p class="land-subtitle-h1-2" [ngClass]="routeAnimationsElements">
            Помогаем школьникам выбрать будущую профессию и спланировать свой путь к ней
          </p>
          <a [ngClass]="routeAnimationsElements" routerLink="/registration" class="button land-green-btn w-button">{{
            'SHARED.REGISTRATION' | translate
          }}</a>
          <a
            [ngClass]="routeAnimationsElements"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLScgRnyhooJElW7QV0IYqn7JUUC_Bpi4bLkSpvjobfomUjCEWQ/viewform"
            class="button land-green-btn btn-school w-button"
            >Зарегистрировать школу</a
          >
        </div>
        <img
          [ngClass]="routeAnimationsElements"
          src="/assets/images/landing/land-main-imagepng"
          width="699"
          srcset="/assets/images/landing/land-main-image-p-500.png 500w, /assets/images/landing/land-main-image.png 1398w"
          sizes="(max-width: 450px) 92vw, (max-width: 767px) 81vw, (max-width: 991px) 90vw, 699px"
          alt=""
          class="land-main-image"
        />
      </div>
    </div>
  </div>
  <div class="land-content">
    <div class="land-advantages-section">
      <div class="land-advantages-row">
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="/assets/images/landing/Idea_big.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Выявление талантов</h3>
          <p class="land-advantages-text">Доступ к современным инструментам диагностики и профориентации</p>
        </div>
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="/assets/images/landing/Chat.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Индивидуальные рекомендации</h3>
          <p class="land-advantages-text">
            Система поможет максимально эффективно использовать городскую инфраструктуру для знакомства с перспективными профессиями.
          </p>
        </div>
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="/assets/images/landing/Tie.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Поиск новых интересов</h3>
          <p class="land-advantages-text">Возможность найти новые увлечения и участвовать в мероприятиях города</p>
        </div>
      </div>
    </div>
    <div class="land-users-section">
      <h2 class="land-h2">Для кого этот сервис?</h2>
      <p class="land-subtitle-h2">
        Профнавигация – это командная работа. Мы создали профориентационный сервис, который помогает школьникам, родителям и учителям
        работать вместе.
      </p>
      <div class="land-user-tabs">
        <div class="land-user-tabs-menu" [ngClass]="routeAnimationsElements">
          <div class="land-user-tabs-menu-row">
            <a [ngClass]="{ 'w--current': swiperIndex == 0 }" (click)="toggleOverlayControls(0)" class="land-user-tab-link w-inline-block">
              <div class="land-user-tab-link-text">Ученикам и родителям</div>
            </a>
            <a
              [ngClass]="{ 'w--current': swiperIndex == 1 }"
              (click)="toggleOverlayControls(1)"
              class="land-user-tab-link w-inline-block w--current"
            >
              <div class="land-user-tab-link-text">Учителям и школам</div>
            </a>
            <!-- <a
              [ngClass]="{'w--current': swiperIndex == 2}"
              (click)="toggleOverlayControls(2)"
              class="land-user-tab-link w-inline-block"
            >
              <div class="land-user-tab-link-text">
                {{ 'LANDING_KZ.USERS_SECTION.NAVIGATION.ORGANIZATIONS' | translate }}м
              </div>
            </a> -->
          </div>
        </div>

        <swiper-container
          #swiperComponent
          prfSwiperElement
          init="false"
          [config]="swiperConfig"
          (indexChange)="swiperIndexChange($event)"
          class="professions-row"
        >
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img src="./assets/images/landing/swiper1.jpg" class="land-user-image" alt="" />
              </div>
              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./assets/images/landing/Test.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Диагностика</h4>
                    <p class="land-user-text">Выявление навыков, интересов и талантов ребенка</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/landing/Idea.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Рекомендации профессий</h4>
                    <p class="land-user-text">Каталог перспективные профессии с заданиями для погружения в профессию</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/landing/Free_work.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Образование</h4>
                    <p class="land-user-text">Возможности дополнительного образования, профильные мероприятия и курсы</p>
                  </div>
                </div>
              </div>
            </div>
            <a routerLink="/registration" class="button land-green-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>
          </swiper-slide>
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img class="land-user-image" src="./assets/images/landing/swiper2.jpg" alt="" />
              </div>
              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./assets/images/landing/Hat.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Актуальные знания по профнавигации</h4>
                    <p class="land-user-text">Информация о перспективных профессиях и потребностях городской экономики.</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/landing/Idea.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Профили талантов</h4>
                    <p class="land-user-text">
                      Аналитика по результатам диагностики. Формирование профиля талантов класса и школы, информация о внеклассной
                      активности учеников.
                    </p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/landing/Book.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Методическая поддержка</h4>
                    <p class="land-user-text">
                      Материалы для сопровождения профориентационной работы, индивидуальные рекомендации по работе с классом.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <a routerLink="/registration" class="button land-green-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="land-registration-section">
      <div class="container-970 w-container">
        <div class="land-reg-row">
          <div class="land-reg-column">
            <h2 class="land-h2 reg">Направляйте своего ребёнка</h2>
            <p class="land-subtitle-h2 reg">
              Узнайте сильные и слабые стороны вашего ребёнка и получите рекомендации по построению индивидуальной траектории.
            </p>
            <a routerLink="/registration" class="button land-green-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>
          </div>
          <img src="/assets/images/landing/reg-image.svg" alt="" class="land-reg-image" />
        </div>
      </div>
    </div>
    <footer class="land-footer">
      <div class="w-container footer-container">
        <div class="footer-sponsors-block">
          <div class="footer-information">
            <div>
              <div>
                <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.USED_PRIVACY_DATA' | translate
                }}</a>
              </div>
              <div>
                <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.AGREEMENT.USER_AGREEMENT' | translate
                }}</a>
              </div>
            </div>
            <div>
              <img src="/assets/images/dashboard/profilum-logo.svg" alt="Profilum logo" />
            </div>
            <!-- <a class="footer-button-school w-button" target="_blank"
                href="https://docs.google.com/presentation/d/1gAYPNf0ypIRcSLTvqsFuzFifSh-A5A4zhpHNX3s6QLw/edit"
                (click)="linkForSchools()">Презентация для школ</a> -->
          </div>
        </div>
        <div class="footer-contacts">
          <div class="land-footer-contacts">
            <a href="mailto:ask@profilum.ru" class="land-footer-link">ask&#64;profilum.ru</a><br />
            <div class="footer-contacts-small">поддержка</div>
          </div>
        </div>
      </div>
      <div class="footer-mobile">
        <div class="footer-mobile-down">
          <div class="footer-mobile-info">
            <div class="footer-mobile-contacts">
              <div>
                <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.USED_PRIVACY_DATA' | translate
                }}</a>
              </div>
              <div>
                <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.AGREEMENT.USER_AGREEMENT' | translate
                }}</a>
              </div>
            </div>
          </div>
          <div class="footer-mobile-mails">
            <a href="mailto:ask@profilum.ru" class="land-footer-link">ask&#64;profilum.ru</a><br />
            <div class="footer-contacts-small">поддержка</div>
            <div class="logo_footer profilum_logo">
              <img src="/assets/images/landing/logo.svg" alt="Profilum logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
<div class="sources lb" [ngClass]="{ show: showPopUp }">
  <div class="lb-bg"></div>
  <div class="lb-block sources-lb">
    <a (click)="showPopUp = !showPopUp" class="close-lb w-inline-block">
      <img src="/assets/images/landing/close-lb.svg" alt="" />
    </a>
    <h3 class="land-sources-heading">{{ 'SHARED.SHORT_LIST_OF_SOURCES' | translate }}</h3>
    <div class="sources-row">
      <div class="sources-column">
        <div class="sources-block">
          <div class="sources-num">1.</div>
          <div class="sources-block-col">
            <div class="sources-text">
              «Прогноз ежегодной дополнительной потребности в профессиональных кадрах по видам экономической деятельности» Центра занятости
              и социальной защиты населения города Москвы.
            </div>
          </div>
        </div>
        <div class="sources-block">
          <div class="sources-num">2.</div>
          <div class="sources-block-col">
            <div class="sources-text">
              «Перечень приоритетных профессий (специальностей) и направлений для организации профессионального обучения и дополнительного
              профессионального образования» и «Доклад о разработке прогноза потребности экономики города Москвы в кадрах с учетом опроса
              работодателей и крупных инвестиционных проектов в срок до 2025г.» Департамента труда и занятости Москвы.
            </div>
          </div>
        </div>
        <div class="sources-block">
          <div class="sources-num">3.</div>
          <div class="sources-block-col">
            <div class="sources-text">Государственные программы Москвы:</div>
            <ul class="sources-unordered-list">
              <li class="sources-list-item">«Культура Москвы».</li>
              <li class="sources-list-item">«Развитие транспортной системы».</li>
              <li class="sources-list-item">«Развитие коммунально-инженерной инфраструктуры и энергосбережение».</li>
              <li class="sources-list-item">«Москва - город для бизнеса и инноваций».</li>
              <li class="sources-list-item">«Столичное здравоохранение».</li>
              <li class="sources-list-item">«Столичное образование».</li>
              <li class="sources-list-item">«Информационный город».</li>
              <li class="sources-list-item">«Безопасный город».</li>
              <li class="sources-list-item">«Спорт Москвы».</li>
              <li class="sources-list-item">«Развитие городской среды».</li>
            </ul>
          </div>
        </div>
        <div class="sources-block">
          <div class="sources-num">4.</div>
          <div class="sources-block-col">
            <div class="sources-text">
              «Рейтинг топ-50 профессий среднего профессионального образования» Министерства образования и науки РФ.
            </div>
          </div>
        </div>
        <div class="sources-block">
          <div class="sources-num">5.</div>
          <div class="sources-block-col">
            <div class="sources-text">
              «Справочник востребованных на рынке труда, новых и перспективных профессий» Министерства труда и социальной защиты РФ.
            </div>
          </div>
        </div>
      </div>
      <div class="sources-column">
        <div class="sources-block">
          <div class="sources-num">6.</div>
          <div class="sources-block-col">
            <div class="sources-text">Проект «The future of work» Международной организации труда.</div>
          </div>
        </div>
        <div class="sources-block">
          <div class="sources-num">7.</div>
          <div class="sources-block-col">
            <div class="sources-text">Аналитические доклады:</div>
            <ul class="sources-unordered-list">
              <li class="sources-list-item">«Профессии будущей Москвы (2016)» Агентства инноваций Москвы.</li>
              <li class="sources-list-item">
                «Навыки будущего: что нужно знать и уметь в новом сложном мире» (2017) Global Education Futures и WorldSkills Russia.
              </li>
              <li class="sources-list-item">«Атлас новых профессий» (2016) Агентства стратегических инициатив.</li>
              <li class="sources-list-item">«The Future of Jobs» (2016 и 2018) Всемирного экономического форума</li>
              <li class="sources-list-item">
                «The future of work and skills» (2017) Организации экономического сотрудничества и развития.
              </li>
              <li class="sources-list-item">«Россия 2025: от кадров к талантам» (2017) BCG и Сбербанк.</li>
              <li class="sources-list-item">
                «Будущее рынка труда. Противоборство тенденций, которые будут формировать рабочую среду в 2030 году» (2018) PWC consulting.
              </li>
              <li class="sources-list-item">«Перемены в секторе революционных технологий» (2018) KPMG.</li>
              <li class="sources-list-item">
                «A future that works: automation, employment, and productivity» (2017) McKinsey and company.
              </li>
              <li class="sources-list-item">
                «Inception Report for the Global Commission on the Future of Work» (2017) Международной организации труда.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
