import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResultsService } from 'app/pages/results/results.service';
import { SharedModule } from 'app/shared/shared.module';
import { PageResultComponent } from './result-page/page-result.component';
import { ResultsProfessionPrestigeComponent } from './results-profession-prestige/results-profession-prestige.component';
import { ResultsSwitcherComponent } from './results-switcher/results-switcher.component';
import { DashboardModule } from 'app/shared/dashboard/dashboard.module';
import { PromotestResultsComponent } from './promotest-results/promotest-results.component';
import { CommonModule } from '@angular/common';
import { MotivationResultsPageComponent } from './motivation-results-page/motivation-results-page.component';
import { ValuesResultsPageComponent } from './values-results-page/values-results-page.component';
import { CareerResultsComponent } from './career-results/career-results.component';
import { PupilResultsShortenedComponent } from './result-page/pupil-results-shortened/pupil-results-shortened.component';
import { ValuesShortResultsComponent } from './values-results-page/values-short-results/values-short-results.component';
import { MotivationShortResultsComponent } from './motivation-results-page/motivation-short-results/motivation-short-results.component';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import { SupportBlockComponent } from '../../shared/common-components/support-block/support-block.component';
import { SpiderChartCustomComponent } from '@profilum-components/spider-chart-custom/spider-chart-custom.component';

@NgModule({
  declarations: [
    ResultsSwitcherComponent,
    ResultsProfessionPrestigeComponent,
    PageResultComponent,
    PromotestResultsComponent,
    MotivationResultsPageComponent,
    ValuesResultsPageComponent,
    CareerResultsComponent,
    PupilResultsShortenedComponent,
    ValuesShortResultsComponent,
    MotivationShortResultsComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, DashboardModule, SwiperDirective, SupportBlockComponent, SpiderChartCustomComponent],
  providers: [ResultsService, provideCharts(withDefaultRegisterables())],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResultsModule {}
