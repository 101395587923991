<prf-content-loader *ngIf="!pageLoader && !resultsFetched"></prf-content-loader>

<div *ngIf="resultsFetched" class="results-section">
  <ng-container *ngIf="currentSessionId; else testNotPassed">
    <ng-container *ngIf="!nullResults; else emptyResults">
      <div *ngIf="resultsFetched" class="result-container">
        <h1 class="test-result-h1">{{ 'VALUES.MAIN_TITLE' | translate }}</h1>
        <p class="test-results-text">{{ 'VALUES.MAIN_DESC' | translate }}</p>
        <div class="test-results-section main-categories-chart-section">
          <div *ngIf="!isMobile" class="chart-container">
            <canvas
              #chartCanvas
              baseChart
              [type]="chartData.type"
              [datasets]="chartData.data.datasets"
              [labels]="chartData.data.labels"
              [options]="chartData.options"
            ></canvas>
          </div>
          <div *ngIf="isMobile" class="chart-container chart-container_flex">
            <div *ngFor="let result of resultsArray; let i = index" class="chart-container__item">
              <div class="chart-item__label">
                <span class="chart-item__label-num">{{ i + 1 }}.</span>{{ result.name }}
              </div>
              <div class="chart-item__bar">
                <div class="chart-bar__scale" [style.backgroundColor]="result.backgroundColor" [style.width]="result.value + '%'">
                  <span *ngIf="result.value >= 10">{{ result.value }}%</span>
                </div>
                <div *ngIf="result.value < 10" class="chart-bar__scale_hidden">
                  <span>{{ result.value }}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!mobileOpened" class="test-results-section values-section">
          <h1 class="test-result-h1">{{ 'VALUES.FULL_REPORT' | translate }}</h1>
          <div class="high-values-section values-section-item" *ngIf="highValuesResults.length">
            <div class="value-level-tab">
              <div class="value-level-text">{{ 'VALUES.LAVEL.HIGH' | translate }}</div>
              <div class="value-level-data">
                {{ hightLevelRangeString }}
              </div>
            </div>
            <div class="value-cards-container">
              <div
                *ngFor="let item of highValuesResults; let i = index"
                [class.inactive]="item.descriptionOpened === false"
                class="value-card"
              >
                <a class="card-container" #valueCard (click)="openDescription(highValuesResults, i, valueCard, $event)">
                  <div class="card-background" [style.background-position-y.px]="170 - 15 - (170 * item.value) / 100">
                    <div class="card-value">
                      {{ item.value + '%' }}
                    </div>
                    <div class="card-name">{{ item.name }}</div>
                  </div>
                  <div class="card-main">
                    <div class="card-description">
                      {{ item.shortDescription | translate }}
                    </div>
                    <button *ngIf="!item.descriptionOpened" class="card-link">Подробнее</button>
                    <button *ngIf="item.descriptionOpened" class="card-link" #closeDescr>Свернуть</button>
                  </div>
                </a>
                <div
                  class="card-desription-container"
                  [class.display]="highValuesResults[i] && highValuesResults[i].descriptionOpened"
                  [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 60 + 'px' : null"
                  [style.margin-left]="
                    highValuesResults[i] && highValuesResults[i].descriptionOpened ? '-' + (checkLeftOffset(valueCard) + 30) + 'px' : null
                  "
                >
                  <div
                    *ngIf="!isMobile"
                    class="card-description-wrapper"
                    [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 'px' : null"
                  >
                    <div class="card-description">
                      <div class="description-container">
                        <div class="decsription-tabs">
                          <div class="description-tab-container">
                            <div class="description-tab about-tab">
                              <div class="description-card-title">{{ 'VALUES.ABOUT_VALUE' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.aboutText | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="description-tab-container">
                            <div class="description-tab aboutMyself-tab">
                              <div class="description-card-title">{{ 'VALUES.ABOUT_YOURSELF' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.aboutMyselfText | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="description-tab-container">
                            <div class="description-tab payAttention-tab">
                              <div class="description-card-title">{{ 'VALUES.PAY_ATTENTION' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.payAttentionText | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="description-advice-tab">
                          <div class="description-advice-title">{{ 'VALUES.PRACTICAL' | translate }}</div>
                          <div class="description-advice-text" [innerHTML]="item.advice | translate"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="middle-values-section values-section-item" *ngIf="middleValuesResults.length">
            <div class="value-level-tab">
              <div class="value-level-text">{{ 'VALUES.LAVEL.MEDIUM' | translate }}</div>
              <div class="value-level-data">
                {{ middleLevelRangeString }}
              </div>
            </div>
            <div class="value-cards-container">
              <div
                *ngFor="let item of middleValuesResults; let j = index"
                class="value-card"
                [class.inactive]="item.descriptionOpened === false"
              >
                <a class="card-container" #valueCard (click)="openDescription(middleValuesResults, j, valueCard, $event)">
                  <div class="card-background" [style.background-position-y.px]="170 - 15 - (170 * item.value) / 100">
                    <div class="card-value">
                      {{ item.value + '%' }}
                    </div>
                    <div class="card-name">{{ item.name }}</div>
                  </div>
                  <div class="card-main">
                    <div class="card-description">
                      {{ item.shortDescription | translate }}
                    </div>
                    <button *ngIf="!item.descriptionOpened" class="card-link">Подробнее</button>
                    <button *ngIf="item.descriptionOpened" class="card-link" #closeDescr>Свернуть</button>
                  </div>
                </a>
                <div
                  class="card-desription-container"
                  [class.display]="middleValuesResults[j] && middleValuesResults[j].descriptionOpened"
                  [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 60 + 'px' : null"
                  [style.margin-left]="
                    middleValuesResults[j] && middleValuesResults[j].descriptionOpened
                      ? '-' + (checkLeftOffset(valueCard) + 30) + 'px'
                      : null
                  "
                >
                  <div
                    *ngIf="!isMobile"
                    class="card-description-wrapper"
                    [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 'px' : null"
                  >
                    <div class="card-description">
                      <div class="description-container">
                        <div class="decsription-tabs">
                          <div class="description-tab-container">
                            <div class="description-tab about-tab">
                              <div class="description-card-title">{{ 'VALUES.ABOUT_VALUE' | translate }}?</div>
                              <div class="description-card-text">
                                {{ item.aboutText | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="description-tab-container">
                            <div class="description-tab aboutMyself-tab">
                              <div class="description-card-title">{{ 'VALUES.ABOUT_YOURSELF' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.aboutMyselfText | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="description-tab-container">
                            <div class="description-tab payAttention-tab">
                              <div class="description-card-title">{{ 'VALUES.PAY_ATTENTION' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.payAttentionText | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="description-advice-tab">
                          <div class="description-advice-title">{{ 'VALUES.PRACTICAL' | translate }}</div>
                          <div class="description-advice-text" [innerHTML]="item.advice | translate"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="low-values-section values-section-item" *ngIf="lowValuesResults.length">
            <div class="value-level-tab">
              <div class="value-level-text">{{ 'VALUES.LAVEL.LOW' | translate }}</div>
              <div class="value-level-data">
                {{ lowLevelRangeString }}
              </div>
            </div>
            <div class="value-cards-container">
              <div
                *ngFor="let item of lowValuesResults; let k = index"
                [class.inactive]="item.descriptionOpened === false"
                class="value-card"
              >
                <a class="card-container" #valueCard (click)="openDescription(lowValuesResults, k, valueCard, $event)">
                  <div class="card-background" [style.background-position-y.px]="170 - 15 - (170 * item.value) / 100">
                    <div class="card-value">
                      {{ item.value + '%' }}
                    </div>
                    <div class="card-name">{{ item.name }}</div>
                  </div>
                  <div class="card-main">
                    <div class="card-description">
                      {{ item.shortDescription | translate }}
                    </div>
                    <button *ngIf="!item.descriptionOpened" class="card-link">Подробнее</button>
                    <button *ngIf="item.descriptionOpened" class="card-link" #closeDescr>Свернуть</button>
                  </div>
                </a>
                <div
                  class="card-desription-container"
                  [class.display]="lowValuesResults[k] && lowValuesResults[k].descriptionOpened"
                  [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 60 + 'px' : null"
                  [style.margin-left]="
                    lowValuesResults[k] && lowValuesResults[k].descriptionOpened ? '-' + (checkLeftOffset(valueCard) + 30) + 'px' : null
                  "
                >
                  <div
                    *ngIf="!isMobile"
                    class="card-description-wrapper"
                    [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 'px' : null"
                  >
                    <div class="card-description">
                      <div class="description-container">
                        <div class="decsription-tabs">
                          <div class="description-tab-container">
                            <div class="description-tab about-tab">
                              <div class="description-card-title">{{ 'VALUES.ABOUT_VALUE' | translate }}?</div>
                              <div class="description-card-text">
                                {{ item.aboutText | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="description-tab-container">
                            <div class="description-tab aboutMyself-tab">
                              <div class="description-card-title">{{ 'VALUES.ABOUT_YOURSELF' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.aboutMyselfText | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="description-tab-container">
                            <div class="description-tab payAttention-tab">
                              <div class="description-card-title">{{ 'VALUES.PAY_ATTENTION' | translate }}</div>
                              <div class="description-card-text">
                                {{ item.payAttentionText | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="description-advice-tab">
                          <div class="description-advice-title">{{ 'VALUES.PRACTICAL' | translate }}</div>
                          <div class="description-advice-text" [innerHTML]="item.advice | translate"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="mobile-overlay" [ngClass]="{ opened: mobileOpened }" *ngIf="mobileOpened">
  <div class="motivation-swiper-wrapper">
    <a class="close" (click)="closeMobile()">
      <img src="/assets/images/results/motivation-results/close-cross.svg" alt="" />
    </a>
    <div>
      <div
        class="motivation-bar-mobile value-bar-mobile"
        [style.backgroundColor]="mobileDataColor"
        [style.background-position-y.px]="145 - (145 * mobileData['value']) / 100"
      >
        <div class="tab-item-value tab-item-value__value-test">{{ mobileData.value }}%</div>
        <div class="tab-item-name tab-item-name__value-test">
          {{ mobileData['name'] }}
        </div>
      </div>
      <div class="motiv-section-header motiv-section-header_vt">
        <p class="motiv-section-text motiv-section-text_value-test">
          {{ mobileData['shortDescription'] | translate }}
        </p>
      </div>
      <div class="motiv-section-recommendations">
        <div class="card-description-wrapper">
          <div class="card-description">
            <div class="description-container">
              <div class="decsription-tabs">
                <div class="description-tab-container" style="background-color: #9375fb">
                  <div class="description-tab about-tab">
                    <div class="description-card-title">{{ 'VALUES.ABOUT_VALUE' | translate }}?</div>
                    <div class="description-card-text">
                      {{ mobileData['aboutText'] | translate }}
                    </div>
                  </div>
                </div>
                <div class="description-tab-container" style="background-color: #496df6">
                  <div class="description-tab aboutMyself-tab">
                    <div class="description-card-title">{{ 'VALUES.ABOUT_YOURSELF' | translate }}</div>
                    <div class="description-card-text">
                      {{ mobileData['aboutMyselfText'] | translate }}
                    </div>
                  </div>
                </div>
                <div class="description-tab-container" style="background-color: #e48d6b">
                  <div class="description-tab payAttention-tab">
                    <div class="description-card-title">{{ 'VALUES.PAY_ATTENTION' | translate }}</div>
                    <div class="description-card-text">
                      {{ mobileData['payAttentionText'] | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="description-advice-tab">
                <div class="description-advice-title">{{ 'VALUES.PRACTICAL' | translate }}</div>
                <div class="description-advice-text" [innerHTML]="mobileData['advice'] | translate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyResults>
  <div class="results-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>
<ng-template #testNotPassed>
  <div class="results-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Ребенок не прошел тест</div>
  </div>
</ng-template>
