import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { ISortedItem } from 'app/shared/elements/dropdown-sorting/dropdown-sorting.component';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { of, throwError, BehaviorSubject, Observable, TimeoutError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IRecommendedProfessionsResponse } from './welcome-choise-profession/recommended-professions/model';

export const ColorMap = {
  'Административная работа': 1,
  'Продажи и торговое дело': 1,
  Управление: 1,
  'Экономика, бизнес и финансы': 1,
  'Инженерия, архитектура и проектирование': 6,
  'Естественные науки': 3,
  'Медицина и здравоохранение': 3,
  'Сельское и лесное хозяйство, рыболовство': 3,
  'Образование и педагогика, управление знаниями': 7,
  'Реклама, PR, развлечения и СМИ': 7,
  'Социальные и гуманитарные науки': 7,
  Юриспруденция: 7,
  'Производство и технологии': 2,
  'Строительные и технические работы, добыча': 2,
  'Транспорт и перевозки': 2,
  'Установка, обслуживание и ремонт': 2,
  'Информационные технологии и математика': 5,
  'Безопасность и обеспечение порядка': 4,
  'Военное дело': 4,
  Спорт: 4,
  'Искусство, творчество, дизайн': 8,
  'Сервис, питание и услуги': 1,
};
@Injectable()
export class ProfessionsService {
  private currentProfession = new BehaviorSubject<IProfession>(null);
  private sortingProfession$ = new BehaviorSubject<ISortedItem>(null);

  constructor(private http: HttpService) {}

  public acquireProfessions(filters, size?: number, from?: number) {
    const url = '/api/v1.0/catalog/professions/elasticsearch/search/filters';
    const credentials: IProfessionFilter = {};

    credentials.isVisible = true;

    enum DemandRatingValues {
      NotRated,
      Low,
      Medium,
      High,
    }

    for (const query in filters) {
      if (filters[query]) {
        credentials[query[0].toLowerCase() + query.slice(1)] = filters[query];
      }
    }

    credentials.from = from;
    credentials.size = size;

    if (filters && filters.Fields) {
      credentials.fields = [filters.Fields];
    }

    return this.http.post(url, credentials).pipe(map(r => r));
  }

  public getProfessionsQuery(query: any, regionId?: string): Observable<any> {
    return this.http.post('/api/v1.0/catalog/professions/search/query', { query, regionId }).pipe(map(response => response.professions));
  }

  public getFields() {
    const url = '/api/v1.0/catalog/fields/all';
    return this.http.get(url).pipe(map(r => r.fields));
  }

  public getProfessionByHRID(hrid) {
    const url = '/api/v1.0/catalog/professions/mongo/onebyhrid?hrid=' + hrid;
    return this.http.get(url).pipe(map(r => r.profession));
  }

  public getColorSchemeNumber(fieldName) {
    return ColorMap[fieldName] || 1;
  }

  public getResults(sessionId: string): Observable<any> {
    return this.http.get('/api/v1.0/results/page?sessionIds=' + sessionId).pipe(take(1));
  }

  addUserRecommendation(userId: string, productId: string, recommendationType: string, addresserRole: string): Observable<any> {
    const params = `?UserId=${userId}&ProductId=${productId}&RecommendationType=${recommendationType}&AddresserRole=${addresserRole}`;
    return this.http.post('/b2c/v1.0/parents/adduserrecommendation' + params).pipe(take(1));
  }

  addUserRecommendationByParent(userId: string, productId: string, recommendationType: string, addresserRole: string): Observable<any> {
    const params = `?UserId=${userId}&ProductId=${productId}&RecommendationType=${recommendationType}&AddresserRole=${addresserRole}`;
    return this.http.post('/b2c/v1.0/parents/adduserrecommendation' + params).pipe(take(1));
  }

  addUserRecommendationByTeacher(userId: string, productId: string, recommendationType: string, addresserRole: string): Observable<any> {
    const params = `?UserId=${userId}&ProductId=${productId}&RecommendationType=${recommendationType}&AddresserRole=${addresserRole}`;
    return this.http.post('/b2c/v1.0/teachers/adduserrecommendation' + params).pipe(take(1));
  }

  getUserIdsWithRecommendation(productId: string, recommendationType: string): Observable<any> {
    const params = `?productId=${productId}&recommendationType=${recommendationType}`;
    return this.http.get('/b2c/v1.0/parents/getuseridswithrecommendation' + params).pipe(take(1));
  }

  getUserIdsWithRecommendationByTeacher(productId: string, recommendationType: string): Observable<any> {
    const params = `?productId=${productId}&recommendationType=${recommendationType}`;
    return this.http.get('/b2c/v1.0/teachers/getuseridswithrecommendation' + params).pipe(take(1));
  }

  getUserIdsWithRecommendationByParent(productId: string, recommendationType: string): Observable<any> {
    const params = `?productId=${productId}&recommendationType=${recommendationType}`;
    return this.http.get('/b2c/v1.0/parents/getuseridswithrecommendation' + params).pipe(take(1));
  }

  public getProfessionsByIds(ids: string[]): Observable<any> {
    const url = '/api/v1.0/catalog/professions/elasticsearch/search/filters';
    const credentials = { ids: [], isVisible: true };

    if (ids.length) {
      credentials.ids = [];
      ids.forEach(id => {
        credentials.ids.push(id);
      });
    } else {
      return of([]);
    }
    return this.http.post(url, credentials).pipe(take(1));
  }

  public getUserRecommendations(recommendationType: string, addresserRole: string): Observable<any> {
    return this.http
      .get(`/b2c/v1.0/saas/getuserrecommendations?recommendationType=${recommendationType}&addresserRole=${addresserRole}`)
      .pipe(take(1));
  }

  setCurrentProfession(job: IProfession) {
    this.currentProfession.next(job);
  }

  public getCurrentProfession(): Observable<IProfession> {
    return this.currentProfession.asObservable();
  }

  setSortingProfession(name) {
    this.sortingProfession$.next(name);
  }

  public getSortingProfession(): Observable<ISortedItem> {
    return this.sortingProfession$.asObservable();
  }

  public getRecommendedProfessions(sessionId: string): Observable<IRecommendedProfessionsResponse> {
    return this.http.post('/b2c/v1.0/saas/calculateprofessions', { sessionId }).pipe(take(1));
  }
}
