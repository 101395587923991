import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-pupil-talant-card',
  templateUrl: './pupil-talant-card.component.html',
  styleUrls: ['./pupil-talant-card.component.scss'],
})
export class PupilTalantCardComponent implements OnInit {
  @Input() talant: any;
  @Input() cardIndex: number;

  constructor() {}

  ngOnInit(): void {
    this.setCardData(this.talant);
  }

  setCardData(talant) {
    if (talant?.name) {
      switch (talant.name) {
        case 'Исследователь':
          talant['shortDescription'] = 'TALANTS_DESC.RESEARCHER';
          talant.background = 'rgb(2 197 188 / 65%)';
          break;
        case 'Лидер':
          talant['shortDescription'] = 'TALANTS_DESC.LEDAER';
          talant.background = 'rgb(237 30 121 / 65%)';
          break;
        case 'Коммуникатор':
          talant['shortDescription'] = 'TALANTS_DESC.COMMUNICATOR';
          talant.background = 'rgb(240 95 76 / 65%)';
          break;
        case 'Креатор':
          talant['shortDescription'] = 'TALANTS_DESC.CREATOR';
          talant.background = 'rgb(58 129 237 / 65%)';
          break;
        case 'Мастер':
          talant['shortDescription'] = 'TALANTS_DESC.MASTER';
          talant.background = 'rgb(12 171 114 / 65%)';
          break;
        case 'Инженер':
          talant['shortDescription'] = 'TALANTS_DESC.ENGINEER';
          talant.background = 'rgb(255 190 24 / 65%)';
          break;
        case 'Аналитик':
          talant['shortDescription'] = 'TALANTS_DESC.ANALYTIC';
          talant.background = 'rgb(188 94 27 / 65%)';
          break;
      }
    }
  }
}
