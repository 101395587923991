<div *ngIf="menuAllowed" class="navbar">
  <!-- mosru пользователи -->
  <div *ngIf="menuAllowed" class="menu" style="overflow-y: auto">
    <a
      *ngIf="showMosruAbout && !isVGuser"
      (click)="selectPromoAction()"
      [class.w--current]="currentUrl === '/mosruabout'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/menu/redHat_grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/menu/redHat.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div>Узнайте <br />свои таланты</div>

      <div class="menu-separator"></div>
    </a>
    <!-- VG пользователи -->
    <a
      *ngIf="isVGuser"
      routerLink="/vorobievi-gori/mosrupromo"
      [class.w--current]="currentUrl === '/mosruabout'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Test-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Test-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div>
        Тест<br />
        на таланты
      </div>
      <div class="menu-separator"></div>
    </a>

    <!-- ученик, defaultB2cParents результаты -->
    <a
      *ngIf="userInfo.role === 'pupil' || defaultB2cParents"
      class="menu-link-block w-inline-block"
      [class.w--current]="
        router.url.includes('/parent') ||
        router.url.includes('/results')"
      (click)="selectResults()"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Test-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Test-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Результаты тестов</div>
      <div class="menu-separator"></div>
    </a>

    <!-- ученик, defaultB2cParents рекомендации -->
    <a
      *ngIf="userInfo.role === 'pupil' || defaultB2cParents"
      (click)="selectRecommendations()"
      [class.w--current]="currentUrl === '/recommendations'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Test-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Test-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Рекомендации</div>
      <div class="menu-separator"></div>
    </a>

    <a
      *ngIf="userInfo.role === 'pupil'"
      (click)="selectProfessions()"
      [class.w--current]="currentUrl === '/professions'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="./assets/images/dashboard/Professions-Grey.svg" alt="" class="menu-img" />
        <img src="./assets/images/dashboard/Professions-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">{{ 'HEADER.PROF' | translate }}</div>
      <div class="menu-separator"></div>
    </a>
    <a
      *ngIf="showInternships && isInternshipsReady"
      (click)="selectInternships()"
      [class.w--current]="currentUrl === '/internships'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Course_Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Course_VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Стажировки</div>
      <div class="menu-separator"></div>
    </a>
  </div>
  <div class="mobile-menu-button" (click)="showMobileMenu()">
    <div class="mobile-menu-icon-div">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
  </div>

  <div style="display: none" class="mobile-menu-button fixed">
    <div class="mobile-menu-icon-div">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
  </div>
</div>

<!-- мобильное меню -->
<div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
  <div
    [ngStyle]="{
      transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
    }"
    class="mobile-menu-container"
    style="transform-style: preserve-3d"
  >
    <div class="mobile-menu-header">
      <div class="mobile-menu-button fixed" (click)="hideMobileMenu()">
        <div class="mobile-menu-icon-div">
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
        </div>
      </div>
    </div>
    <div class="mobile-user-div">
      <a (click)="selectProfile()" class="user-dropdown mob w-inline-block">
        <div class="user-dropdown-toggle">
          <div class="user-photo mob" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
          <div class="user-name mob">{{ firstName }}</div>
        </div>
      </a>
    </div>
    <div *ngIf="menuAllowed">
      <ng-container *ngIf="showHeaderButtons">
        <div class="header_buttons">
          <a
            class="header_button _button"
            [class.active]="url.includes(item.url)"
            *ngFor="let item of headerButtonsArray"
            (click)="hideMobileMenu()"
            routerLink="{{ item.url }}"
            >{{ item.name }}</a
          >
        </div>
      </ng-container>
      <!-- mosru пользователи -->
      <a
        *ngIf="showMosruAbout && !isVGuser"
        (click)="selectPromoAction()"
        [class.w--current]="currentUrl === '/mosruabout'"
        class="menu-link mosruabout w-inline-block"
      >
        <div>Узнайте <br />свои таланты</div>
      </a>
      <!-- VG пользователи -->
      <a
        *ngIf="isVGuser"
        routerLink="/vorobievi-gori/mosrupromo"
        [class.w--current]="currentUrl === '/mosruabout'"
        class="menu-link mosruabout w-inline-block"
      >
        <div>
          Тест<br />
          на таланты
        </div>
      </a>
      <a
        *ngIf="userInfo.role === 'pupil' || defaultB2cParents"
        class="menu-link-block test w-inline-block"
        [class.w--current]="
          router.url.includes('/parent') ||
          router.url.includes('/results') ||
          router.url.includes('/motivation-test-results') ||
          router.url.includes('/values-test-results')
        "
        [matMenuTriggerFor]="resultsMenu"
        (menuOpened)="setMenuProperties()"
      >
        <div class="menu-separator"></div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Результаты тестов</div>
        <div class="menu-separator"></div>
      </a>

      <mat-menu #resultsMenu="matMenu" xPosition="after">
        <ng-container *ngFor="let testItem of userTestsArray" class="menu-item-wrapper">
          <button mat-menu-item *ngIf="testItem.testAccess" (click)="selectResults(testItem.screeningTestId)">
            {{ testItem.testName }}
          </button>
        </ng-container>
      </mat-menu>

      <!-- ученик, defaultB2cParents рекомендации -->
      <a
        *ngIf="userInfo.role === 'pupil' || defaultB2cParents"
        (click)="selectRecommendations()"
        [class.w--current]="currentUrl === '/recommendations'"
        class="menu-link w-inline-block"
      >
        <div class="menu-link-text">Рекомендации</div>
      </a>

      <a
        *ngIf="userInfo.role === 'pupil'"
        (click)="selectProfessions()"
        [class.w--current]="currentUrl === '/professions'"
        class="menu-link professions w-inline-block"
      >
        <div>{{ 'HEADER.PROF' | translate }}</div>
      </a>
    </div>
  </div>
  <div class="mobile-menu-bg" (click)="hideMobileMenu()"></div>
</div>
