import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
})
export class RadarChartComponent implements OnInit {
  @Input() data;
  @Input() isParent;
  // Radar
  // public radarChartLabels: string[] = ['Конструирование и математика', 'Творческие способности', 'Решение проблем', 'Физические навыки', 'Навыки общения', 'Навыки работы с информацией'];
  public radarChartLabels: string[] = [];
  public baseSkills: any = {
    'Конструирование и математика': 0,
    'Творческие способности': 0,
    'Решение проблем': 0,
    'Физические навыки': 0,
    'Навыки общения': 0,
    'Навыки работы с информацией': 0,
  };
  public radarChartColors: any[] = [
    {
      backgroundColor: 'rgba(128, 230, 155, 0.75)',
      borderColor: 'rgba(128, 230, 155, 0.85)',
    },
  ];
  public radarChartData: any = [];
  public radarChartType: string = 'radar';
  public radarChartOptions: any = {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: tooltipItem => {
          return tooltipItem.yLabel;
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scale: {
      ticks: {
        min: 0,
        max: 100,
        stepSize: 25,
        display: false,
        maxTicksLimit: 3,
      },
      pointLabels: {
        fontSize: 16,
        fontColor: '#444',
        fontFamily: '"Nunito Sans", Arial',
      },
      tooltips: {
        enabled: false,
      },
      angleLines: {
        // color: 'grey' // lines radiating from the center
      },
      gridLines: {
        // color: 'grey'
      },
    },
  };

  constructor() {}

  ngOnInit() {
    let array: any = [];
    if (this.data[0] != undefined) {
      this.data[0].forEach(item => {
        let valueFormat = item.value * 100;
        this.baseSkills[item.axis] = valueFormat;
        this.radarChartLabels.push(item.axis + ' ' + Math.round(valueFormat));
        array.push(this.baseSkills[item.axis]);
      });
    }
    this.radarChartData.push({
      data: array,
      label: 'Average Skill Results',
    });
  }
}
