import { Injectable } from '@angular/core';

@Injectable()
export class D3ToolsService {
  constructor() {}

  // tools

  getRangeConfig() {
    return [
      {
        id: 'redGradient',
        left: '#c03e48',
        right: '#bd4d4d',
        direction: 0,
      },
      {
        id: 'redOrandgeGradient',
        left: '#bd4d4d',
        right: '#e0895a',
        direction: 0,
      },
      {
        id: 'orangeGradient',
        left: '#e0895a',
        right: '#e1ab4a',
        direction: 0,
      },
      {
        id: 'yellowGradient',
        left: '#e1ab4a',
        right: '#ccd23d',
        direction: 0,
      },
      {
        id: 'greenGradient',
        left: '#ccd23d',
        right: '#acfa29',
        direction: 0,
      },
    ];
  }

  createGradient(id, config, defs) {
    const calculatedAngle = this.calculateGradient(config.direction);

    const mainGradient = defs
      .append('linearGradient')
      .attr('id', id)
      .attr('x1', calculatedAngle.x1)
      .attr('y1', calculatedAngle.y1)
      .attr('x2', calculatedAngle.x2)
      .attr('y2', calculatedAngle.y2);

    mainGradient.append('stop').style('stop-color', config.left).attr('offset', 0);

    mainGradient.append('stop').style('stop-color', config.right).attr('offset', 1);
  }

  calculateGradient(direction) {
    const defaultDirection = {
      left: {
        x1: '100%',
        y1: '0%',
        x2: '0%',
        y2: '0%',
      },
      right: {
        x1: '0%',
        y1: '0%',
        x2: '100%',
        y2: '0%',
      },
      down: {
        x1: '0%',
        y1: '0%',
        x2: '0%',
        y2: '100%',
      },
      up: {
        x1: '0%',
        y1: '100%',
        x2: '0%',
        y2: '0%',
      },
    };

    if (typeof direction === 'string') {
      return defaultDirection[direction];
    }

    const pointOfAngle = a => ({
      x: Math.cos(a),
      y: Math.sin(a),
    });

    const degreesToRadians = d => (d * Math.PI) / 180;

    const eps = Math.pow(2, -52);
    const angle = direction % 360;
    const startPoint = pointOfAngle(degreesToRadians(180 - angle));
    const endPoint = pointOfAngle(degreesToRadians(360 - angle));

    if (startPoint.x <= 0 || Math.abs(startPoint.x) <= eps) {
      startPoint.x = 0;
    }

    if (startPoint.y <= 0 || Math.abs(startPoint.y) <= eps) {
      startPoint.y = 0;
    }

    if (endPoint.x <= 0 || Math.abs(endPoint.x) <= eps) {
      endPoint.x = 0;
    }

    if (endPoint.y <= 0 || Math.abs(endPoint.y) <= eps) {
      endPoint.y = 0;
    }
    return {
      x1: startPoint.x,
      y1: startPoint.y,
      x2: endPoint.x,
      y2: endPoint.y,
    };
  }
}
