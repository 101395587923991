import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { NoAutocompleteDirective } from './directives/no-autocomplete.directive';
import { CustomCheckBoxComponent } from './elements/custom-check-box/custom-check-box.component';
import { GenderToggleComponent } from './elements/gender-toggle/gender-toggle.component';
import { MaterialModule } from './modules/material.module';
import { OverlayBusyComponent } from './overlay-busy/overlay-busy.component';
import { DropdownComponent } from './elements/dropdown/dropdown.component';
import { TopListComponent } from './top-list/top-list.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedBaseModule } from './shared-base.module';
import { DropdownWithSearchComponent } from './elements/dropdown-with-search/dropdown-with-search.component';
import { BannerComponent } from './elements/banner/banner.component';
import { RouterModule } from '@angular/router';
import { DropdownSortingComponent } from './elements/dropdown-sorting/dropdown-sorting.component';
import { UserAccessService } from './services/useraccess.service';
import { LoaderButtonComponent } from './loader-button/loader-button.component';

@NgModule({
  imports: [SharedBaseModule, DashboardModule, TranslateModule, MaterialModule, RouterModule],
  declarations: [
    NoAutocompleteDirective,

    OverlayBusyComponent,
    GenderToggleComponent,
    CustomCheckBoxComponent,
    DropdownComponent,
    TopListComponent,
    DropdownWithSearchComponent,
    BannerComponent,
    DropdownSortingComponent,
    LoaderButtonComponent,
  ],
  exports: [
    //т.к. в shared есть другие подмодули, в которых приходится подключать тот же список основных модулей,
    //вынесла их в отдельный модуль SharedBaseModule. Получается зависимость SharedBaseModule <- SharedModule <- PagesModule
    SharedBaseModule,
    DashboardModule,
    NoAutocompleteDirective,
    OverlayBusyComponent,
    GenderToggleComponent,
    CustomCheckBoxComponent,
    DropdownComponent,
    TopListComponent,
    DropdownWithSearchComponent,
    BannerComponent,
    DropdownSortingComponent,
    LoaderButtonComponent,
  ],
  providers: [OverlayBusyService, NoAutocompleteDirective, UserAccessService],
})
export class SharedModule {}
