<section class="parent">
  <prf-menu [menuAllowed]="menuAllowed" [setUserProfile]="userProfile" [root]="'parent'"></prf-menu>
  <div class="content-section">
    <prf-page-header [title]="pageTitle"></prf-page-header>
    <div class="container" *ngIf="dataLoaded">
      <!-- свитчер между детьми -->
      <!-- <prf-children-tabs
          *ngIf="dataLoaded"
          [children]="userProfile.children"
          (selectChild)="selectedChild = $event"
          (addChild)="isShowAddChild = $event"
        >
        </prf-children-tabs> -->

      <prf-parent-child-container
        *ngIf="dataLoaded"
        [child]="selectedChild"
        [sessionIDParent]="sessionIDParent"
        [favoriteProfessions]="favoriteProfessions"
        (pageTitle)="setTitle($event)"
      >
      </prf-parent-child-container>
      <prf-support-block />
    </div>
  </div>

  <prf-children-add
    [open]="isShowAddChild"
    (binding)="bindingChild($event)"
    (closed)="isShowAddChild = !$event"
    (addedChild)="loadProfile()"
    (showRegWindow)="isShowChildReg = $event"
  >
  </prf-children-add>

  <prf-child-registration *ngIf="isShowChildReg" (updateParent)="bindingChild($event)" (closePage)="isShowChildReg = !$event">
  </prf-child-registration>

  <prf-static-task-modal></prf-static-task-modal>
</section>
