<div class="login-section" [class.with-scroll]="parentRegStep !== EParentRegSteps.SUBMIT_PHONE">
  <div *ngIf="loaded" class="login-container">
    <div class="tabs login w-tabs">
      <div class="w-tab-pane w--tab-active">
        <div class="tab-pane login">
          <div class="login-title fullscreen-title">
            {{ 'REGISTRATION.PARENT_REGISTRATION' | translate }}
          </div>
          <div class="login-text w-center">
            {{ 'REGISTRATION.FILL_PARENT' | translate }}
          </div>
          <div class="user-data-form-block w-form">
            <form [formGroup]="form" name="wf-form-Login" autocomplete="off">
              <div class="login-form-header"></div>
              <div class="login-form-row">
                <div class="login-field-column">
                  <label [class.topped-out]="lastNameLabel" for="lastName" class="login-label">Фамилия*</label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="form.get('lastName').touched && lastNameLabel === false && f.lastName.errors"
                    maxlength="25"
                    name="lastName"
                    data-name="lastName"
                    id="lastName"
                    formControlName="lastName"
                    (focusin)="lastNameLabel = true"
                    (focusout)="form.get('lastName').value ? null : (lastNameLabel = false)"
                  />
                  <ng-container *ngIf="form.get('lastName').touched && lastNameLabel === false && f.lastName.errors">
                    <mat-error *ngIf="f.lastName.errors.required"> Заполните поле </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <div class="login-field-column">
                  <label for="firstName" [class.topped-out]="firstNameLabel" class="login-label">Имя*</label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="form.get('firstName').touched && firstNameLabel === false && f.firstName.errors"
                    maxlength="25"
                    name="firstName"
                    data-name="firstName"
                    id="firstName"
                    formControlName="firstName"
                    (focusin)="firstNameLabel = true"
                    (focusout)="form.get('firstName').value ? null : (firstNameLabel = false)"
                  />
                  <ng-container *ngIf="form.get('firstName').touched && firstNameLabel === false && f.firstName.errors">
                    <mat-error *ngIf="f.firstName.errors.required"> Заполните поле </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <div class="login-field-column">
                  <label for="email" [class.topped-out]="emailLabel" class="login-label">Email*</label>
                  <input
                    type="email"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="emailChecked && f.email.errors"
                    maxlength="70"
                    name="email"
                    data-name="email"
                    id="email"
                    formControlName="email"
                    (focusin)="emailLabel = true"
                    (focusout)="checkFormatEmail($event); form.get('email').value ? null : (emailLabel = false)"
                  />
                  <ng-container>
                    <mat-error *ngIf="!checkEmailPattern"> Проверьте правильность email </mat-error>
                    <mat-error *ngIf="f.email.errors?.duplicateUserName"> Такая электронная почта уже зарегистрирована </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <div class="login-field-column">
                  <div class="flex-row">
                    <div class="phone-wrapper">
                      <label for="phone" [class.topped-out]="phoneLabel" class="login-label">Телефон*</label>
                      <input
                        type="tel"
                        autocomplete="off"
                        class="login-field w-input phone-input"
                        [class.error-field]="phoneChecked && f.phone.errors"
                        name="phone"
                        maxlength="25"
                        data-name="phone"
                        id="phone"
                        [textMask]="{ mask }"
                        formControlName="phone"
                        (input)="checkPhone()"
                        (focusin)="phoneChecked = false; phoneLabel = true"
                        (focusout)="form.get('phone').value ? null : (phoneLabel = false)"
                        (paste)="onPhonePaste($event, userTypes.parent)"
                      />
                      <ng-container *ngIf="phoneChecked && f.phone.errors">
                        <mat-error *ngIf="f.phone.errors.maxLength"> Номер телефона слишком длинный </mat-error>
                        <mat-error *ngIf="f.phone.errors.required || f.phone.errors.pattern || f.phone.errors.notValid">
                          Проверьте правильность номера телефона
                        </mat-error>
                        <mat-error *ngIf="f.phone.errors.isUsed"> Данный телефон уже используется </mat-error>
                        <mat-error *ngIf="f.phone.errors.invalidNumber"> Неверный формат номера </mat-error>
                        <mat-error style="max-width: 320px" *ngIf="f.phone.errors.limitExceed"
                          >   При отправке СМС произошла ошибка. Повторите попытку позже или
                          <a href="https://t.me/proftesthelp_bot" target="_blank" class="error-link">обратитесь в поддержку</a>
                        </mat-error>
                        <mat-error *ngIf="f.phone.errors.failed">    При отправке СМС произошла ошибка. Повторите попытку позже или
                          <a href="https://t.me/proftesthelp_bot" target="_blank" class="error-link">обратитесь в поддержку</a> </mat-error>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="f.password" class="login-form-row">
                <div class="form-full-width">
                  <div class="login-field-column" (focusout)="focusOutErrorChecking()" (focusin)="focusOutPasswordErrors = false">
                    <div class="login-input-container">
                      <label [class.topped-out]="phoneCodeLabel" for="password" class="login-label">
                        Введите одноразовый пароль из смс*
                      </label>
                      <input
                        *ngIf="isMaskedPassword"
                        type="password"
                        autocomplete="new-password"
                        class="login-field w-input short-right"
                        [class.error-field]="submitted && f.password.errors"
                        maxlength="25"
                        name="password"
                        data-name="password"
                        id="password"
                        formControlName="password"
                        (ngModelChange)="isValidPassword($event)"
                        (focusin)="phoneCodeLabel = true"
                        (focusout)="form.get('password')?.value ? null : (phoneCodeLabel = false)"
                      />

                      <input
                        *ngIf="!isMaskedPassword"
                        type="text"
                        id="password"
                        autocomplete="new-password"
                        class="login-field w-input short-right"
                        [class.error-field]="submitted && f.password.errors"
                        maxlength="25"
                        name="password"
                        data-name="password"
                        formControlName="password"
                        (ngModelChange)="isValidPassword($event)"
                        (focusin)="phoneCodeLabel = true"
                        (focusout)="form.get('password')?.value ? null : (phoneCodeLabel = false)"
                      />
                      <ng-container *ngIf="form.get('password')?.errors">
                        <div *ngIf="form.get('password').errors?.isOutdated" class="alert-message">
                          {{ 'REGISTRATION.CODE_IS_OUTDATED' | translate }}
                        </div>
                        <div *ngIf="form.get('password').errors?.notCorrect" class="alert-message">
                          {{ 'REGISTRATION.PASS_NOT_SUITS' | translate }}
                        </div>
                      </ng-container>
                      <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                    </div>
                    <div class="promt-text">
                      <span *ngIf="activeTimer" [class.timer-value__centered]="!smsAllowed" class="timer-value">{{ timer$ | async }}</span>
                      <a
                        *ngIf="!activeTimer && !smsAllowed"
                        class="promt-link sms-doesnt-send"
                        href="https://t.me/proftesthelp_bot"
                        target="_blank"
                        >СМС не пришло</a
                      >
                      <span>
                        <a *ngIf="smsAllowed" (click)="sendPasswordTwice()" [class.inactive]="activeTimer" class="promt-link">
                          {{ 'REGISTRATION.SEND_TWICE' | translate }}
                        </a>
                        <div *ngIf="!smsAllowed" class="promt-link-wrapper">
                          <div [style.display]="supportAlert ? 'block' : 'none'" class="info-popup" #infopopup>
                            <p>Вы можете связаться с поддержкой через чат</p>
                            <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ADD CHILD -->
              <div class="add-child-section">
                <div class="profile-data-wrapper" [class.opened]="parentRegStep === 3">
                  <div class="add-child-text">Заполните данные о ребенке</div>
                  <form [formGroup]="formChild" class="child-add-form">
                    <div class="profile-data-row">
                      <div class="profile-field-div">
                        <label [class.topped-out]="childLastNameLabel" for="lastNameChild" class="login-label"
                          >{{ 'SHARED.FORM.FAMILY' | translate }}*</label
                        >
                        <input
                          type="text"
                          autocomplete="off"
                          class="login-field w-input"
                          [class.error-field]="formChild.get('lastName').touched && childLastNameLabel === false && fChild.lastName.errors"
                          name="lastName"
                          maxlength="25"
                          data-name="lastName"
                          id="lastNameChild"
                          formControlName="lastName"
                          (focusin)="childLastNameLabel = true"
                          (focusout)="formChild.get('lastName')?.value ? null : (childLastNameLabel = false)"
                        />
                        <ng-container *ngIf="formChild.get('lastName').touched && childLastNameLabel === false && fChild.lastName.errors">
                          <mat-error *ngIf="fChild.lastName.errors.required"> Заполните поле </mat-error>
                        </ng-container>
                      </div>
                    </div>

                    <div class="profile-data-row">
                      <div class="profile-field-div">
                        <label [class.topped-out]="childFirstNameLabel" for="firstNameChild" class="login-label"
                          >{{ 'SHARED.FORM.NAME' | translate }}*</label
                        >
                        <input
                          type="text"
                          autocomplete="off"
                          class="login-field w-input"
                          [class.error-field]="
                            formChild.get('firstName').touched && childFirstNameLabel === false && fChild.firstName.errors
                          "
                          name="firstName"
                          maxlength="25"
                          data-name="firstName"
                          id="firstNameChild"
                          formControlName="firstName"
                          (focusin)="childFirstNameLabel = true"
                          (focusout)="formChild.get('firstName')?.value ? null : (childFirstNameLabel = false)"
                        />
                        <ng-container
                          *ngIf="formChild.get('firstName').touched && childFirstNameLabel === false && fChild.firstName.errors"
                        >
                          <mat-error *ngIf="fChild.firstName.errors.required"> Заполните поле </mat-error>
                        </ng-container>
                      </div>
                    </div>

                    <div class="profile-data-row">
                      <div class="profile-field-div">
                        <label [class.topped-out]="childBirthDateLabel" for="dateChild" class="login-label">Дата рождения*</label>
                        <input
                          type="text"
                          autocomplete="off"
                          class="login-field w-input"
                          [class.error-field]="invalidDate"
                          [textMask]="{ mask: dateMask }"
                          name="date"
                          maxlength="25"
                          data-name="date"
                          id="dateChild"
                          formControlName="date"
                          (focusin)="childBirthDateLabel = true"
                          (focusout)="checkFormatDate($event); formChild.get('date')?.value ? null : (childBirthDateLabel = false)"
                        />
                        <ng-container *ngIf="invalidDate">
                          <mat-error *ngIf="formChild.get('date').errors || invalidDate">
                            {{ 'REGISTRATION.FORM_ALERT.CHECK_DATA' | translate }}
                          </mat-error>
                        </ng-container>
                      </div>
                    </div>

                    <div class="profile-data-row">
                      <div class="profile-field-div">
                        <label [class.topped-out]="childPhoneLabel" for="phoneChild" class="login-label">Телефон*</label>
                        <input
                          type="tel"
                          autocomplete="off"
                          class="login-field w-input phone-input"
                          [class.error-field]="phoneChildChecked && fChild.phone.errors"
                          name="phone"
                          maxlength="25"
                          data-name="phone"
                          id="phoneChild"
                          [textMask]="{ mask }"
                          formControlName="phone"
                          (focusin)="phoneChildChecked = false; childPhoneLabel = true"
                          (focusout)="checkChildPhone(); formChild.get('phone')?.value ? null : (childPhoneLabel = false)"
                          (paste)="onPhonePaste($event, userTypes.pupil)"
                        />

                        <ng-container *ngIf="phoneChildChecked && fChild.phone.errors">
                          <mat-error *ngIf="fChild.phone.errors.maxLength"> Номер телефона слишком длинный </mat-error>
                          <mat-error *ngIf="fChild.phone.errors?.required || fChild.phone.errors?.pattern || fChild.phone.errors?.notValid">
                            Проверьте правильность номера телефона
                          </mat-error>
                          <mat-error *ngIf="fChild.phone.errors?.isUsed"> Данный телефон уже используется </mat-error>
                          <mat-error *ngIf="fChild.phone.errors?.isEqual"> Номер ребенка совпадает с номером родителя </mat-error>
                        </ng-container>
                      </div>
                    </div>

                    <div class="profile-data-row inline-row">
                      <div>
                        <label class="login-label">Пол</label>
                        <div class="div-space"></div>
                      </div>
                      <div class="gender-choice">
                        <div class="gender-choice-row">
                          <a class="gender-button active" (click)="setMale()" [class.active]="isMale(genderChoice)" #male> М </a>
                          <a class="gender-button right" (click)="setFemale()" [class.active]="!isMale(genderChoice)" #female> Ж </a>
                        </div>
                      </div>
                    </div>

                    <div class="terms-block">
                      <div class="terms _agreement">
                        <prf-custom-check-box [formControl]="fChild.personalTerms">
                          Принимаю условия
                          <a href="/assets/docs/talent/OfferMinCifry.pdf" target="_blank">оферты</a>
                        </prf-custom-check-box>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="w-center-content">
                <button *ngIf="parentRegStep === 1" (click)="checkParent()" class="button-submit" [class.disabled]="!checkParentForm">
                  <span class="btn-text">Далее</span>
                </button>
                <button *ngIf="parentRegStep === 2" (click)="submitParent()" class="button-submit" [class.disabled]="!checkParentForm">
                  <span class="btn-text">Продолжить</span>
                </button>
                <button
                  *ngIf="parentRegStep === 3"
                  (click)="submit()"
                  class="button-submit"
                  [class.disabled]="!isAccessAllowed || !isAccessChildAllowed"
                >
                  <span class="btn-text">Зарегистрироваться</span>
                </button>
              </div>
            </form>
            <div class="login-form-footer">
              <div class="footer_sign-in">Уже есть аккаунт?<a href="/login" class="sign-in-link">Войти</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <prf-support-block *ngIf="loaded" />
</div>
