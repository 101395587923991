import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ETestTypesSteps } from '../../../../shared/enums/testtypes.enum';
import { PlayerService } from '../../../player/player.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { PupilPanelService } from '../pupil-panel.service';
import { CurrentStepService } from './current-step.service';

@Component({
  selector: 'prf-welcome-steps',
  templateUrl: './welcome-steps.component.html',
  styleUrls: ['./welcome-steps.component.scss'],
})
export class WelcomeStepsComponent implements OnInit {
  public loaded: boolean = false;
  public stepNumber: number = 1;
  public userId: string = '';
  public sessionStatus: string = '';
  public testSessionId: string = '';
  public uncompletedTest: boolean = false;
  public currentSlide: number = 1;
  public countSlide: number = 1;
  public userRole: string = '';
  public showBanner: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private router: Router,
    private pupilPanelService: PupilPanelService,
    private playerService: PlayerService,
    private currentStepService: CurrentStepService,
  ) {
    this.sessionStatus = localStorage.getItem('sessionStatus');
    this.testSessionId = localStorage.getItem('testSessionId');
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');

    this.countSlide = this.userRole === 'parent' ? ETestTypesSteps.DEFAULT_360 : ETestTypesSteps.DEFAULT_STANDART;
    this.uncompletedTest = this.sessionStatus === 'Uncompleted test';
  }

  ngOnInit() {
    this.stepNumber = this.getStep();

    this.checkBanner();
    this.checkStep()
      .pipe(
        switchMap(r => {
          return this.getSlide();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => (this.loaded = true));
  }

  private getSlide(): Observable<any> {
    if (this.uncompletedTest) {
      return this.playerService.getCurrentSlide(this.testSessionId).pipe(
        tap(slide => {
          if (slide && slide.slide && slide.slide.orderNumber) {
            this.currentSlide = slide.slide.orderNumber;
          }
        }),
      );
    } else {
      return of(null);
    }
  }

  private getStep(): number {
    const step = parseInt(localStorage.getItem('welcomeStep'));
    return step > 0 ? step : 0;
  }

  private setStep(step: number): Observable<any> {
    this.stepNumber = step;
    return this.currentStepService
      .updateCurrentStep(this.stepNumber.toString())
      .pipe(tap(r => localStorage.setItem('welcomeStep', step.toString())));
  }

  private checkStep(): Observable<any> {
    if (this.sessionStatus !== 'Success') {
      return this.setStep(1); // Пройти тест
    } else {
      return this.pupilPanelService.getUserGoals(this.userId).pipe(
        switchMap(goals => {
          if (this.stepNumber < 6 && goals && goals.length) {
            return this.setStep(4); // Изучать больше!
          } else if (this.stepNumber > 3 && (!goals || !goals.length)) {
            return this.setStep(3); // Выбрать профессию
          } else if ((!this.stepNumber || this.stepNumber === 1) && this.sessionStatus === 'Success') {
            return this.setStep(2); // Узнать о своих навыках
          } else {
            return of(null);
          }
        }),
      );
    }
  }

  checkBanner() {
    // этапы: после прохождения теста, перед выбором профессии, после выбора профессии
    const checkStep = () => {
      return [2, 3, 4].includes(this.stepNumber);
    };
    if (this.userRole === 'pupil' && checkStep()) {
      this.pupilPanelService
        .getTestingSessions(this.userId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(sessions => {
          const sessionsCompleted = sessions.filter(s => s.completed === true).sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1));
          const lastCompletedSession = sessionsCompleted[sessionsCompleted.length - 1];
          if (lastCompletedSession) {
            const sessionDate = new Date(lastCompletedSession.createdDate);
            // баннер показан, если последний пройденный тест ранее указанной даты
            const startDate = new Date(AppSettingsService.settings.banner.lastSessionBeforeDate);
            if (sessionDate.getTime() < startDate.getTime()) {
              this.showBanner = true;
            } else {
              this.showBanner = false;
            }
          } else {
            this.showBanner = false;
          }
        });
    } else {
      this.showBanner = false;
    }
  }

  public navigateStartTest() {
    this.router.navigate(['/test-na-professiyu']);
  }

  public navigateContinueTest() {
    this.router.navigate(['/test-na-professiyu/' + this.testSessionId]);
  }

  public navigateResults() {
    this.setStep(3)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.router.navigate(['/results']));
  }

  public navigateProfessions() {
    this.router.navigate(['/professions'], { fragment: 'recommendations' });
  }

  public hideThisBlock() {
    this.setStep(6).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
