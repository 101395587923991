<prf-menu></prf-menu>

<div class="content-section">
  <prf-page-header [title]="'Избранное'"></prf-page-header>
  <div class="container prof-page-cont">
    <div class="professions-section">
      <prf-page-switcher [valuesArray]="favoritesMenu" (selectedMenuEmitter)="selectTab($event)"></prf-page-switcher>
      <ng-container *ngIf="loaded">
        <div *ngIf="selectedTab === 'professions'" class="favourites-section _professions">
          <ng-container *ngIf="professionsFavorites && professionsFavorites.length > 0; else emptyContent">
            <div class="catalog-events-row">
              <prf-profession-card-new *ngFor="let profession of professionsFavorites" [profession]="profession"> </prf-profession-card-new>
            </div>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === 'courses'" class="favourites-section _courses">
          <ng-container *ngIf="coursesFavorites && coursesFavorites.length > 0; else emptyContent">
            <div class="catalog-events-row">
              <prf-course-card *ngFor="let course of coursesFavorites" [course]="course"></prf-course-card>
            </div>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === 'events'" class="favorites-section _events">
          <ng-container *ngIf="favoriteEvents && favoriteEvents.length > 0; else emptyContent">
            <div class="catalog-events-row">
              <prf-course-card *ngFor="let event of favoriteEvents" [course]="course"></prf-course-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <prf-support-block />
    </div>
  </div>

  <ng-template #emptyContent>
    <div class="empty-block">
      <div>
        <img src="/assets/images/dashboard/favourites.svg" width="80" class="no-profession-icon" />
      </div>
      <div class="empty-block-text">{{ 'SHARED.YOU_HAVE_NOT_ADDED_ANYTHING' | translate }}</div>
    </div>
  </ng-template>
</div>
