import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesComponent } from './favorites.component';
import { FavoritesService } from './favorites.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { SupportBlockComponent } from '../../../shared/common-components/support-block/support-block.component';

@NgModule({
  declarations: [FavoritesComponent],
  imports: [CommonModule, BrowserModule, RouterModule, SharedModule, SupportBlockComponent],
  providers: [FavoritesService],
})
export class FavoritesModule {}
