import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICookieInterface } from '../../shared/interfaces/icookie';
import { HttpService } from '../services/http.service';

@Injectable()
export class MainGuard {
  constructor(
    private router: Router,
    private http: HttpService,
    public route: ActivatedRoute,
    private cookieService: CookieService,
    private sharedService: SharedService,
  ) {}

  canActivate(): Observable<boolean> {
    const userLogout: boolean = this.router.getCurrentNavigation().extras.state?.userLogout;
    console.log('test123');
    if (userLogout) {
      return of(true);
    }
    return of(true);
    /*return this.checkCookie().pipe(
      switchMap((cookieResponse: ICookieInterface) => {
        if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
          return of(false);
        }
        if (cookieResponse.status !== 'Success') {
          this.sharedService.logOut();
          return of(true);
        } else {
          const token = localStorage.getItem('userRole');
          switch (token) {
            case 'admin': {
              this.router.navigate(['/admin/schools']);
              return of(false);
            }
            case 'schooladmin': {
              this.router.navigate(['/schooladmin/classes']);
              return of(false);
            }
            case 'pupil':
            case 'parent': {
              console.log(123123, token);
              this.router.navigate(['/' + token]);
              return of(true);
            }
            default:
              return of(true);
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        // если получаем ошибку 401 Unauthorized, то попадаем на '/'
        // в этом случае нельзя выполнить /logout
        if (err.status >= 500 && err.status < 600) {
          this.sharedService.logOut();
        }
        return of(true);
      }),
    );*/
  }

  public checkCookie() {
    return this.http.get('/b2c/v1.0/saas/checkcookie').pipe(map((r: ICookieInterface) => r));
  }
}
