import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserInfoClass } from '../../../shared/classes/userInfo.class';
import { REG_EXP } from '../../../shared/global-constants/reg-exp';
import { OverlayBusyService } from '../../../shared/overlay-busy/overlay-busy.service';
import { ChangePasswordService } from '../../../pages/catalog/settings-page/change-password/change-password.service';
import { CurrentStepService } from '../../../pages/control-panel/pupil/welcome-steps/current-step.service';
import { LoginProsvetService } from './login-prosvet.service';
import { SharedService } from 'app/shared/shared.service';
import { IProsvetUserData } from 'app/shared/interfaces/iprosvet.userdata.interface';
import { PrEventsEnums } from 'app/shared/enums/pr-events.enums';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { LoginService } from 'app/landing/b2c/login-b2c/login.service';

export enum LoginCasesProsvet {
  LOGIN,
  REGISTER,
  UPDATE,
}

@Component({
  selector: 'prf-login-prosvet',
  templateUrl: './login-prosvet.component.html',
  styleUrls: ['./login-prosvet.component.scss'],
})
export class LoginProsvetComponent implements OnInit {
  public form: UntypedFormGroup;
  public passFailed: boolean = false;
  public buttonStates = {
    waiting: false,
    active: false,
    activated: false,
  };
  defaultTerritoryId: string = '00000000-0000-0000-0000-000000000000';

  private authCode: string = null;
  public prosvetUserData: IProsvetUserData = {
    userId: null,
    externalAppUserId: null,
    email: null,
    firstName: null,
    middleName: null,
    lastName: null,
    role: null,
    schoolId: null,
    birthday: null,
    gender: null,
    city: null,
    tag: null,
    status: null,
    comment: null,
  };

  public loginCasesProsvet = LoginCasesProsvet;
  public prosvetUserState: any = LoginCasesProsvet.LOGIN;

  ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private loginService: LoginService,
    private overlayService: OverlayBusyService,
    private currentStepService: CurrentStepService,
    private changePasswordService: ChangePasswordService,
    private sharedService: SharedService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private loginProsvetService: LoginProsvetService,
    @Inject(DOCUMENT) document: Document,
  ) {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
      this.authCode = params['code'];
    });
  }

  ngOnInit() {
    this.createForm();
    this.overlayService.hide();

    if (this.authCode) {
      this.getProsvetUserData(this.authCode).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }
  }

  getProsvetUserData(code: string): Observable<any> {
    return this.loginProsvetService.getProsvetUserData(this.authCode).pipe(
      take(1),
      switchMap(prosvetUserData => {
        this.prosvetUserData = prosvetUserData;
        return this.handleGetUserDataResponse(this.prosvetUserData);
      }),
      catchError(err => {
        this.utilsService.openSnackBar(`Серверная ошибка: ${err.error.message}`, 'error');
        return throwError(err);
      }),
    );
  }

  private handleGetUserDataResponse(prosvetUserData: IProsvetUserData): Observable<any> {
    if (prosvetUserData) {
      let currentObservable$: Observable<any> = of(null);

      switch (prosvetUserData.status) {
        case 'Success':
          {
            // можно логинить пользователя
            this.prosvetUserState = LoginCasesProsvet.LOGIN;
            currentObservable$ = this.doProsvetLogin(prosvetUserData);
          }
          break;
        case 'User does not exist':
          {
            // можно регистрировать пользователя
            this.handleRegisterResponse(prosvetUserData.role);
          }
          break;
        case 'Different registration type':
          {
            this.utilsService.openSnackBar(
              'Для вашего пользователя недоступен вход через сервис "Просвещение". Попробуйте другой вариант входа в систему',
              'error',
            );
          }
          break;
        case 'User with current email already exists':
          {
            this.utilsService.openSnackBar('В системе уже есть другой пользователь с такой электронной почтой', 'error');
          }
          break;
        case "Can't create user with current role":
          {
            this.utilsService.openSnackBar('Для вашей роли недоступен вход в систему. Обратитесь к администратору', 'error');
          }
          break;
        case 'Failed':
          {
            if (prosvetUserData.comment.includes("Can't get information about Prosveshcheniye user")) {
              this.utilsService.openSnackBar('При входе через сервис "Просвещение" возникла ошибка', 'error');
            } else if (prosvetUserData.comment.includes("Can't find school")) {
              this.utilsService.openSnackBar('Вашей школы нет в системе', 'error');
            } else if (prosvetUserData.comment.includes("Can't find city")) {
              this.utilsService.openSnackBar('Вашего города нет в системе', 'error');
            } else {
              this.utilsService.openSnackBar(`Ошибка регистрации, комментарий: ${prosvetUserData.comment}`, 'error');
            }
          }
          break;
      }
      return currentObservable$;
    } else {
      return of(null);
    }
  }

  private handleRegisterResponse(role: string) {
    if (role === 'pupil' || role === 'parent') {
      this.prosvetUserState = LoginCasesProsvet.REGISTER;
    } else {
      this.prosvetUserState = LoginCasesProsvet.LOGIN;
      this.utilsService.openSnackBar('Вход через сервис "Просвещение" доступен только для родителей и учеников', 'error');
    }
  }

  public doProsvetLogin(prosvetUserData: IProsvetUserData): Observable<any> {
    return this.loginProsvetService.loginProsvetUser(prosvetUserData).pipe(
      switchMap(loginResult => {
        if (!loginResult || loginResult.succeeded === false) {
          this.prosvetUserState = LoginCasesProsvet.LOGIN;
          this.utilsService.openSnackBar(
            '👎 Вход в систему заблокирован из-за смены пароля. Обратитесь в службу поддержки.',
            'error',
            15000,
          );
          return of(null);
        } else {
          localStorage.setItem('userRole', loginResult.role);
          localStorage.setItem('userId', loginResult.userId);
          localStorage.setItem('tag', loginResult.tag);
          localStorage.setItem('isAuthorized', 'true');

          return this.sharedService.getUserInfoData().pipe(
            switchMap((ui: UserInfoClass) => {
              if (ui) {
                this.setUserInfoInLS(ui);
              }

              switch (loginResult.role) {
                case 'parent': {
                  this.router.navigate(['/parent']);
                  return of(null);
                }
              }

              return this.currentStepService.getCurrentStep().pipe(
                switchMap(welcomeStep => {
                  localStorage.setItem('welcomeStep', welcomeStep);
                  return this.changePasswordService.getUserSession(loginResult.userId).pipe(
                    tap(data => {
                      const sessionId = data.status == 'Success' || 'Uncompleted test' ? data.sessionId : undefined;
                      if (sessionId) {
                        localStorage.setItem('testSessionId', sessionId);
                        localStorage.setItem('sessionStatus', data.status);
                        //если требуется обновить профиль, перекидываем сначала на страницу обновления
                        prosvetUserData.needToUpdate
                          ? (this.prosvetUserState = LoginCasesProsvet.UPDATE)
                          : this.router.navigate(['/pupil']);
                      } else if (data.status == 'Untested user') {
                        prosvetUserData.needToUpdate
                          ? (this.prosvetUserState = LoginCasesProsvet.UPDATE)
                          : this.router.navigate(['/test-na-professiyu']);
                      }
                    }),
                  );
                }),
              );
            }),
          );
        }
      }),
    );
  }

  setUserInfoInLS(ui: UserInfoClass) {
    if (!ui) {
      throw 'User info is not defined';
    }

    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    localStorage.setItem('city', ui.city);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;
  }

  prosvetReg() {
    this.sharedService
      .getProsvetRoute()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(prosvetRoute => {
        if (prosvetRoute) {
          location.href = prosvetRoute.url;
          return;
        }
      });
  }
  // Методы логина
  doLogin() {
    // LS - Local Storage

    const setUserProfileLS = loginResult => {
      if (!loginResult) {
        throw 'Login result is not defined';
      }
      localStorage.setItem('userRole', loginResult.role);
      localStorage.setItem('userId', loginResult.userId);
      localStorage.setItem('tag', loginResult.tag);
      localStorage.setItem('isAuthorized', 'true');
    };
    const setUserInfoInLS = (ui: UserInfoClass) => {
      if (!ui) {
        throw 'User info is not defined';
      }
      localStorage.setItem('imagePath', ui.imagePath);
      localStorage.setItem('firstName', ui.firstName);
      localStorage.setItem('lastName', ui.lastName);
      localStorage.setItem('schoolId', ui.schoolId);
      localStorage.setItem('userGender', ui.gender);
      localStorage.setItem('companyId', ui.companyId);
      localStorage.setItem('position', ui.position);
      localStorage.setItem('regionId', ui.regionId);
      localStorage.setItem('municipalityId', ui.municipalityId);
      ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;

      if (localStorage.getItem('userRole') == 'admin') {
        setAdminLevelLS(ui);
      }
    };
    const setAdminLevelLS = (ui: UserInfoClass) => {
      const userRegionId = ui.regionId;
      const userMunicipalityId = ui.municipalityId;
      if (userRegionId != null && userMunicipalityId != null) {
        if (userRegionId == this.defaultTerritoryId && userMunicipalityId == this.defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.GLOBAL);
        }
        if (userRegionId != this.defaultTerritoryId && userMunicipalityId == this.defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.REGION);
        }
        if (userRegionId != this.defaultTerritoryId && userMunicipalityId != this.defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.MUNICIPALITY);
        }
      }
    };
    const setActiveRout = (role: string) => {
      const userRoles = {
        admin: () => this.router.navigate(['/admin/schools']),
        schooladmin: () => this.router.navigate(['/schooladmin/classes']),
        tutor: () => this.router.navigate(['/tutor']),
        '': () => this.removeWaiting(),
      };
      const isRoleValid = userRoles.hasOwnProperty(role);
      userRoles[isRoleValid ? role : '']();
      return isRoleValid;
    };

    this.loginService
      .login(this.loginForm.email.value, this.loginForm.password.value, false)
      .pipe(
        switchMap((loginResult: any) => {
          if (loginResult.succeeded === false) {
            this.passFailed = true;
            this.failWaiting();
            return of(null);
          } else {
            localStorage.clear();
            setUserProfileLS(loginResult);

            return this.sharedService.getUserInfoData().pipe(
              switchMap((ui: UserInfoClass) => {
                setUserInfoInLS(ui);

                if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
                  this.router.navigate(['/vorobievi-gori/promotest-results']);
                  return of(null);
                }

                const isRoleValid = setActiveRout(loginResult.role);
                if (isRoleValid) {
                  return of(null);
                }
                const userRole = loginResult.role;

                if (userRole === 'pupil') {
                  return this.currentStepService.getCurrentStep().pipe(
                    switchMap(welcomeStep => {
                      localStorage.setItem('welcomeStep', welcomeStep);

                      return this.loginService.getLastSession(loginResult.userId).pipe(
                        tap((session: any) => {
                          const sessionId = session.status == 'Success' || 'Uncompleted test' ? session.sessionId : undefined;
                          if (sessionId) {
                            localStorage.setItem('testSessionId', sessionId);
                            localStorage.setItem('sessionStatus', session.status);
                            if (loginResult.tag == PrEventsEnums[PrEventsEnums.Marathon]) {
                              localStorage.setItem('promoTag', loginResult.tag); // метка для определения участия пользователя в промо акции
                              return this.router.navigate(['/results-marathon', sessionId]);
                            }

                            this.router.navigate(['/pupil']);
                          } else if (session.status == 'Untested user') {
                            this.router.navigate(['/test-na-professiyu']);
                          }
                        }),
                      );
                    }),
                  );
                } else if (userRole === 'parent') {
                  return this.loginService.getLastSession(loginResult.userId).pipe(
                    tap((session: any) => {
                      const sessionId = session.status == 'Success' || 'Uncompleted test' ? session.sessionId : undefined;
                      localStorage.setItem('sessionStatus', session.status);
                      if (sessionId) {
                        localStorage.setItem('testSessionId', sessionId);

                        this.router.navigate(['/parent']);
                      } else if (session.status == 'Untested user') {
                        this.router.navigate(['/test-na-professiyu']);
                      }
                    }),
                  );
                } else {
                  return of(null);
                }
              }),

              catchError(err => {
                // У главного админа нет профиля
                return throwError(err);
              }),
            );
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.removeWaiting());
  }

  public onClickLogin() {
    if (this.isAccessDenied) {
      throw 'Access denied!';
    }
    this.doLogin();
  }

  // Методы работы с формой
  protected createForm() {
    this.form = this.fb.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(REG_EXP.emailRegExp)]),
      password: new UntypedFormControl(null, [Validators.required]),
    });

    this.form.get('email')['focused'] = false;
    this.form.get('password')['focused'] = false;
  }

  get loginForm(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  get isAccessDenied() {
    return this.loginForm.email.invalid || this.loginForm.password.invalid;
  }

  get isLoginInvalid() {
    return this.loginForm.email.dirty && this.loginForm.email.invalid && this.loginForm.email.errors;
  }

  get isPasswordInvalid() {
    return this.loginForm.password.dirty && this.loginForm.password.invalid && this.loginForm.password.errors;
  }

  // Методы переключение состояний кнопки (анимации)
  protected removeWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.activated = true;
  }

  protected failWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.active = false;
  }

  public animateLogin() {
    this.buttonStates.active = true;
    this.buttonStates.waiting = true;
    this.doLogin();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
