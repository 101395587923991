import { Injectable } from '@angular/core';

@Injectable()
export class ModalHandlerService {
  public setStaticBody(): void {
    document.getElementsByTagName('body')[0].classList.add('body-fixed');
  }

  public removeStaticBody(): void {
    document.getElementsByTagName('body')[0].classList.remove('body-fixed');
  }
}
