import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import * as moment from 'moment';
import { AdminPanelService } from '../../../../pages/control-panel/admin/admin-panel.service';
import { ChildrenAddService } from '../../../../pages/control-panel/parent/children-add/children-add.service';
import { FilterByNamePipe } from '../../../../shared/pipes/filter-by-name.pipe';
import { FilterSchoolsPipe } from '../../../../shared/pipes/filter-schools.pipe';
import { AppSettingsService } from '../../../../shared/services/appsettings.service';
import { RegistrationService } from '../registration.service';
import { SharedService } from 'app/shared/shared.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { ICity } from 'app/shared/interfaces/icity';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { SCHOOL_NUMBERS } from 'app/shared/global-constants/school-numbers';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';

@Component({
  selector: 'prf-registration-pupil-b2c',
  templateUrl: './registration-pupil-b2c.component.html',
  styleUrls: ['./registration-pupil-b2c.component.scss'],
})
export class RegistrationPupilB2cComponent implements OnInit {
  @Input() tag: string;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;

  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;

  public _regionsView;
  public addition: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  private _citiesView;
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  private _schoolView: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public personalTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public codeIsUsed: boolean = false;
  public checkEmail: boolean = true;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public registrationByParams;
  boolean = false;

  public isNotEnoughYears: boolean = false; // признак для определения разрешенного возраста
  private minAge: number = 14; // минимальный разрешенный возраст для регистрации ученика
  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;
  private ngUnsubscribe$: Subject<any> = new Subject<any>();

  role: string = 'pupil';

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private regionsService: RegionsService,
    private childrenAddService: ChildrenAddService,
    private filterSchoolsPipe: FilterSchoolsPipe,
    private filterByNamePipe: FilterByNamePipe,
    private translateService: TranslateService,
    private adminPanelService: AdminPanelService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.initFormGroup();

    this.SCHOOL_LETTERS = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');

    this.getData()
      .pipe(
        switchMap(_ => {
          return this.activatedRoute.queryParams.pipe(
            takeUntil(this.ngUnsubscribe$),
            switchMap(params => {
              this.regionsView = this.regions;

              if (params?.regionId) {
                this.registrationByParams = true;
                const region = this.regionsView.find(region => region.data.id === params.regionId);
                if (region) {
                  this.defaultRegion = region.data.regionId;
                  this.form.get('region').setValue(region);
                  this.onRegionChange();
                  if (params.cityId) {
                    const city = this.citiesView.find(city => city.data.id === params.cityId);
                    if (city) {
                      this.form.get('city').setValue(city);
                      this.onCityChange();
                      if (params.schoolId) {
                        const school = this.schoolView.find(school => school.data.id === params.schoolId);
                        if (school) {
                          this.form.get('school').setValue(school);
                          return this.onSchoolChange().pipe(
                            tap(r => {
                              if (params.classId) {
                                const pupilschoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === params.classId);
                                this.f.schoolClass.setValue(pupilschoolClass);
                              }
                            }),
                          );
                        } else {
                          return of(null);
                        }
                      } else {
                        return of(null);
                      }
                    }
                  } else {
                    return of(null);
                  }
                } else {
                  this.defaultRegion = AppSettingsService.settings.b2c.regionId;
                  this.regions = this.regions.filter(r => r.id === this.defaultRegion);
                  this.f.region.setValue(this.regionsView.find(r => r.data.id === this.defaultRegion));
                  this.onRegionChange();
                  return of(null);
                }
              } else {
                //b2c user registration
                this.tag = 'b2c';
                this.defaultRegion = AppSettingsService.settings.b2c.regionId;
                this.f.region.setValue(this.regionsView.find(r => r.data.id === AppSettingsService.settings.b2c.regionId));
                this.onRegionChange();

                this.f.city.setValue(this.citiesView.find(r => r.data.id === AppSettingsService.settings.b2c.cityId));
                this.onCityChange();
                this.f.school.setValue(this.schoolView.find(r => r.data.id === AppSettingsService.settings.b2c.schoolId));

                this.f.schoolClass.setValue(this.schoolClassView.find(r => r.data.id === AppSettingsService.settings.b2c.classId));

                if (!this.f.schoolClass.value) {
                  this.f.schoolClassNumber.setValue({ name: 7 });
                  this.f.schoolClassLetter.setValue({ name: 'А' });
                }
                return of(null);
              }
            }),
          );
        }),
      )
      .subscribe();
  }

  private initFormGroup() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null),
      date: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.dateRegExp)]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(this.passwordRegExp),
      ]),
      role: new UntypedFormControl(this.role, [Validators.required]),
      region: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      school: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl('M', [Validators.required]),
      personalTerms: new UntypedFormControl(false, [Validators.requiredTrue]),
      schoolClass: new UntypedFormControl(null, []),
    });
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      let credentials: any;
      if (this.registrationByParams) {
        credentials = {
          regionId: this.f.region.value.data.id,
          municipalityId: this.f.city.value.data.municipalityId,
          city: this.f.city.value.data.name,

          birthday: moment(this.f.date.value, 'DD/MM/YYYY', true).format('YYYY-MM-DD'),
          email: this.f.email.value,
          lastName: this.f.lastName.value,
          firstName: this.f.firstName.value,
          middleName: this.f.middleName.value,
          password: this.f.password.value,
          role: this.f.role.value,
          gender: this.f.gender.value,
          schoolClassLetter: this.f.schoolClass.value.data.letter,
          schoolClassNumber: this.f.schoolClass.value.data.number,
          schoolId: this.f.school.value.data.id,
          schoolClass: this.f.schoolClass.value.data.id,
          tag: this.tag,
        };
      } else {
        //b2c user registration
        const city = this.cities.find(c => c.id === AppSettingsService.settings.b2c.cityId) || {
          name: '',
        };
        const schoolClass = this.classes.find(s => s.id === AppSettingsService.settings.b2c.classId) || {
          letter: 'A',
          number: 7,
        };
        credentials = {
          city: city.name,
          regionId: AppSettingsService.settings.b2c.regionId,
          municipalityId: AppSettingsService.settings.b2c.municipalityId,
          birthday: moment(this.f.date.value, 'DD/MM/YYYY', true).format('YYYY-MM-DD'),
          email: this.f.email.value,
          lastName: this.f.lastName.value,
          firstName: this.f.firstName.value,
          middleName: this.f.middleName.value,
          password: this.f.password.value,
          role: this.f.role.value,
          gender: this.f.gender.value,
          schoolClassLetter: schoolClass.letter,
          schoolClassNumber: schoolClass.number,
          schoolId: AppSettingsService.settings.b2c.schoolId,
          tag: this.tag,
        };
      }

      if (credentials) {
        localStorage.clear();

        this.registrationFailed = false;
        this.passFailed = false;
        this.registrationService
          .openregistration(credentials)
          .pipe(
            switchMap((response: any) => {
              if (response) {
                if (response.userId == undefined || response.userId == null) {
                  this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                  this.registrationFailed = true; // ошибка регистрации
                  this.failWaiting();
                  return of(null);
                } else {
                  // Сразу попадаем в ЛК
                  return this.registrationService.login(response.userName, credentials.password, false).pipe(
                    switchMap((loginResult: any) => {
                      if (loginResult) {
                        if (loginResult.succeeded === false) {
                          this.loginFailed = true;
                          this.passFailed = true;
                          return of(null);
                        } else {
                          localStorage.setItem('userRole', loginResult.role);
                          localStorage.setItem('userId', loginResult.userId);
                          localStorage.setItem('tag', loginResult.tag);
                          localStorage.setItem('isAuthorized', 'true');

                          return this.sharedService.getUserInfoData().pipe(
                            tap((ui: UserInfoClass) => {
                              if (ui) {
                                this.setUserInfoInLS(ui);
                              }
                              switch (loginResult.role) {
                                case 'pupil': {
                                  return this.router.navigate(['/pupil']);
                                }
                              }
                              this.removeWaiting();
                            }),
                          );
                        }
                      } else {
                        return of(null);
                      }
                    }),
                  );
                }
              } else {
                return of(null);
              }
            }),
            takeUntil(this.ngUnsubscribe$),
          )
          .subscribe(_ => this.removeWaiting());
      }
    } else {
      this.failWaiting();
    }
  }

  getData(): Observable<any> {
    return this.getRegions().pipe(switchMap(_ => this.getCities().pipe(switchMap(_ => this.getSchools()))));
  }

  getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(regions => {
        this.regions = regions.filter(region => region.hrid != 'defaultRegion');
      }),
    );
  }

  getCities(): Observable<any> {
    return this.adminPanelService.getAllCities().pipe(tap(cities => (this.cities = cities)));
  }

  getSchools(): Observable<any> {
    return this.childrenAddService.getCatalogSchools().pipe(
      tap((schools: any[]) => {
        this.schools = [...schools].map(school => ({
          city: school.city,
          value: school.number,
          viewValue: school.number,
          id: school.id,
        }));
      }),
    );
  }
  getSchoolClassBySchool(id): Observable<any> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  onRegionChange() {
    this.f.city.reset();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }
  onCityChange() {
    this.f.school.reset();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }
  onSchoolChange(): Observable<any> {
    if (this.f.school.value && this.f.school.value.data.id) {
      return this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .pipe(
          tap(r => {
            this.f.schoolClass.reset();
            this.f.schoolClassNumber.reset();
            this.f.schoolClassNumber.reset();
          }),
        );
    } else {
      return of(null);
    }
  }

  set regionsView(regions) {
    this._regionsView = [...regions].map(region => ({ name: region.name, data: region }));
  }

  get regionsView() {
    return [...this.regions].map(region => ({ name: region.name, data: region }));
  }

  set citiesView(regionId) {
    this._citiesView = regionId
      ? [...this.cities].filter(city => city.regionId === regionId).map(city => ({ name: city.name, data: city }))
      : [];
  }
  get citiesView() {
    return this._citiesView;
  }

  set schoolView(cityName) {
    this._schoolView = cityName
      ? [...this.schools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
      : [];
  }
  get schoolView() {
    return this._schoolView;
  }

  get schoolClassView() {
    return [...this.classes].map(schoolClass => ({
      name: schoolClass.number + schoolClass.letter,
      data: schoolClass,
    }));
  }
  get schoolClassNumber() {
    return [...SCHOOL_NUMBERS].map(number => ({ name: number.value, data: number }));
  }
  get schoolClassLetter() {
    return [...this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS')].map(letter => ({
      name: letter.value,
      data: letter,
    }));
  }
  get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }

  get currentClassLetter() {
    return this.f.schoolClassLetter.value ? this.f.schoolClassLetter.value.name : this.f.schoolClass.value.data.letter;
  }
  get currentClassNumber() {
    return this.f.schoolClassNumber.value ? this.f.schoolClassNumber.value.name : this.f.schoolClass.value.data.number;
  }

  private setDefaultErrorFlagValues(): void {
    this.isWhiteSpaceError = false;
    this.isCharactersError = true;
    this.isLetterError = true;
    this.isNumberError = true;
    this.isRusLettersError = false;
  }
  private updateErrorFlagValues(password: string): void {
    this.isWhiteSpaceError = !this.testWhiteSpace.test(password);
    this.isCharactersError = !this.testSixCharter.test(password);
    this.isLetterError = !this.testOneLetter.test(password);
    this.isNumberError = !this.testOneDigit.test(password);
    this.isRusLettersError = this.testRusLetters.test(password);
  }
  private isAnyPasswordError(): boolean {
    return this.isCharactersError || this.isLetterError || this.isNumberError || this.isRusLettersError || this.isWhiteSpaceError;
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  setUserInfoInLS(ui: UserInfoClass) {
    const defaultTerritoryId: string = '00000000-0000-0000-0000-000000000000';

    const setAdminLevelLS = (ui: UserInfoClass) => {
      const userRegionId = ui.regionId;
      const userMunicipalityId = ui.municipalityId;
      if (userRegionId != null && userMunicipalityId != null) {
        if (userRegionId == defaultTerritoryId && userMunicipalityId == defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.GLOBAL);
        }
        if (userRegionId != defaultTerritoryId && userMunicipalityId == defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.REGION);
        }
        if (userRegionId != defaultTerritoryId && userMunicipalityId != defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.MUNICIPALITY);
        }
      }
    };

    if (!ui) {
      throw 'User info is not defined';
    }

    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;

    if (localStorage.getItem('userRole') == 'admin') {
      setAdminLevelLS(ui);
    }
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }
  public checkBirthdayDate(isCorrectDate: boolean) {
    const date = moment();
    const userDate = moment(this.f.date.value, 'DD/MM/YYYY', true); // порядок определен форматом даты на фронте
    const diff = moment.duration(date.diff(userDate)).asYears();
    this.isNotEnoughYears = diff < this.minAge;
  }
  public animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }
  public removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }
  public failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }
  public focusOutErrorChecking() {
    this.focusOutPasswordErrors = this.isAnyPasswordError();
  }
  public additionEnable() {
    this.addition = !this.addition;
  }
  public checkFormatEmail(event): void {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);
    }
  }
  public checkFormatDate(event): void {
    if (event) {
      this.checkBirthdayDate(true);
      this.checkBirthday = true;
    }
  }
  public tooggleSelectClassType() {
    this.isClasses = !this.isClasses;
    this.f.schoolClass.reset();
    this.f.schoolClassNumber.reset();
    this.f.schoolClassLetter.reset();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  get isAccessAllowed() {
    return this.form.valid && this.isValidPassword(this.f.password.value) && !this.isNotEnoughYears;
  }
  public isValidPassword(password: string): boolean {
    if (password && password.length > 0) {
      this.updateErrorFlagValues(password);
      if (this.isAnyPasswordError()) {
        this.isNotValidPassword = true; // isWhiteSpaceError был без этой записи
        return false;
      } else {
        this.isNotValidPassword = false;
        return true;
      }
    } else {
      this.setDefaultErrorFlagValues();
      return false;
    }
  }
}
