<div
  #container
  [style.top.px]="top"
  [style.left.px]="left"
  [style.visibility]="visibility"
  class="info-popup recommendation w-hidden-small w-hidden-tiny prf-tooltip"
>
  <p>{{ params.text }}</p>
  <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
</div>
