import { Component, Input, OnInit } from '@angular/core';

/* DOCS for charts:  https://valor-software.com/ng2-charts/ and http://www.chartjs.org/docs/latest/  */
@Component({
  selector: 'prf-talents',
  templateUrl: './talents.component.html',
  styleUrls: ['./talents.component.scss'],
})
export class TalentsComponent implements OnInit {
  @Input() data;

  // Pie
  public pieChartLabels: string[] = [];
  public pieChartData: number[] = [];
  public pieChartType: string = 'pie';
  public chartColors: any[] = [{ backgroundColor: ['#ffa1b5', '#86c7f3', '#ffe29a', '#93d9d9', '#c964a0', '#49147e'] }];
  public pieChartOptions: any = {
    legend: {
      labels: {
        fontSize: 16,
      },
    },
    pointLabels: {
      fontSize: 16,
      fontColor: '#444',
      fontFamily: '"Nunito Sans", Arial',
    },
  };
  public pieChartLegend: boolean = true;

  constructor() {}

  ngOnInit() {
    this.data.forEach(item => {
      this.pieChartLabels.push(item.name);
      this.pieChartData.push(item.percents);
    });
  }
}
