<div *ngIf="!isLoading" class="pupil-page">
  <prf-menu [root]="'pupil'"></prf-menu>
  <!--  <img class="logo_cornered" src="/assets/images/dashboard/profilum-new-logo.svg" alt="Profilum logo" />-->

  <ng-container *ngIf="isOnboarding; else dashboardPage">
    <prf-onboarding-steps (onboardingFinished)="finishOnboarding()"></prf-onboarding-steps>
  </ng-container>

  <ng-template #dashboardPage>
    <div class="content-section">
      <prf-page-header [title]="title" [showMobile]="false"></prf-page-header>
      <div class="container vertical-container">
        <section class="diagnostics-tabs-section">
          <div class="diagnostics-tabs-wrapper">
            <div class="diagnostics-tabs-container">
              <div class="diagnostic-tab-wrapper" *ngFor="let diagnostic of diagnosticsList">
                <div class="diagnostic-tab">
                  <div class="diagnostic-header">
                    <div class="diagnostic-name">{{ diagnostic.name }}</div>
                    <div class="diagnostic-description">{{ diagnostic.description }}</div>
                  </div>

                  <div class="diagnostic-buttons">
                    <ng-container [ngSwitch]="true">
                      <div *ngSwitchCase="diagnostic.diagnosticState === 2">
                        <button class="diagnostic-button button-blue" (click)="routingTest(diagnostic)">
                          {{ 'PUPIL.PASS_DIAGNOSTIC' | translate }}
                        </button>
                      </div>

                      <div *ngSwitchCase="diagnostic.diagnosticState === 3 || diagnostic.diagnosticState === 4">
                        <button class="diagnostic-button button-simple" (click)="openResultsPage(diagnostic)">
                          {{ 'PUPIL.VIEW_RESULTS' | translate }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </section>

        <section class="status-section">
          <div
            class="status-bar-container"
            [class.vertical-align]="window.innerWidth <= 991"
            [style.background]="
              window.innerWidth > 991
                ? dashboardStatus === dashboardStatuses.action
                  ? '#EEF1FE'
                  : '#FFEFCF'
                : dashboardStatus === dashboardStatuses.action
                  ? '#EEF1FE'
                  : '#FFEFCF'
            "
          >
            <div class="status-title" [innerHTML]="dashboardStatus.title"></div>

            <div class="status-text" [innerHTML]="dashboardStatus.text"></div>
          </div>
        </section>

        <prf-support-block />
      </div>
    </div>
  </ng-template>
</div>
