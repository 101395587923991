import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { UserAccessService } from 'app/shared/services/useraccess.service';
import { SharedService } from 'app/shared/shared.service';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { UserDataHandlerService } from '@profilum-library';

@Component({
  selector: 'prf-motivation-results-page',
  templateUrl: './motivation-results-page.component.html',
  styleUrls: ['./motivation-results-page.component.scss'],
})
export class MotivationResultsPageComponent implements OnInit {
  public userRole: string = '';
  public urlSessionId: string = '';
  public userProfile: any;

  public showFullResults: boolean = false;
  loaded: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private userAccessService: UserAccessService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
      }
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (this.userRole === 'parent') {
      this.sharedService
        .getUserInfoData()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(data => {
          this.userProfile = data;
        });
    }

    this.userAccessService
      .getUserAccesses()
      .pipe(
        take(1),
        tap(userAccesses => {
          if (userAccesses) {
            const motivationTestId = TESTS_VARIANTS_IDS.motivationtest;
            const resultsAccess = userAccesses.find(el => el.screeningTestId === motivationTestId);
            this.showFullResults = resultsAccess.isTestResult === true;

            this.loaded = true;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
