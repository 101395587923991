import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { PrEventsEnums } from 'app/shared/enums/pr-events.enums';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChangePasswordService } from '../../../pages/catalog/settings-page/change-password/change-password.service';
import { CurrentStepService } from '../../../pages/control-panel/pupil/welcome-steps/current-step.service';
import { OverlayBusyService } from '../../../shared/overlay-busy/overlay-busy.service';
import { LoginService } from './login.service';
import { environment } from 'environments/environment';
import { SettingsService } from 'app/shared/dashboard/settings/settings.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Helper } from 'app/shared/common/helper';
import { AuthHandlerService, IUserInfo, IUserProfileInfo, UserDataHandlerService } from '@profilum-library';

export enum ELoginForms {
  BY_PHONE = 1,
  CODE_INPUT,
  BY_EMAIL,
  PASSWORD_INPUT,
  ADD_PHONE,
  ADD_CODE_INPUT,
}

@Component({
  selector: 'prf-login-b2c',
  templateUrl: './login-b2c.component.html',
  styleUrls: ['./login-b2c.component.scss'],
})
export class LoginB2cComponent implements OnInit {
  public form: UntypedFormGroup;
  public passFailed: boolean = false;
  public codeFailed: boolean = false;
  public anotherForm: boolean = false;
  public unRegistered: boolean = false;
  public isMaskedPassword: boolean = true;
  public buttonStates = {
    waiting: false,
    active: false,
    activated: false,
  };
  public phoneMask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public phoneMaskWithoutCode = ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  public phoneMaskUsed = this.phoneMask;
  emailRegExp = REG_EXP.emailRegExp;

  public loginForms = ELoginForms;
  public loginFormVariant: ELoginForms = ELoginForms.BY_PHONE;

  defaultTerritoryId: string = '00000000-0000-0000-0000-000000000000';

  public isVGuser: boolean = false;
  public urlToken: string = '';

  public userPhone: string = null;

  public phoneLabel = false;
  public emailLabel = false;
  public checkPhone: boolean = false;
  public checkEmail: boolean = true;
  timer$ = new Subject<number>();
  activeTimer: boolean = false;
  smsAllowed: boolean = true;
  supportAlert: boolean = false;
  incorrectLoginData: boolean = false;
  public showInfoBlock: boolean = false;

  private loginResult: { role: string; userId: string; succeeded: boolean; tag: string };

  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;

  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;
  public pathToForm: string = '';
  translations: string[] = [];

  isProcessing: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private translateService: TranslateService,
    private overlayService: OverlayBusyService,
    private loginService: LoginService,
    private currentStepService: CurrentStepService,
    private changePasswordService: ChangePasswordService,
    private sharedService: SharedService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private settingsService: SettingsService,
    private utilsService: UtilsService,
    private authHandlerService: AuthHandlerService,
    private userDataHandlerService: UserDataHandlerService,
    @Inject(DOCUMENT) public document: Document,
  ) {
    this.getTranslations();
    this.translateService
      .get('SHARED.ENTRANCE')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
    this.userPhone = this.router.getCurrentNavigation()?.extras.state?.userPhone || null;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/vorobievi-gori')) {
          this.isVGuser = true;
          this.urlToken = '/vorobievi-gori';
        }
      }
    });
  }

  ngOnInit(): void {
    this.pathToForm = environment.production ? 'class.profilum.ru' : 'saasdev.profilum.ru';
    //localStorage.clear();
    this.createForm();
    this.overlayService.hide();
  }

  public get phoneValue(): string {
    const formValue: string = this.form.get('phone').value.replace(/\D/g, '');

    return formValue.length === 11 ? formValue : '7' + formValue;
  }

  // Методы работы с формой
  private createForm() {
    this.form = this.fb.group({
      phone: new UntypedFormControl(this.userPhone, [Validators.pattern(REG_EXP.phoneRegExp)]),
      code: new UntypedFormControl(null),
      email: new UntypedFormControl(null, [Validators.pattern(REG_EXP.emailRegExp)]),
      password: new UntypedFormControl(null),
    });

    this.form.get('phone')['focused'] = true;
    if (this.userPhone) {
      this.phoneLabel = true;
    }
    //this.form.get('email')['focused'] = false;
    // this.form.get('password')['focused'] = false;
  }

  submit() {
    this.isProcessing = true;
    switch (this.loginFormVariant) {
      case ELoginForms.BY_PHONE:
        this.submitPhoneLogin();
        break;
      case ELoginForms.CODE_INPUT:
        this.loginByCode();
        break;
      case ELoginForms.BY_EMAIL:
        this.submitEmail();
        break;
      case ELoginForms.PASSWORD_INPUT:
        this.loginByEmail();
        break;
      case ELoginForms.ADD_PHONE:
        this.submitPhoneUpdate();
        break;
      case ELoginForms.ADD_CODE_INPUT:
        this.updateUserPhone();
        break;
    }
  }

  updateUserPhone() {
    const userName = this.phoneValue;
    const code = this.form.get('code').value;
    this.loginService
      .updateUserName(userName, code)
      .pipe(
        switchMap(r => {
          if (r.status === 'Success') {
            this.parseLogin(this.loginResult);
          } else if (r?.status === 'Not found') {
            this.form.get('code').setErrors({ needSupport: true });
          }
          return of(null);
        }),
      )
      .subscribe(() => (this.isProcessing = false));
  }

  loginByCode() {
    this.startTimer(60);
    const phone = this.phoneValue;
    const code = this.form.get('code').value;
    this.loginService
      .login(phone, code)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(loginResult => {
        this.isProcessing = false;
        if (!loginResult.succeeded) {
          this.form.get('code').setErrors({notCorrect: true});
          this.codeFailed = true;
          return of(null);
        } else {
          this.parseLogin(loginResult);
        }
      });
  }

  loginByEmail() {
    const email = this.form.get('email').value;
    const password = this.form.get('password').value;
    this.loginService
      .login(email, password)
      .pipe(
        switchMap(loginResult => {
          if (!loginResult.succeeded && !loginResult.isUnRegistered) {
            this.passFailed = true;
            this.loginFormVariant = ELoginForms.BY_EMAIL;
          } else if (!loginResult.succeeded && loginResult.isUnRegistered) {
            this.unRegistered = true;
            this.loginFormVariant = ELoginForms.BY_EMAIL;
          } else {
            if (loginResult.tag != 'b2c') {
              this.anotherForm = true;
              this.loginFormVariant = ELoginForms.BY_EMAIL;
              this.sharedService.logOut();
            }

            if (loginResult.role === 'tutor') {
              this.parseLogin(loginResult);
            }

            this.loginFormVariant = ELoginForms.ADD_PHONE;
          }
          this.loginResult = loginResult;
          return of(null);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => (this.isProcessing = false));
  }

  newPhoneLogin() {
    const phone = this.phoneValue;
    const code = this.form.get('code').value;

    this.loginService
      .updateUserName(phone, code)
      .pipe(
        tap(r => {
          if (r.status === 'Success') {
            this.loginByCode();
          } else {
            if ((r.comment = 'Sms code is outdated')) {
              this.utilsService.openSnackBar(`Sms code is outdated`, 'error');
            } else if ((r.comment = 'Invalid code')) {
              this.utilsService.openSnackBar(`Invalid code`, 'error');
            }
            //todo добавиьт обработку на неправильно указанный номер телефона, не только код
            this.form.get('code').setErrors({ notCorrect: true });
            this.codeFailed = true;
            return of(null);
          }
        }),
      )
      .subscribe(() => (this.isProcessing = false));
  }

  public parseLogin(loginResult): void {
    console.log('is login?');
    this.authHandlerService.setUserProfile(loginResult);
    const userInfo: IUserProfileInfo = this.userDataHandlerService.getUserInfo();
    this.userDataHandlerService.updateUserInfo();
    this.setUserProfileLS(userInfo);
    // const setUserProfileLS = loginResult => {
    //   if (!loginResult) {
    //     throw 'Login result is not defined';
    //   }
    //   console.log('login result ', loginResult);
    //
    // };

    // if (userData.regionId != null && userData.municipalityId != null) {
    //   if (userData.regionId == this.defaultTerritoryId && userData.municipalityId == this.defaultTerritoryId) {
    //     localStorage.setItem('adminLevel', AdminAccessLevel.GLOBAL);
    //   }
    //   if (userData.regionId != this.defaultTerritoryId && userData.municipalityId == this.defaultTerritoryId) {
    //     localStorage.setItem('adminLevel', AdminAccessLevel.REGION);
    //   }
    //   if (userData.regionId != this.defaultTerritoryId && userData.municipalityId != this.defaultTerritoryId) {
    //     localStorage.setItem('adminLevel', AdminAccessLevel.MUNICIPALITY);
    //   }
    // }
  }

  private setUserProfileLS(userInfo): void {
    console.log('setUP');
    if (!['pupil', 'parent', 'tutor'].includes(userInfo.role)) {
      this.utilsService.openSnackBar('Неверный сервис. Логин для вашей роли не доступен.', 'error');
      this.sharedService.logOut();
    }
    //parent || pupil with tag != b2c
    if (userInfo.tag != 'b2c') {
      this.utilsService.openSnackBar('Неверный сервис. Логин для вашей роли не доступен.', 'error');
      this.sharedService.logOut();
    }
    if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
      this.router.navigate(['/vorobievi-gori/promotest-results']);
    }
    const userRole = userInfo.role;

    if (userRole === 'pupil') {
      this.currentStepService
        .getCurrentStep()
        .pipe(
          switchMap(welcomeStep => {
            localStorage.setItem('welcomeStep', welcomeStep);

            return this.loginService.getLastSession(userInfo.userId).pipe(
              takeUntil(this.ngUnsubscribe$),
              tap((session: any) => {
                const sessionId = session.status === 'Success' || 'Uncompleted test' ? session.sessionId : undefined;
                if (sessionId) {
                  localStorage.setItem('testSessionId', sessionId);
                  localStorage.setItem('sessionStatus', session.status);
                  if (userInfo.tag == PrEventsEnums[PrEventsEnums.Marathon]) {
                    localStorage.setItem('promoTag', userInfo.tag); // метка для определения участия пользователя в промо акции
                    return this.router.navigate(['/results-marathon', sessionId]);
                  }
                  console.log('route to pupil 3');
                  this.router.navigate(['/pupil']);
                } else if (session.status == 'Untested user') {
                  this.router.navigate(['/test-na-professiyu']);
                }
              }),
            );
          }),
        )
        .subscribe();
    } else if (userRole === 'parent') {
      this.loginService
        .getLastSession(userInfo.userId)
        .pipe(
          tap((session: any) => {
            const sessionId = session.status == 'Success' || 'Uncompleted test' ? session.sessionId : undefined;
            localStorage.setItem('sessionStatus', session.status);
            if (sessionId) {
              localStorage.setItem('testSessionId', sessionId);

              this.router.navigate(['/parent']);
            } else if (session.status == 'Untested user') {
              this.router.navigate(['/test-na-professiyu']);
            }
          }),
        )
        .subscribe();
    }
  }

  prosvetReg() {
    this.sharedService
      .getProsvetRoute()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(prosvetRoute => {
        if (prosvetRoute) {
          location.href = prosvetRoute.url;
          return;
        }
      });
  }

  submitPhoneLogin() {
    const phone = this.phoneValue;
    this.loginService
      .checkPhone(phone)
      .pipe(
        take(1),
        catchError(err => {
          this.utilsService.openSnackBar('Неисправности с базой данных, уже чиним.', 'error');
          return throwError(err);
        }),
      )
      .subscribe(response => {
        if (response.status === 'Success') {
          if (response.isFree) {
            this.form.get('phone').setErrors({ notExist: true });
          } else {
            this.sendPhone(this.phoneValue).pipe(take(1)).subscribe();
          }
        }
        this.isProcessing = false;
      });
  }

  submitPhoneUpdate() {
    const phone = this.phoneValue;
    this.loginService
      .checkPhone(phone)
      .pipe(
        take(1),
        catchError(err => {
          this.utilsService.openSnackBar('Неисправности с базой данных, уже чиним.', 'error');
          return throwError(err);
        }),
        switchMap(r => {
          if (r.status === 'Success') {
            if (!r.isFree) {
              this.form.get('phone').setErrors({ isUsed: true });
            } else {
              return this.sendPhone(phone);
            }
          }
          return of(null);
        }),
      )
      .subscribe(_ => (this.isProcessing = false));
  }

  submitEmail() {
    this.loginFormVariant = ELoginForms.PASSWORD_INPUT;
    this.isProcessing = false;
  }

  submitAllowed(): boolean {
    switch (this.loginFormVariant) {
      case ELoginForms.BY_PHONE:
        return this.submitPhoneAllowed;
      case ELoginForms.CODE_INPUT:
        return this.submitCodeAllowed;
      case ELoginForms.BY_EMAIL:
        return this.submitEmailAllowed;
      case ELoginForms.PASSWORD_INPUT:
        return this.isValidPassword(this.form.get('password').value);
      case ELoginForms.ADD_PHONE:
        return this.submitPhoneAllowed;
      case ELoginForms.ADD_CODE_INPUT:
        return this.submitCodeAllowed;
      default:
        return false;
    }
  }

  public sendPasswordTwice(): void {
    this.sendPhone(this.phoneValue)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(r => {
          if (r.status === 'Success') {
            this.smsAllowed = false;
            this.toggleInfoBlock();
          } else {
            this.form.get('code').setErrors({ failed: true });
          }
        }),
      )
      .subscribe();
  }

  sendPhone(phone): Observable<any> {
    return this.loginService.sendPhone(phone).pipe(
      tap(response => {
        if (response?.status === 'Success') {
          this.displayInfoBlock();
          if (this.loginFormVariant === 1 || this.loginFormVariant === 5) {
            ++this.loginFormVariant;
          }
          this.loginFormVariant;
          this.cd.detectChanges();
          const codeInputElement = document.getElementById('codeInput');
          if (codeInputElement) codeInputElement.focus();

          //temp fix
          if (!environment.production) {
            this.form.get('code').setValue(response.code);
            this.form.get('code').updateValueAndValidity();
            this.isMaskedPassword = false;
          }
          this.startTimer(60);
          return;
        } else if (response?.status === 'Not Found') {
          if (response.comment.includes('No user with phone')) {
            this.form.get('phone').setErrors({ noUserPhone: true });
          }
          this.form.get('phone').setErrors({ notFound: true });
          return;
        } else {
          if (response.comment.includes('message is denied')) {
            this.form.get('phone').setErrors({ limitFailed: true });
          }
          this.form.get('phone').setErrors({ failed: true });
          return;
        }
      }),
    );
  }

  clearInput(control) {
    if (control) {
      this.form.get(control).setValue(null);
      this.form.get(control).updateValueAndValidity();
    }
  }

  changeEmailReg() {
    this.loginFormVariant = this.loginForms.BY_EMAIL;
    this.form.get('phone').setValue(null);
    this.form.get('code').setValue(null);
    this.isProcessing = false;
  }

  changePhoneReg() {
    this.loginFormVariant = this.loginForms.BY_PHONE;
    this.form.get('email').setValue(null);
    this.form.get('password').setValue(null);
    this.isProcessing = false;
  }

  supportRequest(event: Event) {
    this.supportAlert = true;
    setTimeout(() => {
      this.supportAlert = false;
      this.openOnlineConsultant(event);
    }, 2000);
  }

  startTimer(seconds: number) {
    let count = seconds;
    this.activeTimer = true;
    timer(0, 1000)
      .pipe(
        take(count),
        tap(r => {
          count > 0 ? --count : 0;
          this.timer$.next(count);
          if (!count) {
            this.activeTimer = false;
          }
        }),
      )
      .subscribe();
  }

  routingRePassword() {
    this.router.navigate(['/recovery_password']);
  }

  get submitPhoneAllowed() {
    return this.form.get('phone').value && this.form.get('phone').valid;
  }

  get submitCodeAllowed() {
    return this.form.get('code').value && this.form.get('code').valid;
  }

  get submitEmailAllowed() {
    return this.form.get('email').value && this.form.get('email').valid;
  }

  public checkFormatEmail(event): void {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.form.get('email').value);
    }
  }

  get loginForm(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  get isAccessDenied() {
    return this.loginForm.email.invalid || this.loginForm.password.invalid;
  }

  get isLoginInvalid() {
    return this.loginForm.email.dirty && this.loginForm.email.invalid && this.loginForm.email.errors;
  }

  get isPasswordInvalid() {
    return this.loginForm.password.dirty && this.loginForm.password.invalid && this.loginForm.password.errors;
  }

  public isValidPassword(password: string): boolean {
    if (password && password.length > 0) {
      this.updateErrorFlagValues(password);
      if (this.isAnyPasswordError()) {
        this.isNotValidPassword = true; // isWhiteSpaceError был без этой записи
        return false;
      } else {
        this.isNotValidPassword = false;
        return true;
      }
    } else {
      this.setDefaultErrorFlagValues();
      return false;
    }
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  private setDefaultErrorFlagValues(): void {
    this.isWhiteSpaceError = false;
    this.isCharactersError = true;
    this.isLetterError = true;
    this.isNumberError = true;
    this.isRusLettersError = false;
  }

  private updateErrorFlagValues(password: string): void {
    this.isWhiteSpaceError = !this.testWhiteSpace.test(password);
    this.isCharactersError = !this.testSixCharter.test(password);
    this.isLetterError = !this.testOneLetter.test(password);
    this.isNumberError = !this.testOneDigit.test(password);
    this.isRusLettersError = this.testRusLetters.test(password);
  }

  private isAnyPasswordError(): boolean {
    return this.isCharactersError || this.isLetterError || this.isNumberError || this.isRusLettersError || this.isWhiteSpaceError;
  }

  get loginTitleText(): string {
    switch (this.loginFormVariant) {
      case ELoginForms.BY_PHONE:
        return 'LOGIN.HELLO_PROFILUM';
      case ELoginForms.CODE_INPUT:
        return 'LOGIN.HELLO_PROFILUM';
      case ELoginForms.BY_EMAIL:
        return 'LOGIN.HELLO_PROFILUM';
      case ELoginForms.PASSWORD_INPUT:
        return 'LOGIN.HELLO_PROFILUM';
      case ELoginForms.ADD_PHONE:
        return 'LOGIN.ADD_YOUR_PHONE';
      case ELoginForms.ADD_CODE_INPUT:
        return 'LOGIN.HELLO_PROFILUM';
      default:
        return 'LOGIN.HELLO_PROFILUM';
    }
  }

  get loginFormText() {
    switch (this.loginFormVariant) {
      case ELoginForms.BY_PHONE:
        return 'LOGIN.CONFIRM_NUMBER';
      case ELoginForms.CODE_INPUT:
        return 'LOGIN.GET_PASSWORD_BY_SMS';
      case ELoginForms.BY_EMAIL:
        return 'LOGIN.ENTER_YOUR_EMAIL';
      case ELoginForms.PASSWORD_INPUT:
        return 'LOGIN.ENTER_PASSWORD';
      case ELoginForms.ADD_PHONE:
        return 'LOGIN.AUTH_BY_PHONE_CHANGE';
      case ELoginForms.ADD_CODE_INPUT:
        return 'LOGIN.PASSWORD';
      default:
        return '';
    }
  }

  get promptText() {
    switch (this.loginFormVariant) {
      case ELoginForms.CODE_INPUT:
        return 'LOGIN.SEND_TWICE';
      case ELoginForms.BY_EMAIL:
        return 'LOGIN.ENTER_BY_PHONE';
      case ELoginForms.PASSWORD_INPUT:
        return 'LOGIN.NOT_REMEMBER_PASS';
      case ELoginForms.ADD_PHONE:
        return null;
      case ELoginForms.ADD_CODE_INPUT:
        return 'LOGIN.SEND_TWICE';
      default:
        return '';
    }
  }

  // Методы переключение состояний кнопки (анимации)
  removeWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.activated = true;
  }

  public onPaste($event: ClipboardEvent): void {
    $event.preventDefault();
    let copied: string = $event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string

    if (copied.length === 11) {
      copied = copied.slice(1);

      this.form.get('phone').setValue('+7' + copied);
    } else if (copied.length === 10) {
      $event.preventDefault();
      this.form.get('phone').setValue('+7' + copied);
    }
  }

  failWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.active = false;
  }

  getTranslations() {
    this.translateService
      .get([
        'LOGIN.HELLO_PROFILUM',
        'LOGIN.CONFIRM_NUMBER',
        'LOGIN.PHONE',
        'LOGIN.EMAIL',
        'LOGIN.PASSWORD',
        'LOGIN.GET_PASSWORD_BY_SMS',
        'LOGIN.EVERY_TIME_NEW',
        'LOGIN.ENTER_YOUR_NUMBER',
        'LOGIN.ENTER_BY_EMAIL',
        'LOGIN.PASSWORD_IN_SMS',
        'LOGIN.ENTER_FROM_MESSAGE',
        'LOGIN.SEND_TWICE',
        'LOGIN.PASSWORD_NOT_MATCH',
        'LOGIN.WRITE_TO_SUPPORT',
        'LOGIN.NUMBER_NOT_REGISTERED',
        'LOGIN.ENTER_YOUR_EMAIL',
        'LOGIN.ENTER_BY_PHONE',
        'LOGIN.NOT_REMEMBER_PASS',
        'LOGIN.ENTER_PASSWORD',
        'LOGIN.PASSWORD_INCORRECT',
        'LOGIN.ADD_YOUR_PHONE',
        'LOGIN.AUTH_BY_PHONE_CHANGE',
        'LOGIN.NUMBER_ADDED',
        'LOGIN.CAN_NUMBER_AUTH',
        'LOGIN.PASS_NOT_SUITS',
        'LOGIN.TO_CONTINUE',
        'LOGIN.TO_CONFIRM',
        'LOGIN.TO_ENTER',
        'LOGIN.INCORRECT_PASSWORD',
        'LOGIN.CHECK_EMAIL',
        'NUMBER_IS_NOT_EXIST',
      ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.translations = translations;
      });
  }

  public toggleInfoBlock(): void {
    this.showInfoBlock = !this.showInfoBlock;
  }

  public displayInfoBlock(): void {
    this.showInfoBlock = true;
  }

  public openOnlineConsultant(event: Event): void {
    Helper.openOnlineConsultantWidget(event);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
