import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { REG_EXP } from '../../../shared/global-constants/reg-exp';
import { ISchool } from '../../../shared/interfaces/ischool.interface';

import { SettingsService } from '../../../shared/dashboard/settings/settings.service';

import { UserProfileService } from './user-profile.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from 'app/shared/shared.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

// import { userInfo } from 'os';

@Component({
  selector: 'prf-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  // @Output() editProfile = new EventEmitter();
  CHANGE_PASS_RU = [
    {
      en: 'Incorrect password.',
      ru: '',
    },
    { en: 'Passwords must be at least 6 characters.', ru: 'Пароль должен быть не менее 6 символов.' },
    {
      en: "Passwords must have at least one lowercase ('a'-'z').",
      ru: "Пароль должен иметь по крайней мере одину букву ('a'-'z').",
    },
    {
      en: "Passwords must have at least one digit ('0'-'9').",
      ru: "Пароль должен иметь как минимум одну цифру ('0'-'9').",
    },
  ];
  school: ISchool;
  userClass: any;
  isParent: boolean = false;
  userInfo: IUserInfo;
  editProfile: boolean = false;
  animIndex: number = 0;

  parents: Array<any> = [];
  showInfoPopup: boolean = false;
  userRole: string;
  showInviteParent: boolean = false;
  uploadImageError: string = '';
  isMale: boolean = false;
  userBirthday: string = '';
  userPhone: string = '';
  changePasswordFlag: boolean = false;

  phonePattern = '+# (###) ###-##-##';

  form: UntypedFormGroup;
  password: string = '';
  submitted: boolean;
  errorChangePass: string = '';
  errorOldPass: string = '';

  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public isMaskedPassword: boolean = true;
  public isMaskedPasswordOld: boolean = true;
  public rusLettersError: boolean = false;
  public focusOutPasswordErrors: boolean = false;
  public charactersError: boolean = true;
  public letterError: boolean = true;
  public whiteSpaceError: boolean = false;
  public numberError: boolean = true;
  public promotestResults: any[] = [];
  public children: any[] = [];
  menuAllowed: boolean = true;

  public passwordError: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();
  public userAvatar: string;

  constructor(
    private meta: Meta,
    private userDataHandlerService: UserDataHandlerService,
    private profileService: UserProfileService,
    private sharedService: SharedService,
    private pageHeaderService: PageHeaderService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Профайл' });
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.isParent = this.userRole === 'parent';

    //set translation
    this.getTranslation('SHARED.INCORRECT_OLD_PASSWORD')
      .pipe(take(1))
      .subscribe(translation => (this.CHANGE_PASS_RU[0].ru = translation));
  }

  ngOnInit() {
    this.initForm();

    this.userDataHandlerService.getUserData().subscribe(response => {
      this.userInfo = response;
      if (this.userInfo) {
        this.userPhone = this.makePhoneString(this.userInfo.phoneNumber, this.phonePattern);
        this.userBirthday = moment.utc(this.userInfo.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');
        if (!this.userBirthday || this.userBirthday == 'Invalid date') {
          this.userBirthday = 'Дата не определена';
        }
        this.children = this.userInfo.children;
        let selectedChild;
        if (this.children && this.children.length) {
          selectedChild = this.children[this.children.length - 1];
        }
        if (this.userRole === 'parent') {
          this.menuAllowed = !!(selectedChild && selectedChild.id !== '00000000-0000-0000-0000-000000000000');
        }

        if (this.isParent) {
          return this.profileService.getSharedResults(this.userInfo.userId).pipe(
            takeUntil(this.ngUnsubscribe$),
            tap(sharedResults => {
              if (sharedResults.length > 0) {
                this.promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);

                this.children = this.children.map(child => {
                  return Object.assign(child, {
                    istestedMosru:
                      this.promotestResults.filter(oneResult => {
                        return oneResult.refferalUserId == child.userId;
                      }).length > 0,
                    sessionId:
                      this.promotestResults.filter(oneResult => {
                        return oneResult.refferalUserId === child.userId;
                      }).length > 0
                        ? this.promotestResults.filter(oneResult => {
                            return oneResult.refferalUserId === child.userId;
                          })[0].sessionId
                        : null,
                  });
                });
              }
            }),
          );
        } else {
          return of(null);
        }
      } else {
        return of(null);
      }
    });

    let currentObservable$: Observable<any> = of(null);
    if (this.userRole === 'pupil') {
      currentObservable$ = this.loadSchoolClass();
    }
    currentObservable$.pipe(take(1)).subscribe();
  }

  public initForm() {
    this.form = this.fb.group({
      password: new UntypedFormControl(this.password, [Validators.maxLength(256)]),
      passwordOld: new UntypedFormControl(this.password, [Validators.maxLength(256)]),
    });
  }

  loadSchoolClass(): Observable<any> {
    const userRole: string = localStorage.getItem('userRole');
    let currentObservable$: Observable<any> = of(null);

    if (this.userRole === 'pupil') {
      currentObservable$ = this.profileService.getSchoolClass();
    }

    return observableForkJoin([this.sharedService.getSchool(), currentObservable$]).pipe(
      tap(([school, schcl]) => {
        this.school = school;
        this.userClass = schcl[0];
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  private loadUser(): void {
    this.userDataHandlerService.updateUserInfo();
  }

  public logOut() {
    this.sharedService.logOut().subscribe();
  }

  inviteParent() {
    this.showInviteParent = true;
  }

  inviteClose() {
    this.showInviteParent = false;
  }

  selectFile(event) {
    this.uploadImageError = '';

    if (!event.target.files.item(0).type.match('image.*')) {
      this.uploadImageError = 'Неверный формат файла.';
      return;
    }

    if (event.target.files.item(0).size > 1024 * 1024) {
      this.uploadImageError = 'Размер не болле: 1 МB';
      return;
    }

    this.userInfo.imagePath = '';

    this.profileService
      .changeUserAvatar(event.target.files)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.loadUser());
  }

  getImageUrl(user: any) {
    return user.imagePath ? user.imagePath : '/assets/images/dashboard/no-photo.svg';
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  getMale(isMale: boolean) {
    if (this.userInfo.gender == 'M') {
      return true;
    } else {
      return false;
    }
  }

  changeIndex(animIndex: number) {
    if (this.animIndex == 0 || this.animIndex == 1) {
      this.animIndex = 2;
    } else {
      this.animIndex = 1;
    }
    return animIndex;
  }

  indexFromChild(event) {
    this.animIndex = event;
  }

  cancelEdit() {
    this.form.get('password').setValue('');
    this.form.get('password').updateValueAndValidity();
    this.form.get('passwordOld').setValue('');
    this.form.get('passwordOld').updateValueAndValidity();
  }

  get f() {
    return this.form.controls;
  }

  submitChanges() {
    this.submitted = true;
    if (!this.form.value.password || !this.form.value.passwordOld) {
      this.errorChangePass = 'Для смены пароля, введите старый и новый пароль.';
    } else if (this.form.value.password === this.form.value.passwordOld) {
      this.errorChangePass = 'Новый пароль не должен совпадать со старым';
    } else {
      this.updatePassword();
    }
  }

  updatePassword() {
    const model = {
      currentPassword: this.form.value.passwordOld,
      newPassword: this.form.value.password,
      newPassword2: this.form.value.password,
    };

    this.errorChangePass = '';
    if (!this.focusOutPasswordErrors) {
      this.settingsService
        .changePassword(model)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(changeResult => {
          if (changeResult) {
            if (changeResult.status === 'Success') {
              this.changePasswordFlag = !this.changePasswordFlag;
              this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
              this.router.navigate(['/login']);
            } else {
              if (changeResult.comment) {
                if (changeResult.comment.includes('Incorrect password')) {
                  this.errorOldPass = 'Неверный старый пароль.';
                } else {
                  this.errorChangePass = this.translateChangePassRequest(changeResult.comment);
                }
              }
            }
          }
        });
    }
  }

  public isAccessAllowed() {
    return this.testPassword(this.form.value.password);
  }

  private translateChangePassRequest(text: string) {
    const findText = this.CHANGE_PASS_RU.filter(cp => cp.en === text);
    if (findText && findText.length) {
      return findText[0].ru;
    } else {
      return text;
    }
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public toggleMaskOld() {
    this.isMaskedPasswordOld = !this.isMaskedPasswordOld;
  }

  public errorChecking(): boolean {
    if (this.passwordError) {
      return true;
    }
  }

  public focusOutErrorChecking() {
    if (this.form.value.password != '') {
      this.focusOutPasswordErrors =
        this.charactersError || this.letterError || this.numberError || this.rusLettersError || this.whiteSpaceError;
    }
  }

  private testPassword(event): boolean {
    this.passwordError = false;

    this.charactersError = true;
    this.letterError = true;
    this.numberError = true;
    this.rusLettersError = false;
    this.whiteSpaceError = false;
    if (event && event.length > 0) {
      this.charactersError = !this.testSixCharter.test(event);
      this.letterError = !this.testOneLetter.test(event);
      this.numberError = !this.testOneDigit.test(event);
      this.rusLettersError = this.testRusLetters.test(event);
      this.whiteSpaceError = !this.testWhiteSpace.test(event);
      if ((this.whiteSpaceError = !this.testWhiteSpace.test(event))) {
        return false;
      }
      if ((this.charactersError = !this.testSixCharter.test(event))) {
        this.passwordError = true;
        return false;
      }
      if ((this.letterError = !this.testOneLetter.test(event))) {
        this.passwordError = true;
        return false;
      }
      if ((this.numberError = !this.testOneDigit.test(event))) {
        this.passwordError = true;
        return false;
      }
      if ((this.rusLettersError = this.testRusLetters.test(event))) {
        this.passwordError = true;
        return false;
      }
      return true;
    }
  }

  private makePhoneString(string, pattern): string {
    let padded = '';
    if (string) {
      let i = 0;
      padded = pattern.replace(/#/g, () => {
        return string[i++];
      });
    }
    return padded;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public clearPassError() {
    this.errorChangePass = '';
    this.errorOldPass = '';
    this.focusOutPasswordErrors = false;
  }
}
