import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Gender } from '../../shared/enums/gender.enums';
import { ETestType } from './anketa/anketa.enums';
import { ETestTypes } from '../../shared/enums/testtypes.enum';
import { UserDataHandlerService } from '@profilum-library';

@Injectable()
export class PlayerService {
  constructor(
    private http: HttpService,
    private appSettingsService: AppSettingsService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
  }

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      const errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  createSession(userData: any): Observable<any> {
    const postData: any = {
      data: {
        User: {
          Gender: this.userDataHandlerService.getUserData().value.gender || Gender.Female,
        },
      },
      referralUserId: userData.referralUserId,
      language: userData!.language,
    };
    return this.http.post('/b2c/v1.0/pupils/createsession', postData).pipe(map(response => response));
  }

  createSessionParent(userData: any) {
    const postData: any = {data: {}};
    if (userData.testType === ETestType.Test360) {
      postData.ReferralUserId = localStorage.getItem('RefferalUserId');
      postData.data.ScreeningTestType = 'Screening360';
    }

    const childGender = localStorage.getItem('RefferalUserGender');
    if (childGender == Gender.Female || childGender == Gender.Male) {
      Object.assign(postData, {
        data: {
          ScreeningTestType: 'Screening360',
          user: {
            gender: childGender.toLowerCase(),
          },
        },
      });
    }

    return this.http.post('/b2c/v1.0/parents/createsession', postData).pipe(map(response => response));
  }

  createPormoTestSession(userData: any) {
    return this.http.post('/b2c/v1.0/parents/createsession', userData).pipe(map(response => response));
  }

  getTestsList(userId?) {
    return this.http.get('/api/v1.0/player/tests/list').pipe(map(resp => resp.json()));
  }

  getNextSlide(sessionId) {
    let url = '';
    const testType = localStorage.getItem('testType');
    const isAuthorized = localStorage.getItem('isAuthorized');
    if (!isAuthorized && testType == ETestTypes.VALUES_TEST.toString()) {
      url = '/api/v1.0/player/slides/anonymousnext?sessionId=';
    } else {
      url = '/api/v1.0/player/slides/next?sessionId=';
    }
    return this.http.get(url + sessionId).pipe(map(response => response));
  }

  getCurrentSlide(sessionId) {
    return this.http.get('/api/v1.0/player/slides/current?sessionId=' + sessionId).pipe(map(response => response));
  }

  getPreviousSlide(sessionId) {
    return this.http.get('/api/v1.0/player/slides/previous?sessionId=' + sessionId).pipe(map(response => response));
  }

  setUserAnswers(answers) {
    return this.http.post('/api/v1.0/results/useranswers', answers).pipe(map(response => response));
  }

  setAnswersResults(answers: any): Observable<any> {
    return this.http.post('/api/v1.0/results/useranswers', answers).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  calculateResults(sessionId) {
    return this.http.post('/api/v1.0/results/calculator/objects', { sessionId });
  }

  getResults(sessionId) {
    return this.http.get('/api/v1.0/results/page?sessionIds=' + sessionId);
  }

  userRegistration(registrationObject) {
    return this.http.post('/api/v1.0/auth/school/createschoolboy', registrationObject).pipe(map(response => response));
  }

  bindSession(sessionId) {
    return this.http.post('/b2c/v1.0/saas/bind', { sessionId });
  }

  getPlayerSession(userData) {
    //  создает и возвращает сессию
    /** Если уже есть созданные пользователи, то они указываются при создании сессии в этих полях: userId и refferalUserId.
     * Но если пользователи не созданы, то можно создать сессию без них, а потом сделать sessions/bind и привязать их
     * Это для реализации двух различных сценариев прохождения теста: с регистрацией во время теста и с регистрацией до теста
     */
    const postData: any = {
      userId: userData.userId,
      screeningTestId: userData.testId,
      data: userData.personalInfo,
    };
    // Для получения сессии родителем необходимо добавить параметры
    if (userData.isParent) {
      postData.data.ScreeningTestType = 'Screening360';
    }
    // Добавление данных пользователя в случае его сущестования. Это второй случай, описанный выше.
    if (userData.userId && userData.referralUserId) {
      postData.userId = userData.userId;
      postData.referralUserId = userData.referralUserId;
    }
    return this.http.post('/api/v1.0/player/sessions', postData).pipe(map(response => response));
  }
}
