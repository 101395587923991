<prf-content-loader *ngIf="!resultsFetched"></prf-content-loader>

<div *ngIf="resultsFetched && showResultMenu" class="professions-menu">
  <div *ngIf="userRole === 'tutor'" class="professions-menu-row-2">
    <a (click)="resultMenu = 'results'" [class.w--current]="resultMenu === 'results'" class="professions-menu-link w--current">
      {{ 'SHARED.RESULT.S' | translate }}
    </a>
    <a (click)="resultMenu = 'recommendations'" [class.w--current]="resultMenu === 'recommendations'" class="professions-menu-link">
      {{ 'SHARED.RECOMMENDATIONS' | translate }}
    </a>
  </div>
</div>

<div *ngIf="resultsFetched" [class.no-display]="resultMenu !== 'results'">
  <div class="result-container">
    <prf-test-switch
      *ngIf="showSwitch"
      [child]="child"
      [parent]="parent"
      [checkParent]="showParent"
      [checkPupil]="showPupil"
      [isCompleteTestParent]="isCompleteTestParent"
      [isCompleteTestPupil]="isCompleteTestPupil"
      (clickParent)="showParent = $event"
      (clickPupil)="showPupil = $event; checkResults()"
      class="test-switch"
    >
    </prf-test-switch>
    <a routerLink="/{{ userRole }}" class="testing-logo-link w-inline-block"> </a>
    <!--<a (click)="printer()" class="button white-button print-btn-2 w-button">Распечатать</a>-->
    <div>
      <ng-container *ngIf="!nullResults; else emptyResults">
        <section *ngIf="isCompleteTestPupil" class="test-result-section">
          <ng-container>
            <h1 class="test-result-h1">
              {{ userRole === 'pupil' ? ('SHARED.YOUR_TALENTS' | translate) : ('SHARED.CHILD_TALENTS' | translate) }}
            </h1>
            <p class="test-result-text">
              {{
                userRole === 'pupil'
                  ? ('TEST_AND_RESULTS.PUPIL_TALENTS_DESC' | translate)
                  : ('TEST_AND_RESULTS.PARENT_TALENTS_DESC' | translate)
              }}
            </p>
          </ng-container>

          <div class="talant-cards">
            <div *ngFor="let talent of talentsData" class="card-wrapper">
              <prf-pupil-talant-card [talant]="talent"></prf-pupil-talant-card>
            </div>

            <!-- <div class="professions-card _2 professions-card-talents">
                <div class="professions-image">
                  <img src="{{ talent.imageUrl }}" />
                </div>
                <div class="prof-column _2">
                  <div class="professions-desc-wrapper">
                    <h3 class="professions-h3">{{ talent.name }}</h3>
                    <div class="professions-short-text">
                      {{ talent.description | slice: 0:200 }}...
                    </div>
                    <div class="test-result-info-div">
                      <a
                        *ngIf="!isMobile"
                        (mouseenter)="talent.showDescription = true"
                        (mouseleave)="talent.showDescription = false"
                        class="read-more-2"
                      >
                        Подробнее
                      </a>
                      <a
                        *ngIf="isMobile"
                        (click)="talent.showDescription = !talent.showDescription"
                        class="read-more-2"
                        #closepopup
                      >
                        Подробнее
                      </a>
                      <div
                        [style.display]="talent.showDescription ? 'block' : 'none'"
                        class="info-popup"
                        #infopopup
                      >
                        <p>{{ talent.description }}</p>
                        <img
                          src="/assets/images/icons/info-triangle.png"
                          width="12"
                          class="info-triangle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
          </div>
        </section>

        <section *ngIf="isCompleteTestPupil" class="test-result-section _talants">
          <h2 class="test-result-h1">Из чего складываются таланты?</h2>
          <p class="test-result-text">
            {{
              userRole === 'pupil'
                ? ('TEST_AND_RESULTS.PUPIL_TALENTS_TERM' | translate)
                : ('TEST_AND_RESULTS.PARENT_TALENTS_TERM' | translate)
            }}
          </p>
          <div class="section-row">
            <div class="interests-tab">
              <div class="tab-title">Интересы</div>
              <div class="list-container">
                <div class="tab-list">
                  <div class="list-item" *ngFor="let category of interestsTopCategories">
                    <div class="category-name">{{ category.name }}</div>
                    <div class="categories-values">
                      <div class="category-value _child" *ngIf="showPupil">{{ category.childValue }}/100</div>
                      <div class="category-value _parent" *ngIf="showParent">{{ category.parentValue }}/100</div>
                    </div>
                  </div>
                </div>
              </div>
              <a class="interests-link" (click)="selectDetails(interests)">Подробнее</a>
            </div>
            <div class="skills-tab">
              <div class="tab-title">Навыки</div>
              <div class="list-container">
                <div class="tab-list">
                  <div class="list-item" *ngFor="let category of skillsTopCategories">
                    <div class="category-name">{{ category.name }}</div>
                    <div class="categories-values">
                      <div class="category-value _child" *ngIf="showPupil">{{ category.childValue }}/100</div>
                      <div class="category-value _parent" *ngIf="showParent">{{ category.parentValue }}/100</div>
                    </div>
                  </div>
                </div>
              </div>
              <a class="skills-link" (click)="selectDetails(skills)">Подробнее</a>
            </div>
          </div>

          <div class="diagram-row" *ngIf="showParent && showPupil">
            <div class="diagram-column">
              <div class="diagram-color-oval"></div>
              <div>
                {{ 'TEST_AND_RESULTS.CHILD_RESULTS' | translate }}
              </div>
            </div>
            <div class="diagram-column">
              <div class="diagram-color-oval blue"></div>
              <div>
                {{ 'TEST_AND_RESULTS.PARENT_RESULTS' | translate }}
              </div>
            </div>
          </div>
        </section>

        <section class="test-result-section" #skills>
          <h2 class="test-result-h1">Навыки</h2>
          <div>
            <p class="test-result-text">
              {{
                userRole === 'pupil'
                  ? ('TEST_AND_RESULTS.PUPIL_SKILLS_TERM' | translate)
                  : ('TEST_AND_RESULTS.PARENT_SKILLS_TERM' | translate)
              }}
            </p>
          </div>

          <div *ngIf="!showParent || !showPupil" class="test-result-diagram _2">
            <prf-spider-chart-custom
              [inputSpiderChartData]="skillsAttainmentData"
              [theme]="showParent ? SpiderChartThemeEnum.BLUE : SpiderChartThemeEnum.GREEN"
              [figureType]="SpiderChartFiguresEnum.HEXAGON"
            > </prf-spider-chart-custom>
          </div>

          <div *ngIf="showParent && showPupil" class="test-result-diagram">
            <div class="diagram-column-2">
              <prf-spider-chart-custom [inputSpiderChartData]="skillsAttainmentData"
                                       [isDoubleResultsVariant]="true"
                                       [figureType]="SpiderChartFiguresEnum.HEXAGON"
              ></prf-spider-chart-custom>

              <div class="diagram-row">
                <div class="diagram-column">
                  <div class="diagram-color-oval"></div>
                  <div>
                    {{ 'TEST_AND_RESULTS.CHILD_RESULTS' | translate }}
                  </div>
                </div>
                <div class="diagram-column">
                  <div class="diagram-color-oval blue"></div>
                  <div>
                    {{ 'TEST_AND_RESULTS.PARENT_RESULTS' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="diagram-info">
              <img src="/assets/images/icons/diagr-info-icon.svg" class="diagram-info-icon" />
              <p class="diagram-info-paragraph">
                {{ 'TEST_AND_RESULTS.DIAGRAM_TEXT' | translate }}
              </p>
            </div>
          </div>
        </section>

        <div #interests>
          <prf-interests-progress-bar [data]="interestsData" [isParent]="showParent && !showPupil"> </prf-interests-progress-bar>
        </div>

        <section class="test-result-section centered-content">
          <ng-container *ngIf="userRole === 'pupil'">
            <h1 class="test-result-h1">
              <!-- {{ 'TEST_AND_RESULTS.PUPIL_RECOMMENDATIONS' | translate }} -->
              Узнай, какие профессии тебе подходят!
            </h1>
            <p class="test-result-text" [style.margin-bottom]="0">
              В разделе рекомендации тебя ждут возможные сферы деятельности и примеры профессий.
            </p>
          </ng-container>

          <div *ngIf="showAfterTestingText">
            <a [routerLink]="['/recommendations']" class="button after-test w-button">Посмотреть</a>
          </div>
        </section>
      </ng-container>
    </div>
  </div>
</div>

<!-- Блок отображения рекомендаций TODO - вынести в отдельный компонент -->
<div *ngIf="resultsFetched && showResultMenu" [class.no-display]="resultMenu !== 'recommendations'">
  <h1 class="test-result-h1">{{ 'SHARED.RECOMMENDED_PROFESSIONS' | translate }}</h1>
  <p *ngIf="userRole !== 'pupil'" class="test-result-text">
    {{ 'SHARED.SELECTION_OF_PROFESSION_NARRATIVE_PARENT' | translate }}
  </p>
  <p *ngIf="userRole === 'pupil'" class="test-result-text">
    {{ 'SHARED.SELECTION_OF_PROFESSION_NARRATIVE' | translate }}
  </p>

  <prf-recommended-professions *ngIf="isCompleteTestPupil" [sessionId]="pupilSessionId"></prf-recommended-professions>

  <h2 class="test-result-h1 rec">
    {{ 'TEST_AND_RESULTS.RECOMMENDED_CLASSES' | translate }}
  </h2>
  <prf-recommended-classes [testResults]="currentResults ? currentResults.results : []"></prf-recommended-classes>
</div>

<ng-template #emptyResults>
  <div class="results-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>
