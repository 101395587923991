import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { GoalsComponent } from './goals/goals.component';
import { GoalsService } from '../../../shared/dashboard/goals/goals.service';
import { PlanCardComponent } from './goals/pupil-plan/plan-card/plan-card.component';
import { PupilPlanComponent } from './goals/pupil-plan/pupil-plan.component';
import { NotifyComponent } from './notify/notify.component';
import { PupilPanelComponent } from './pupil-panel.component';
import { PupilPanelService } from './pupil-panel.service';
import { PupilRoutingModule } from './pupil-routing.module';
import { PupilUncompletedTestComponent } from './pupil-uncompleted-test/pupil-uncompleted-test.component';
import { CurrentStepService } from './welcome-steps/current-step.service';
import { WelcomeStepsComponent } from './welcome-steps/welcome-steps.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UpdateUserInfoComponent } from './update-user-info/update-user-info.component';
import { PupilTestsHistoryPageComponent } from './pupil-tests-history-page/pupil-tests-history-page.component';
import { OnboardingStepsComponent } from './onboarding-steps/onboarding-steps.component';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import { SupportBlockComponent } from '../../../shared/common-components/support-block/support-block.component';

@NgModule({
  declarations: [
    PupilPanelComponent,
    NotifyComponent,
    GoalsComponent,
    WelcomeComponent,
    PupilUncompletedTestComponent,
    PupilPlanComponent,
    PlanCardComponent,
    WelcomeStepsComponent,
    UpdateUserInfoComponent,
    PupilTestsHistoryPageComponent,
    OnboardingStepsComponent,
  ],
  providers: [PupilPanelService, GoalsService, CurrentStepService],
  imports: [CommonModule, PupilRoutingModule, SharedModule, SwiperDirective, NgOptimizedImage, SupportBlockComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PupilModule {}
