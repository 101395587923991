<prf-menu [root]="userRole"></prf-menu>

<div class="content-section">
  <prf-page-header *ngIf="!newsId" [title]="'Новости'"> </prf-page-header>
  <div class="container">
    <prf-page-header *ngIf="newsId" [backButtonName]="'Все новости'" [backButtonUrl]="'/news'" [title]="'Новости'"> </prf-page-header>

    <prf-news-lenta *ngIf="!newsId"></prf-news-lenta>
    <prf-news-detail *ngIf="newsId"></prf-news-detail>
  </div>
</div>
