<prf-menu *ngIf="checkMenuRole()"></prf-menu>
<prf-admin-menu *ngIf="userRole === 'admin'" [internshipActive]="true" [adminLevel]="adminLevel"> </prf-admin-menu>

<div class="content-section">
  <div class="container">
    <prf-page-header [title]="title" [backButtonUrl]="backButtonUrl()" [backButtonName]="'Все стажировки'"> </prf-page-header>

    <div class="admin-header-event-page" *ngIf="userRole === 'admin' && dataFetched && internship">
      <div class="admin-header-col">
        <div class="event-status-text ev-page" *ngIf="internship.startDate">Создано {{ startDate }}</div>
        <!--<div class="event-status-text ev-page">
          Ред 23 окт в 12:00
        </div>-->
      </div>
      <div class="edit-event-column event-page-edit">
        <div class="edit-event-col-2">
          <a [routerLink]="['/admin/copy-internship', internship.hrid]" class="edit-event-button w-inline-block">
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="/assets/images/icons/files-copy-interface-symbol.svg" class="icon-initial-state" />
              </div>
              <div>{{ 'SHARED.CREATE_COPY' | translate }}</div>
            </div>
          </a>
          <a (click)="addtoArchive = !addtoArchive" class="edit-event-button w-inline-block">
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="/assets/images/icons/archive-black-box_1.svg" class="icon-initial-state" />
              </div>
              <div>Добавить в архив</div>
            </div>
          </a>
          <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
        </div>
        <div class="edit-event-col">
          <a [routerLink]="['/admin/edit-internship', internship.hrid]" class="button edit-event-page-btn w-button">{{
            'SHARED.EDIT_BUTTON' | translate
          }}</a>
        </div>
      </div>
    </div>

    <div class="event-page-section" *ngIf="dataFetched && internship">
      <div class="event-page-header event-gradient-bg-2" [ngClass]="color">
        <div
          class="event-image-bg"
          [ngStyle]="{
            'background-image': internship.mainImagePath ? 'url(' + internship.mainImagePath + ')' : ''
          }"
        ></div>
        <!-- <div class="event-header-column">
          <div class="event-page-age">{{ getAges() }}</div>
          <a
            class="event-page-heart w-inline-block"
            (click)="addToFavorite()"
            *ngIf="!isFavorite; else isFavoriteCard"
            [style.background-image]="'url(/assets/images/icons/heart-line-white.svg)'"
            (mouseover)="heart.style.backgroundImage = 'url(/assets/images/icons/heart-white.svg)'"
            (mouseleave)="heart.style.backgroundImage = 'url(/assets/images/icons/heart-line-white.svg)'"
            #heart
          ></a>
        </div> -->
        <div class="event-header-column _2">
          <div class="event-page-h1-div">
            <h1 class="event-page-h1">{{ internship.name }}</h1>
          </div>
        </div>
      </div>
      <div class="event-page-content">
        <div class="event-page-description-row">
          <div class="event-page-description-column">
            <h2 class="event-page-h2">Описание</h2>

            <div class="event-page-descr-row">
              <div *ngIf="internship.internships && internship.internships.length > 0" class="event-page-descr-column">
                <h4 class="event-page-h4">Тематика</h4>
                <ul class="event-description-list">
                  <li *ngFor="let field of internship.internships">
                    <div class="event-description-title">{{ field }}</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="internship.courseGroups && internship.courseGroups.length" class="event-page-descr-column">
                <h4 class="event-page-h4">Профиль</h4>
                <ul class="event-description-list">
                  <li *ngFor="let group of internship.courseGroups">
                    <div class="event-description-title">{{ group }}</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="complexity" class="event-page-descr-column">
                <h4 class="event-page-h4">Уровень</h4>
                <ul class="event-description-list">
                  <li>
                    <div class="event-description-title">{{ complexity }}</div>
                  </li>
                </ul>
              </div>
            </div>

            <p class="event-page-description-text" innerHtml="{{ internship.description }}"></p>
          </div>
          <div class="event-page-description-column _2">
            <div class="event-page-text">Организатор</div>
            <h3 class="event-page-h3">{{ internship.institution }}</h3>
            <div class="event-page-date" *ngIf="internship.startDate">{{ startDate }}</div>
            <div class="event-page-address">
              <div class="event-address event-pg">{{ internship.address }}</div>
            </div>
            <ng-container *ngIf="userRole === 'pupil'">
              <span (mouseover)="showTooltipAdd = true" (mouseleave)="showTooltipAdd = false">
                <a
                  *ngIf="!isEnrolled"
                  [attr.disabled]="testCompleted ? null : true"
                  (click)="addToGoals()"
                  class="button add-goal w-inline-block"
                  style="position: relative"
                >
                  <img src="/assets/images/icons/Flag-white.svg" class="goal-button-icon" />
                  <div>Подать заявку</div>
                  <div *ngIf="showTooltipAdd && testCompleted !== true" class="info-popup recommendation w-hidden-small w-hidden-tiny">
                    <p>{{ 'SHARED.TEST_REQUIRED' | translate }}</p>
                    <img src="assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                  </div>
                </a>
                <a *ngIf="isEnrolled" class="button event-page-btn w-button white-button"> Заявка подана </a>
              </span>
            </ng-container>

            <!-- <prf-recommendation-menu
              *ngIf="userRole === 'parent'
              [internship]="internship.id"
              [recommendationType]="'Internship'">
            </prf-recommendation-menu> -->
          </div>
        </div>

        <div class="event-page-map-block" [class.no-display]="!internship.address || !mapsLoaded">
          <h2 class="event-page-h2">{{ mapHeader }}</h2>
          <prf-yandex-maps [location]="internship.address" (loaded)="mapsLoaded = $event"></prf-yandex-maps>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #isFavoriteCard>
  <a
    class="event-page-heart w-inline-block"
    (click)="removeFavorite()"
    [style.background-image]="'url(/assets/images/icons/heart-white.svg)'"
    (mouseover)="heartActive.style.backgroundImage = 'url(/assets/images/icons/heart-line-white.svg)'"
    (mouseleave)="heartActive.style.backgroundImage = 'url(/assets/images/icons/heart-white.svg)'"
    #heartActive
  ></a>
</ng-template>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление стажировки</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить стажировку?</div>
          <a class="button attach-file-btn w-button" (click)="deleteInternship(internship.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="addtoArchive">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="addtoArchive = !addtoArchive">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Добавление в архив</h4>
          <div class="task-result-text">Вы уверены, что хотите добавить стажировку в архив?</div>
          <a class="button attach-file-btn w-button" (click)="archive(internship)">{{ 'SHARED.ADD' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
