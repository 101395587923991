<section>
  <prf-tutor-menu></prf-tutor-menu>
  <div class="content-section">
    <prf-page-header [title]="title"></prf-page-header>
    <div class="container">
      <ng-container>
        <router-outlet></router-outlet>
      </ng-container>
    </div>
  </div>
</section>
