<prf-menu [root]="userRole" [setUserProfile]="userProfile"></prf-menu>

<div class="content-section">
  <prf-page-header [title]="'Результаты теста'" [backButtonUrl]="'/' + userRole" [backButtonName]="'На главную'"></prf-page-header>
  <div *ngIf="loaded" class="container container-motiv-reslts container-test-results">
    <div *ngIf="showFullResults" class="results-container">
      <prf-motivation-results [userRole]="userRole" [sessionId]="urlSessionId" [pageLoader]="true"></prf-motivation-results>
    </div>
    <div *ngIf="!showFullResults" class="results-container">
      <prf-motivation-short-results [userRole]="userRole" [sessionId]="urlSessionId"></prf-motivation-short-results>
    </div>
  </div>
</div>
