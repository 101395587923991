<div class="support-links">
  <p class="title">Техническая поддержка:</p>
  <div class="support-links__wrapper">
    <a href="https://t.me/proftesthelp_bot" target="_blank" class="support-link"
      ><img class="icon" ngSrc="/assets/images/icons/telegram.svg" width="24" height="24" alt="иконка телеграма" />support</a
    >
    <a href="mailto:help@proftestnr.ru" class="support-link"
      ><img class="icon" ngSrc="/assets/images/icons/email.svg" width="24" height="24" alt="иконка почты" />help&#64;proftestnr.ru</a
    >
  </div>
</div>
