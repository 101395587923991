import { Routes } from '@angular/router';
import { SlideService } from 'app/shared/services/slide.service';
import { ConfirmEmailComponent } from './catalog/settings-page/confirm-email/confirm-email.component';
import { SendLinkComponent } from './catalog/settings-page/send-link/send-link.component';
import { AnketaComponent } from './player/anketa/anketa.component';
import { PageResultComponent } from './results/result-page/page-result.component';
import {
  ResultsProfessionPrestigeComponent
} from './results/results-profession-prestige/results-profession-prestige.component';
import { ResultsSwitcherComponent } from './results/results-switcher/results-switcher.component';
import { PaymentFormComponent } from '../shared/dashboard/order/payment-form/payment-form.component';
import { PromotestResultsComponent } from './results/promotest-results/promotest-results.component';
import { AllEventsPromotestComponent } from 'app/shared/dashboard/all-events-promotest/all-events-promotest.component';
import { PromoActionPageComponent } from 'app/shared/dashboard/promo-action-page/promo-action-page.component';
import { MotivationResultsPageComponent } from './results/motivation-results-page/motivation-results-page.component';
import {
  PromotestCourseDetailsComponent
} from 'app/shared/dashboard/courses/promotest-courses/promotest-course-details/promotest-course-details.component';
import { ValuesResultsPageComponent } from './results/values-results-page/values-results-page.component';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { CareerResultsComponent } from './results/career-results/career-results.component';
import { PlayerV2Component } from '@profilum-components/prf-tests/player/default/player-v2.component';

export const routeConfig: Routes = [
  {
    path: 'test-na-professiyu',
    component: AnketaComponent,
  },
  {
    path: 'motivation-test',
    component: AnketaComponent,
  },
  {
    path: 'test-na-professiyu/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
  },
  {
    path: 'motivation-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
  },
  {
    path: 'values-test',
    component: AnketaComponent,
  },
  {
    path: 'values-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
  },
  {
    path: 'career-test',
    component: AnketaComponent,
  },
  {
    path: 'career-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
  },
  {
    path: 'mosrupromo-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
  },
  {
    path: 'vorobievi-gori/mosrupromo-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
  },
  {
    path: 'results/:ssid',
    component: PageResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'results-switcher/:ssid',
    component: ResultsSwitcherComponent,
  },
  {
    path: 'promotest-results/:ssid',
    component: PromotestResultsComponent,
  },
  {
    path: 'promotest-results',
    component: PromotestResultsComponent,
  },
  {
    path: 'vorobievi-gori/promotest-results/:ssid',
    component: PromotestResultsComponent,
  },
  {
    path: 'vorobievi-gori/promotest-results',
    component: PromotestResultsComponent,
  },
  {
    path: 'partners-courses-all',
    component: AllEventsPromotestComponent,
  },
  {
    path: 'vorobievi-gori/partners-courses-all',
    component: AllEventsPromotestComponent,
  },
  {
    path: 'vorobievi-gori/partners-courses-all/:hrid',
    component: PromotestCourseDetailsComponent,
  },
  { path: 'mosruabout', component: PromoActionPageComponent },
  {
    path: 'results-profession-prestige',
    component: ResultsProfessionPrestigeComponent,
  },
  {
    path: 'motivation-test-results/:ssid',
    component: MotivationResultsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'motivation-test-results',
    component: MotivationResultsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'values-test-results/:ssid',
    component: ValuesResultsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'values-test-results',
    component: ValuesResultsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'career-test-results/:ssid',
    component: CareerResultsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'career-test-results',
    component: CareerResultsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order/payment',
    component: PaymentFormComponent,
  },
  {
    path: 'emailconfirmation',
    component: ConfirmEmailComponent,
  },
  {
    path: 'sendconfirmationlink',
    component: SendLinkComponent,
  },
  {
    path: 'industry-page/:id',
    loadChildren: () => import('app/pages/industry-page/industry-page.routes'),
  },
];
